.hand {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
}

.hand :global(.card) {
    margin: 1rem 1rem;
}

.container {
    overflow: auto;
}