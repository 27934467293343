.counter {
  display: inline-block;
  margin: 0 1rem;
}

.value {
  font-size: 2rem;
  font-weight: bold;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
}

.name {
  text-align: center;
  color: var(--grey-2);
}

.button {
  appearance: none;
  background: none;
  font-size: 32px;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  border: 2px solid transparent;
  color: #fff;
  padding-bottom: 4px;
  cursor: pointer;
  background-color: var(--red-4);
  border-radius: 2px;
  transition: all 0.15s;
}

.button:hover,
.button:focus {
  border: 2px solid var(--red-1);
}

.button:active {
  background-color: var(--red-1);
}
