.d6 {
    font-size: 32px;
    color: var(--black);
    font-weight: bold;
    display: inline-block;
    position: relative;
    border-radius: 4px;
    width: 60px;
    height: 60px;
}

.d6__value {
    position: absolute;
    top: 33px;
    left: 21px;
    transform: translate(-50%, -50%);
}