
.card {
    background-color: var(--red-3);
    border-radius: 4px;
    margin-bottom: .5rem;
    cursor: pointer;
  }
  
  .card:hover {
    background-color: var(--red-2);
  }
  
  .title {
    text-align: center;
    padding: .5rem 2rem;
    margin: 0;
  }
  
  .content {
    padding: .5rem;
    background-color: rgba(0, 0, 0, 0.7);
  }
