.progressTrack {
  list-style: none;
  padding-left: 0;
}

.progressTrack li {
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 0.2em;
}

.progressTrackBox {
  position: relative;
  height: 1rem;
  width: 1rem;
  display: block;
}

.progressTrackBox {
  border: 3px solid #999;
}

.progressTrackTitle {
    color: var(--grey-2);
    text-align: center;
    margin-bottom: .25rem;
}

.progressBtns {
  width: 20%;
  text-align: center;
  display: inline-block;
}

.progressTick-1 {
  background: url("~/public/check1.svg");
  background-repeat: no-repeat;
  transform: rotate(-45deg);
}

.progressTick-2 {
  background: url("~/public/check1.svg");
  background-repeat: no-repeat;
  transform: rotate(45deg);
}

.progressTick-3 {
  background: url("~/public/check2.svg");
  background-repeat: no-repeat;
}

.progressTick-4 {
  background: url("~/public/check2.svg");
  background-repeat: no-repeat;
  transform: rotate(-90deg);
}

.progressTick-10 {
  background: #666;
}

.progressTrackBar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.progressTrackBtn {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 2px;
}

.progressTrackBtn:hover {
    border-color: var(--red-1);
}

.progressTrackBtn:active {
    background-color: var(--red-1);
}

.progressTick {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  display: block;
  bottom: 0;
  left: 0;
}

.progressTrackBoxRound {
  border: 2px solid #999;
  border-radius: 50%;
  overflow: hidden;
}

.progress-check-li {
  position: relative;
}

.threat-box {
  border: 1px solid #999;
  position: absolute;
  width: 0.6em;
  height: 0.6em;
  right: 0.14em;
  top: 0.14em;
  background: white;
}

.threat-box-filled {
  background: black;
}