:root {
  --red-1: #D90404;
  --red-2: #A60303;
  --red-3: #590202;
  --red-4: #260101;
  --black: #0D0D0D;
  --white: #fff;
  --grey-1: #e6e7e8;
  --grey-2: #77787B;
}

body {
  background-color: var(--black);
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  margin: 0;
  color: var(--white);
  position: relative;
}


.app {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  display: flex;
  flex-direction: column;
}

.app__content {
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 0 0;

  max-width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.app__content > * {
  max-height: 100%;
}


.app footer {
  width: 100%;
  background-color: var(--red-4);
  text-align: center;
  display: flex;
}

.footer__button {
  cursor: pointer;
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: .8rem;

  color: var(--grey-2);
}

.footer__button.selected {
  background-color: var(--red-3);
}


.centered-text {
  text-align: center;
}

input[type="text"] {
  font-size: 20px;
  padding: .5rem;
  border-width: 0;
}

button {
  background-color: var(--red-4);
  color: var(--white);
  border: 2px solid transparent;
  cursor: pointer;
  border-radius: 2px;
  font-size: 20px;
  padding: .5rem;
}

button:hover {
  border-color: var(--red-1);
}

button:active {
  background-color: var(--red-1);
}

.card-type--asset {
  background-color: #343a40 !important;
}

.card-type--adventure {
  /* adventure */
  background-color: #3b9151 !important;
  
}

.card-type--combat {
  background-color: #913b3b !important;
}

.card-type--fate {
  background-color: #3b5191 !important;
}

.card-type--quest {
  background-color: #3b9191 !important;
}

.card-type--relationship {
  background-color: #663b91 !important;
}

.card-type--suffer {
  background-color: #917b3b !important;
}

.card-type--delve, .card-type--optional-delve {
  background-color: #66913b !important;
}