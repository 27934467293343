.oracle {
    cursor: pointer;
    padding: .5rem;
}

.oracle:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.oracle__name {
    color: #ccc;
    display: block;
    font-size: 0.8rem;
}

.oracle h4 { margin: 0; padding: 0; font-size: 2rem;}
.oracle table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 1rem;
    color: #ccc;
}
.oracle table td {
    padding: 0.5rem;
    border: 1px solid #333;
    min-width: 60px;
}