body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


p {
  margin-top: 0;
  margin-bottom: 1rem;
}

button + button {
  margin-left: .25rem;
}


@font-face {
  font-family: "game-icons";
  src: url("~/public/game-icons.eot");
  src: url("~/public/game-icons.eot?#iefix") format("eot"), url("~/public/game-icons.woff") format("woff"), url("~/public/game-icons.ttf") format("truetype");
}

@font-face {
  font-family: "bilbo-hand";
  src: url("~/public/viking.otf") format("opentype");
}

.game-icon:before {
  font-family: "game-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none;
}

.game-icon-police-badge:before {
  content: "\E001";
}

.game-icon-pistol-gun:before {
  content: "\E002";
}

.game-icon-arrest:before {
  content: "\E003";
}

.game-icon-banging-gavel:before {
  content: "\E004";
}

.game-icon-chalk-outline-murder:before {
  content: "\E005";
}

.game-icon-convict:before {
  content: "\E006";
}

.game-icon-crime-scene-tape:before {
  content: "\E007";
}

.game-icon-custodian-helmet:before {
  content: "\E008";
}

.game-icon-donut:before {
  content: "\E009";
}

.game-icon-evasion:before {
  content: "\E00A";
}

.game-icon-finger-print:before {
  content: "\E00B";
}

.game-icon-flashlight:before {
  content: "\E00C";
}

.game-icon-handcuffed:before {
  content: "\E00D";
}

.game-icon-id-card:before {
  content: "\E00E";
}

.game-icon-lock-picking:before {
  content: "\E00F";
}

.game-icon-megaphone:before {
  content: "\E010";
}

.game-icon-mp5:before {
  content: "\E011";
}

.game-icon-mug-shot:before {
  content: "\E012";
}

.game-icon-police-car:before {
  content: "\E013";
}

.game-icon-police-officer-head:before {
  content: "\E014";
}

.game-icon-police-target:before {
  content: "\E015";
}

.game-icon-prisoner:before {
  content: "\E016";
}

.game-icon-robber-mask:before {
  content: "\E017";
}

.game-icon-robber:before {
  content: "\E018";
}

.game-icon-sherlock-holmes:before {
  content: "\E019";
}

.game-icon-sniffing-dog:before {
  content: "\E01A";
}

.game-icon-spy:before {
  content: "\E01B";
}

.game-icon-stun-grenade:before {
  content: "\E01C";
}

.game-icon-tire-tracks:before {
  content: "\E01D";
}

.game-icon-traffic-lights-red:before {
  content: "\E01E";
}

.game-icon-walkie-talkie:before {
  content: "\E01F";
}

.game-icon-whistle:before {
  content: "\E020";
}

.game-icon-window-bars:before {
  content: "\E021";
}

.game-icon-arrow-scope:before {
  content: "\E022";
}

.game-icon-flash-grenade:before {
  content: "\E023";
}

.game-icon-handcuffs:before {
  content: "\E024";
}

.game-icon-law-star:before {
  content: "\E025";
}

.game-icon-magnifying-glass:before {
  content: "\E026";
}

.game-icon-manacles:before {
  content: "\E027";
}

.game-icon-riot-shield:before {
  content: "\E028";
}

.game-icon-kevlar:before {
  content: "\E029";
}

.game-icon-balaclava:before {
  content: "\E02A";
}

.game-icon-baton:before {
  content: "\E02B";
}

.game-icon-famas:before {
  content: "\E02C";
}

.game-icon-footsteps:before {
  content: "\E02D";
}

.game-icon-glock:before {
  content: "\E02E";
}

.game-icon-halt:before {
  content: "\E02F";
}

.game-icon-kevlar-vest:before {
  content: "\E030";
}

.game-icon-siren:before {
  content: "\E031";
}

.game-icon-steel-door:before {
  content: "\E032";
}

.game-icon-steyr-aug:before {
  content: "\E033";
}

.game-icon-switchblade:before {
  content: "\E034";
}

.game-icon-walther-ppk:before {
  content: "\E035";
}

.game-icon-swordman:before {
  content: "\E036";
}

.game-icon-swordwoman:before {
  content: "\E037";
}

.game-icon-american-shield:before {
  content: "\E038";
}

.game-icon-armor-downgrade:before {
  content: "\E039";
}

.game-icon-armor-upgrade:before {
  content: "\E03A";
}

.game-icon-attached-shield:before {
  content: "\E03B";
}

.game-icon-cross-shield:before {
  content: "\E03C";
}

.game-icon-dice-shield:before {
  content: "\E03D";
}

.game-icon-dragon-shield:before {
  content: "\E03E";
}

.game-icon-griffin-shield:before {
  content: "\E03F";
}

.game-icon-healing-shield:before {
  content: "\E040";
}

.game-icon-heart-shield:before {
  content: "\E041";
}

.game-icon-ribbon-shield:before {
  content: "\E042";
}

.game-icon-roman-shield:before {
  content: "\E043";
}

.game-icon-shield-bash:before {
  content: "\E044";
}

.game-icon-shield-impact:before {
  content: "\E045";
}

.game-icon-shield-opposition:before {
  content: "\E046";
}

.game-icon-templar-shield:before {
  content: "\E047";
}

.game-icon-temporary-shield:before {
  content: "\E048";
}

.game-icon-tribal-gear:before {
  content: "\E049";
}

.game-icon-tribal-shield:before {
  content: "\E04A";
}

.game-icon-trident-shield:before {
  content: "\E04B";
}

.game-icon-vibrating-shield:before {
  content: "\E04C";
}

.game-icon-viking-shield:before {
  content: "\E04D";
}

.game-icon-arrows-shield:before {
  content: "\E04E";
}

.game-icon-battle-gear:before {
  content: "\E04F";
}

.game-icon-bell-shield:before {
  content: "\E050";
}

.game-icon-black-hand-shield:before {
  content: "\E051";
}

.game-icon-bolt-shield:before {
  content: "\E052";
}

.game-icon-bordered-shield:before {
  content: "\E053";
}

.game-icon-broken-shield:before {
  content: "\E054";
}

.game-icon-bubble-field:before {
  content: "\E055";
}

.game-icon-checked-shield:before {
  content: "\E056";
}

.game-icon-cracked-shield:before {
  content: "\E057";
}

.game-icon-crenulated-shield:before {
  content: "\E058";
}

.game-icon-edged-shield:before {
  content: "\E059";
}

.game-icon-energy-shield:before {
  content: "\E05A";
}

.game-icon-eye-shield:before {
  content: "\E05B";
}

.game-icon-fire-shield:before {
  content: "\E05C";
}

.game-icon-ice-shield:before {
  content: "\E05D";
}

.game-icon-layered-armor:before {
  content: "\E05E";
}

.game-icon-lightning-shield:before {
  content: "\E05F";
}

.game-icon-magic-shield:before {
  content: "\E060";
}

.game-icon-rosa-shield:before {
  content: "\E061";
}

.game-icon-shield-bounces:before {
  content: "\E062";
}

.game-icon-shield-echoes:before {
  content: "\E063";
}

.game-icon-shield-reflect:before {
  content: "\E064";
}

.game-icon-shieldcomb:before {
  content: "\E065";
}

.game-icon-skull-shield:before {
  content: "\E066";
}

.game-icon-slashed-shield:before {
  content: "\E067";
}

.game-icon-spartan:before {
  content: "\E068";
}

.game-icon-spiky-field:before {
  content: "\E069";
}

.game-icon-surrounded-shield:before {
  content: "\E06A";
}

.game-icon-swords-emblem:before {
  content: "\E06B";
}

.game-icon-winged-shield:before {
  content: "\E06C";
}

.game-icon-zebra-shield:before {
  content: "\E06D";
}

.game-icon-shield:before {
  content: "\E06E";
}

.game-icon-air-force:before {
  content: "\E06F";
}

.game-icon-shield-disabled:before {
  content: "\E070";
}

.game-icon-round-shield:before {
  content: "\E071";
}

.game-icon-chameleon-glyph:before {
  content: "\E072";
}

.game-icon-akhet:before {
  content: "\E073";
}

.game-icon-all-seeing-eye:before {
  content: "\E074";
}

.game-icon-anarchy:before {
  content: "\E075";
}

.game-icon-aquarius:before {
  content: "\E076";
}

.game-icon-aries:before {
  content: "\E077";
}

.game-icon-azul-flake:before {
  content: "\E078";
}

.game-icon-banknote:before {
  content: "\E079";
}

.game-icon-bowen-knot:before {
  content: "\E07A";
}

.game-icon-caduceus:before {
  content: "\E07B";
}

.game-icon-camargue-cross:before {
  content: "\E07C";
}

.game-icon-cancer:before {
  content: "\E07D";
}

.game-icon-capricorn:before {
  content: "\E07E";
}

.game-icon-check-mark:before {
  content: "\E07F";
}

.game-icon-cornucopia:before {
  content: "\E080";
}

.game-icon-corporal:before {
  content: "\E081";
}

.game-icon-credits-currency:before {
  content: "\E082";
}

.game-icon-dream-catcher:before {
  content: "\E083";
}

.game-icon-dwennimmen:before {
  content: "\E084";
}

.game-icon-easter-egg:before {
  content: "\E085";
}

.game-icon-ermine:before {
  content: "\E086";
}

.game-icon-eye-of-horus:before {
  content: "\E087";
}

.game-icon-f-clef:before {
  content: "\E088";
}

.game-icon-fallout-shelter:before {
  content: "\E089";
}

.game-icon-female:before {
  content: "\E08A";
}

.game-icon-film-strip:before {
  content: "\E08B";
}

.game-icon-fleur-de-lys:before {
  content: "\E08C";
}

.game-icon-flower-emblem:before {
  content: "\E08D";
}

.game-icon-freemasonry:before {
  content: "\E08E";
}

.game-icon-g-clef:before {
  content: "\E08F";
}

.game-icon-gear-stick-pattern:before {
  content: "\E090";
}

.game-icon-gemini:before {
  content: "\E091";
}

.game-icon-hell-crosses:before {
  content: "\E092";
}

.game-icon-heptagram:before {
  content: "\E093";
}

.game-icon-hieroglyph-y:before {
  content: "\E094";
}

.game-icon-horseshoe:before {
  content: "\E095";
}

.game-icon-info:before {
  content: "\E096";
}

.game-icon-jerusalem-cross:before {
  content: "\E097";
}

.game-icon-klingon:before {
  content: "\E098";
}

.game-icon-leo:before {
  content: "\E099";
}

.game-icon-liberty-wing:before {
  content: "\E09A";
}

.game-icon-libra:before {
  content: "\E09B";
}

.game-icon-logic-gate-and:before {
  content: "\E09C";
}

.game-icon-logic-gate-nand:before {
  content: "\E09D";
}

.game-icon-logic-gate-nor:before {
  content: "\E09E";
}

.game-icon-logic-gate-not:before {
  content: "\E09F";
}

.game-icon-logic-gate-nxor:before {
  content: "\E0A0";
}

.game-icon-logic-gate-or:before {
  content: "\E0A1";
}

.game-icon-logic-gate-xor:before {
  content: "\E0A2";
}

.game-icon-male:before {
  content: "\E0A3";
}

.game-icon-mona-lisa:before {
  content: "\E0A4";
}

.game-icon-nested-hexagons:before {
  content: "\E0A5";
}

.game-icon-ophiuchus:before {
  content: "\E0A6";
}

.game-icon-peace-dove:before {
  content: "\E0A7";
}

.game-icon-pisces:before {
  content: "\E0A8";
}

.game-icon-rod-of-asclepius:before {
  content: "\E0A9";
}

.game-icon-rss:before {
  content: "\E0AA";
}

.game-icon-rub-el-hizb:before {
  content: "\E0AB";
}

.game-icon-sagittarius:before {
  content: "\E0AC";
}

.game-icon-scorpio:before {
  content: "\E0AD";
}

.game-icon-slumbering-sanctuary:before {
  content: "\E0AE";
}

.game-icon-stop-sign:before {
  content: "\E0AF";
}

.game-icon-striped-sun:before {
  content: "\E0B0";
}

.game-icon-taurus:before {
  content: "\E0B1";
}

.game-icon-transform:before {
  content: "\E0B2";
}

.game-icon-triforce:before {
  content: "\E0B3";
}

.game-icon-trinacria:before {
  content: "\E0B4";
}

.game-icon-triquetra:before {
  content: "\E0B5";
}

.game-icon-venus-of-willendorf:before {
  content: "\E0B6";
}

.game-icon-virgo:before {
  content: "\E0B7";
}

.game-icon-virtual-marker:before {
  content: "\E0B8";
}

.game-icon-wanted-reward:before {
  content: "\E0B9";
}

.game-icon-warlock-eye:before {
  content: "\E0BA";
}

.game-icon-yin-yang:before {
  content: "\E0BB";
}

.game-icon-zigzag-hieroglyph:before {
  content: "\E0BC";
}

.game-icon-ace:before {
  content: "\E0BD";
}

.game-icon-aerial-signal:before {
  content: "\E0BE";
}

.game-icon-ankh:before {
  content: "\E0BF";
}

.game-icon-artificial-hive:before {
  content: "\E0C0";
}

.game-icon-automatic-sas:before {
  content: "\E0C1";
}

.game-icon-barbed-sun:before {
  content: "\E0C2";
}

.game-icon-batwing-emblem:before {
  content: "\E0C3";
}

.game-icon-biohazard:before {
  content: "\E0C4";
}

.game-icon-black-flag:before {
  content: "\E0C5";
}

.game-icon-checkbox-tree:before {
  content: "\E0C6";
}

.game-icon-clover:before {
  content: "\E0C7";
}

.game-icon-coiling-curl:before {
  content: "\E0C8";
}

.game-icon-concentric-crescents:before {
  content: "\E0C9";
}

.game-icon-condor-emblem:before {
  content: "\E0CA";
}

.game-icon-conversation:before {
  content: "\E0CB";
}

.game-icon-cubeforce:before {
  content: "\E0CC";
}

.game-icon-cubes:before {
  content: "\E0CD";
}

.game-icon-cursed-star:before {
  content: "\E0CE";
}

.game-icon-cycle:before {
  content: "\E0CF";
}

.game-icon-divided-spiral:before {
  content: "\E0D0";
}

.game-icon-eagle-emblem:before {
  content: "\E0D1";
}

.game-icon-fluffy-trefoil:before {
  content: "\E0D2";
}

.game-icon-freedom-dove:before {
  content: "\E0D3";
}

.game-icon-gamepad-cross:before {
  content: "\E0D4";
}

.game-icon-gothic-cross:before {
  content: "\E0D5";
}

.game-icon-hawk-emblem:before {
  content: "\E0D6";
}

.game-icon-hazard-sign:before {
  content: "\E0D7";
}

.game-icon-holy-symbol:before {
  content: "\E0D8";
}

.game-icon-hospital-cross:before {
  content: "\E0D9";
}

.game-icon-interdiction:before {
  content: "\E0DA";
}

.game-icon-james-bond-aperture:before {
  content: "\E0DB";
}

.game-icon-justice-star:before {
  content: "\E0DC";
}

.game-icon-king:before {
  content: "\E0DD";
}

.game-icon-laser-warning:before {
  content: "\E0DE";
}

.game-icon-maze-cornea:before {
  content: "\E0DF";
}

.game-icon-maze-saw:before {
  content: "\E0E0";
}

.game-icon-moebius-star:before {
  content: "\E0E1";
}

.game-icon-moebius-trefoil:before {
  content: "\E0E2";
}

.game-icon-moebius-triangle:before {
  content: "\E0E3";
}

.game-icon-omega:before {
  content: "\E0E4";
}

.game-icon-orbital:before {
  content: "\E0E5";
}

.game-icon-over-infinity:before {
  content: "\E0E6";
}

.game-icon-pentagram-rose:before {
  content: "\E0E7";
}

.game-icon-perpendicular-rings:before {
  content: "\E0E8";
}

.game-icon-radial-balance:before {
  content: "\E0E9";
}

.game-icon-radioactive:before {
  content: "\E0EA";
}

.game-icon-recycle:before {
  content: "\E0EB";
}

.game-icon-ribbon:before {
  content: "\E0EC";
}

.game-icon-rss-2:before {
  content: "\E0ED";
}

.game-icon-rune-sword:before {
  content: "\E0EE";
}

.game-icon-scales:before {
  content: "\E0EF";
}

.game-icon-shiny-omega:before {
  content: "\E0F0";
}

.game-icon-shuriken-aperture:before {
  content: "\E0F1";
}

.game-icon-skull-crossed-bones:before {
  content: "\E0F2";
}

.game-icon-staryu:before {
  content: "\E0F3";
}

.game-icon-steelwing-emblem:before {
  content: "\E0F4";
}

.game-icon-totem-head:before {
  content: "\E0F5";
}

.game-icon-triorb:before {
  content: "\E0F6";
}

.game-icon-triple-beak:before {
  content: "\E0F7";
}

.game-icon-triple-corn:before {
  content: "\E0F8";
}

.game-icon-triple-plier:before {
  content: "\E0F9";
}

.game-icon-triple-yin:before {
  content: "\E0FA";
}

.game-icon-ubisoft-sun:before {
  content: "\E0FB";
}

.game-icon-zigzag-cage:before {
  content: "\E0FC";
}

.game-icon-holosphere:before {
  content: "\E0FD";
}

.game-icon-power-button:before {
  content: "\E0FE";
}

.game-icon-cancel:before {
  content: "\E0FF";
}

.game-icon-clover-spiked:before {
  content: "\E100";
}

.game-icon-clover-2:before {
  content: "\E101";
}

.game-icon-doubled:before {
  content: "\E102";
}

.game-icon-expander:before {
  content: "\E103";
}

.game-icon-help:before {
  content: "\E104";
}

.game-icon-hive:before {
  content: "\E105";
}

.game-icon-mass-driver:before {
  content: "\E106";
}

.game-icon-nodular:before {
  content: "\E107";
}

.game-icon-nuclear:before {
  content: "\E108";
}

.game-icon-overmind:before {
  content: "\E109";
}

.game-icon-pulse:before {
  content: "\E10A";
}

.game-icon-reactor:before {
  content: "\E10B";
}

.game-icon-regeneration:before {
  content: "\E10C";
}

.game-icon-slow-blob:before {
  content: "\E10D";
}

.game-icon-spawn-node:before {
  content: "\E10E";
}

.game-icon-infinity:before {
  content: "\E10F";
}

.game-icon-allied-star:before {
  content: "\E110";
}

.game-icon-balkenkreuz:before {
  content: "\E111";
}

.game-icon-bat:before {
  content: "\E112";
}

.game-icon-clubs:before {
  content: "\E113";
}

.game-icon-diamonds:before {
  content: "\E114";
}

.game-icon-divided-square:before {
  content: "\E115";
}

.game-icon-hearts:before {
  content: "\E116";
}

.game-icon-iron-cross:before {
  content: "\E117";
}

.game-icon-pentacle:before {
  content: "\E118";
}

.game-icon-spades:before {
  content: "\E119";
}

.game-icon-card-10-clubs:before {
  content: "\E11A";
}

.game-icon-card-10-diamonds:before {
  content: "\E11B";
}

.game-icon-card-10-hearts:before {
  content: "\E11C";
}

.game-icon-card-10-spades:before {
  content: "\E11D";
}

.game-icon-card-2-clubs:before {
  content: "\E11E";
}

.game-icon-card-2-diamonds:before {
  content: "\E11F";
}

.game-icon-card-2-hearts:before {
  content: "\E120";
}

.game-icon-card-2-spades:before {
  content: "\E121";
}

.game-icon-card-3-clubs:before {
  content: "\E122";
}

.game-icon-card-3-diamonds:before {
  content: "\E123";
}

.game-icon-card-3-hearts:before {
  content: "\E124";
}

.game-icon-card-3-spades:before {
  content: "\E125";
}

.game-icon-card-4-clubs:before {
  content: "\E126";
}

.game-icon-card-4-diamonds:before {
  content: "\E127";
}

.game-icon-card-4-hearts:before {
  content: "\E128";
}

.game-icon-card-4-spades:before {
  content: "\E129";
}

.game-icon-card-5-clubs:before {
  content: "\E12A";
}

.game-icon-card-5-diamonds:before {
  content: "\E12B";
}

.game-icon-card-5-hearts:before {
  content: "\E12C";
}

.game-icon-card-5-spades:before {
  content: "\E12D";
}

.game-icon-card-6-clubs:before {
  content: "\E12E";
}

.game-icon-card-6-diamonds:before {
  content: "\E12F";
}

.game-icon-card-6-hearts:before {
  content: "\E130";
}

.game-icon-card-6-spades:before {
  content: "\E131";
}

.game-icon-card-7-clubs:before {
  content: "\E132";
}

.game-icon-card-7-diamonds:before {
  content: "\E133";
}

.game-icon-card-7-hearts:before {
  content: "\E134";
}

.game-icon-card-7-spades:before {
  content: "\E135";
}

.game-icon-card-8-clubs:before {
  content: "\E136";
}

.game-icon-card-8-diamonds:before {
  content: "\E137";
}

.game-icon-card-8-hearts:before {
  content: "\E138";
}

.game-icon-card-8-spades:before {
  content: "\E139";
}

.game-icon-card-9-clubs:before {
  content: "\E13A";
}

.game-icon-card-9-diamonds:before {
  content: "\E13B";
}

.game-icon-card-9-hearts:before {
  content: "\E13C";
}

.game-icon-card-9-spades:before {
  content: "\E13D";
}

.game-icon-card-ace-clubs:before {
  content: "\E13E";
}

.game-icon-card-ace-diamonds:before {
  content: "\E13F";
}

.game-icon-card-ace-hearts:before {
  content: "\E140";
}

.game-icon-card-ace-spades:before {
  content: "\E141";
}

.game-icon-card-jack-clubs:before {
  content: "\E142";
}

.game-icon-card-jack-diamonds:before {
  content: "\E143";
}

.game-icon-card-jack-hearts:before {
  content: "\E144";
}

.game-icon-card-jack-spades:before {
  content: "\E145";
}

.game-icon-card-king-clubs:before {
  content: "\E146";
}

.game-icon-card-king-diamonds:before {
  content: "\E147";
}

.game-icon-card-king-hearts:before {
  content: "\E148";
}

.game-icon-card-king-spades:before {
  content: "\E149";
}

.game-icon-card-queen-clubs:before {
  content: "\E14A";
}

.game-icon-card-queen-diamonds:before {
  content: "\E14B";
}

.game-icon-card-queen-hearts:before {
  content: "\E14C";
}

.game-icon-card-queen-spades:before {
  content: "\E14D";
}

.game-icon-card-discard:before {
  content: "\E14E";
}

.game-icon-card-draw:before {
  content: "\E14F";
}

.game-icon-card-pick:before {
  content: "\E150";
}

.game-icon-card-random:before {
  content: "\E151";
}

.game-icon-3d-meeple:before {
  content: "\E152";
}

.game-icon-abbot-meeple:before {
  content: "\E153";
}

.game-icon-ages:before {
  content: "\E154";
}

.game-icon-backgammon:before {
  content: "\E155";
}

.game-icon-buy-card:before {
  content: "\E156";
}

.game-icon-card-burn:before {
  content: "\E157";
}

.game-icon-card-exchange:before {
  content: "\E158";
}

.game-icon-card-joker:before {
  content: "\E159";
}

.game-icon-coins:before {
  content: "\E15A";
}

.game-icon-conway-life-glider:before {
  content: "\E15B";
}

.game-icon-dice-six-faces-three:before {
  content: "\E15C";
}

.game-icon-domino-tiles:before {
  content: "\E15D";
}

.game-icon-empty-chessboard:before {
  content: "\E15E";
}

.game-icon-meeple-army:before {
  content: "\E15F";
}

.game-icon-meeple-circle:before {
  content: "\E160";
}

.game-icon-meeple-group:before {
  content: "\E161";
}

.game-icon-meeple-king:before {
  content: "\E162";
}

.game-icon-meeple:before {
  content: "\E163";
}

.game-icon-opposite-hearts:before {
  content: "\E164";
}

.game-icon-pay-money:before {
  content: "\E165";
}

.game-icon-perspective-dice-six-faces-four:before {
  content: "\E166";
}

.game-icon-player-base:before {
  content: "\E167";
}

.game-icon-player-next:before {
  content: "\E168";
}

.game-icon-player-previous:before {
  content: "\E169";
}

.game-icon-player-time:before {
  content: "\E16A";
}

.game-icon-receive-money:before {
  content: "\E16B";
}

.game-icon-rolling-dice-cup:before {
  content: "\E16C";
}

.game-icon-rolling-dices:before {
  content: "\E16D";
}

.game-icon-round-table:before {
  content: "\E16E";
}

.game-icon-rule-book:before {
  content: "\E16F";
}

.game-icon-sell-card:before {
  content: "\E170";
}

.game-icon-stack:before {
  content: "\E171";
}

.game-icon-tabletop-players:before {
  content: "\E172";
}

.game-icon-team-downgrade:before {
  content: "\E173";
}

.game-icon-team-upgrade:before {
  content: "\E174";
}

.game-icon-tic-tac-toe:before {
  content: "\E175";
}

.game-icon-token:before {
  content: "\E176";
}

.game-icon-two-coins:before {
  content: "\E177";
}

.game-icon-up-card:before {
  content: "\E178";
}

.game-icon-honeycomb:before {
  content: "\E179";
}

.game-icon-hourglass:before {
  content: "\E17A";
}

.game-icon-pawn:before {
  content: "\E17B";
}

.game-icon-poker-hand:before {
  content: "\E17C";
}

.game-icon-sands-of-time:before {
  content: "\E17D";
}

.game-icon-stopwatch:before {
  content: "\E17E";
}

.game-icon-suits:before {
  content: "\E17F";
}

.game-icon-card-pickup:before {
  content: "\E180";
}

.game-icon-card-play:before {
  content: "\E181";
}

.game-icon-chess-bishop:before {
  content: "\E182";
}

.game-icon-chess-king:before {
  content: "\E183";
}

.game-icon-chess-knight:before {
  content: "\E184";
}

.game-icon-chess-pawn:before {
  content: "\E185";
}

.game-icon-chess-queen:before {
  content: "\E186";
}

.game-icon-chess-rook:before {
  content: "\E187";
}

.game-icon-hexes:before {
  content: "\E188";
}

.game-icon-brutal-helm:before {
  content: "\E189";
}

.game-icon-dwarf-helmet:before {
  content: "\E18A";
}

.game-icon-dwarf-king:before {
  content: "\E18B";
}

.game-icon-elf-helmet:before {
  content: "\E18C";
}

.game-icon-american-football-helmet:before {
  content: "\E18D";
}

.game-icon-astronaut-helmet:before {
  content: "\E18E";
}

.game-icon-bandana:before {
  content: "\E18F";
}

.game-icon-bandit:before {
  content: "\E190";
}

.game-icon-billed-cap:before {
  content: "\E191";
}

.game-icon-black-knight-helm:before {
  content: "\E192";
}

.game-icon-captain-hat-profile:before {
  content: "\E193";
}

.game-icon-chef-toque:before {
  content: "\E194";
}

.game-icon-closed-barbute:before {
  content: "\E195";
}

.game-icon-cork-hat:before {
  content: "\E196";
}

.game-icon-coronation:before {
  content: "\E197";
}

.game-icon-crenel-crown:before {
  content: "\E198";
}

.game-icon-deshret-red-crown:before {
  content: "\E199";
}

.game-icon-diving-helmet:before {
  content: "\E19A";
}

.game-icon-dunce-cap:before {
  content: "\E19B";
}

.game-icon-fez:before {
  content: "\E19C";
}

.game-icon-flower-hat:before {
  content: "\E19D";
}

.game-icon-frog-prince:before {
  content: "\E19E";
}

.game-icon-full-motorcycle-helmet:before {
  content: "\E19F";
}

.game-icon-galea:before {
  content: "\E1A0";
}

.game-icon-gauls-helm:before {
  content: "\E1A1";
}

.game-icon-graduate-cap:before {
  content: "\E1A2";
}

.game-icon-hazmat-suit:before {
  content: "\E1A3";
}

.game-icon-hedjet-white-crown:before {
  content: "\E1A4";
}

.game-icon-imperial-crown:before {
  content: "\E1A5";
}

.game-icon-jester-hat:before {
  content: "\E1A6";
}

.game-icon-jewel-crown:before {
  content: "\E1A7";
}

.game-icon-light-helm:before {
  content: "\E1A8";
}

.game-icon-magic-hat:before {
  content: "\E1A9";
}

.game-icon-magick-trick:before {
  content: "\E1AA";
}

.game-icon-mining-helmet:before {
  content: "\E1AB";
}

.game-icon-mustache:before {
  content: "\E1AC";
}

.game-icon-nefertiti:before {
  content: "\E1AD";
}

.game-icon-outback-hat:before {
  content: "\E1AE";
}

.game-icon-overlord-helm:before {
  content: "\E1AF";
}

.game-icon-party-hat:before {
  content: "\E1B0";
}

.game-icon-phrygian-cap:before {
  content: "\E1B1";
}

.game-icon-pilgrim-hat:before {
  content: "\E1B2";
}

.game-icon-pirate-hat:before {
  content: "\E1B3";
}

.game-icon-plague-doctor-profile:before {
  content: "\E1B4";
}

.game-icon-pope-crown:before {
  content: "\E1B5";
}

.game-icon-propeller-beanie:before {
  content: "\E1B6";
}

.game-icon-pschent-double-crown:before {
  content: "\E1B7";
}

.game-icon-robin-hood-hat:before {
  content: "\E1B8";
}

.game-icon-samurai-helmet:before {
  content: "\E1B9";
}

.game-icon-samus-helmet:before {
  content: "\E1BA";
}

.game-icon-sombrero:before {
  content: "\E1BB";
}

.game-icon-spartan-helmet:before {
  content: "\E1BC";
}

.game-icon-throne-king:before {
  content: "\E1BD";
}

.game-icon-tiara:before {
  content: "\E1BE";
}

.game-icon-turban:before {
  content: "\E1BF";
}

.game-icon-ushanka:before {
  content: "\E1C0";
}

.game-icon-viking-helmet:before {
  content: "\E1C1";
}

.game-icon-war-bonnet:before {
  content: "\E1C2";
}

.game-icon-warlock-hood:before {
  content: "\E1C3";
}

.game-icon-western-hat:before {
  content: "\E1C4";
}

.game-icon-winter-hat:before {
  content: "\E1C5";
}

.game-icon-barbute:before {
  content: "\E1C6";
}

.game-icon-cowled:before {
  content: "\E1C7";
}

.game-icon-cracked-helm:before {
  content: "\E1C8";
}

.game-icon-crested-helmet:before {
  content: "\E1C9";
}

.game-icon-crown-coin:before {
  content: "\E1CA";
}

.game-icon-crown-of-thorns:before {
  content: "\E1CB";
}

.game-icon-crown:before {
  content: "\E1CC";
}

.game-icon-crowned-heart:before {
  content: "\E1CD";
}

.game-icon-crowned-skull:before {
  content: "\E1CE";
}

.game-icon-fedora:before {
  content: "\E1CF";
}

.game-icon-heavy-helm:before {
  content: "\E1D0";
}

.game-icon-helmet-head-shot:before {
  content: "\E1D1";
}

.game-icon-hood:before {
  content: "\E1D2";
}

.game-icon-horned-helm:before {
  content: "\E1D3";
}

.game-icon-laurel-crown:before {
  content: "\E1D4";
}

.game-icon-laurels:before {
  content: "\E1D5";
}

.game-icon-pointy-hat:before {
  content: "\E1D6";
}

.game-icon-queen-crown:before {
  content: "\E1D7";
}

.game-icon-sharp-crown:before {
  content: "\E1D8";
}

.game-icon-top-hat:before {
  content: "\E1D9";
}

.game-icon-visored-helm:before {
  content: "\E1DA";
}

.game-icon-helmet:before {
  content: "\E1DB";
}

.game-icon-brodie-helmet:before {
  content: "\E1DC";
}

.game-icon-pickelhaube:before {
  content: "\E1DD";
}

.game-icon-stahlhelm:before {
  content: "\E1DE";
}

.game-icon-wooden-helmet:before {
  content: "\E1DF";
}

.game-icon-nun-face:before {
  content: "\E1E0";
}

.game-icon-witch-face:before {
  content: "\E1E1";
}

.game-icon-barbarian:before {
  content: "\E1E2";
}

.game-icon-dwarf-face:before {
  content: "\E1E3";
}

.game-icon-executioner-hood:before {
  content: "\E1E4";
}

.game-icon-female-vampire:before {
  content: "\E1E5";
}

.game-icon-fish-monster:before {
  content: "\E1E6";
}

.game-icon-goblin-head:before {
  content: "\E1E7";
}

.game-icon-golem-head:before {
  content: "\E1E8";
}

.game-icon-kenku-head:before {
  content: "\E1E9";
}

.game-icon-monk-face:before {
  content: "\E1EA";
}

.game-icon-ogre:before {
  content: "\E1EB";
}

.game-icon-orc-head:before {
  content: "\E1EC";
}

.game-icon-vampire-dracula:before {
  content: "\E1ED";
}

.game-icon-wizard-face:before {
  content: "\E1EE";
}

.game-icon-woman-elf-face:before {
  content: "\E1EF";
}

.game-icon-cultist:before {
  content: "\E1F0";
}

.game-icon-diablo-skull:before {
  content: "\E1F1";
}

.game-icon-dragon-head:before {
  content: "\E1F2";
}

.game-icon-troll:before {
  content: "\E1F3";
}

.game-icon-crossbow:before {
  content: "\E1F4";
}

.game-icon-annexation:before {
  content: "\E1F5";
}

.game-icon-broken-arrow:before {
  content: "\E1F6";
}

.game-icon-forward-sun:before {
  content: "\E1F7";
}

.game-icon-dodge:before {
  content: "\E1F8";
}

.game-icon-overhead:before {
  content: "\E1F9";
}

.game-icon-sideswipe:before {
  content: "\E1FA";
}

.game-icon-thrust:before {
  content: "\E1FB";
}

.game-icon-underhand:before {
  content: "\E1FC";
}

.game-icon-achilles-heel:before {
  content: "\E1FD";
}

.game-icon-anticlockwise-rotation:before {
  content: "\E1FE";
}

.game-icon-archer:before {
  content: "\E1FF";
}

.game-icon-arrow-cursor:before {
  content: "\E200";
}

.game-icon-arrow-wings:before {
  content: "\E201";
}

.game-icon-avoidance:before {
  content: "\E202";
}

.game-icon-backward-time:before {
  content: "\E203";
}

.game-icon-body-height:before {
  content: "\E204";
}

.game-icon-bow-arrow:before {
  content: "\E205";
}

.game-icon-box-unpacking:before {
  content: "\E206";
}

.game-icon-brain-dump:before {
  content: "\E207";
}

.game-icon-chart:before {
  content: "\E208";
}

.game-icon-clockwise-rotation:before {
  content: "\E209";
}

.game-icon-cloud-download:before {
  content: "\E20A";
}

.game-icon-cloud-upload:before {
  content: "\E20B";
}

.game-icon-contract:before {
  content: "\E20C";
}

.game-icon-dart:before {
  content: "\E20D";
}

.game-icon-detour:before {
  content: "\E20E";
}

.game-icon-direction-sign:before {
  content: "\E20F";
}

.game-icon-encirclement:before {
  content: "\E210";
}

.game-icon-expand:before {
  content: "\E211";
}

.game-icon-expense:before {
  content: "\E212";
}

.game-icon-flag-objective:before {
  content: "\E213";
}

.game-icon-growth:before {
  content: "\E214";
}

.game-icon-horizontal-flip:before {
  content: "\E215";
}

.game-icon-join:before {
  content: "\E216";
}

.game-icon-misdirection:before {
  content: "\E217";
}

.game-icon-move:before {
  content: "\E218";
}

.game-icon-plain-arrow:before {
  content: "\E219";
}

.game-icon-progression:before {
  content: "\E21A";
}

.game-icon-quiver:before {
  content: "\E21B";
}

.game-icon-save-arrow:before {
  content: "\E21C";
}

.game-icon-slalom:before {
  content: "\E21D";
}

.game-icon-spear-feather:before {
  content: "\E21E";
}

.game-icon-spearfishing:before {
  content: "\E21F";
}

.game-icon-split-arrows:before {
  content: "\E220";
}

.game-icon-sun-spear:before {
  content: "\E221";
}

.game-icon-target-prize:before {
  content: "\E222";
}

.game-icon-thrust-bend:before {
  content: "\E223";
}

.game-icon-tree-growth:before {
  content: "\E224";
}

.game-icon-upgrade:before {
  content: "\E225";
}

.game-icon-vertical-flip:before {
  content: "\E226";
}

.game-icon-water-recycling:before {
  content: "\E227";
}

.game-icon-aerodynamic-harpoon:before {
  content: "\E228";
}

.game-icon-arrow-cluster:before {
  content: "\E229";
}

.game-icon-arrow-dunk:before {
  content: "\E22A";
}

.game-icon-arrow-flights:before {
  content: "\E22B";
}

.game-icon-arrowed:before {
  content: "\E22C";
}

.game-icon-arrowhead:before {
  content: "\E22D";
}

.game-icon-back-forth:before {
  content: "\E22E";
}

.game-icon-barbed-arrow:before {
  content: "\E22F";
}

.game-icon-barbed-spear:before {
  content: "\E230";
}

.game-icon-bottom-right-3d-arrow:before {
  content: "\E231";
}

.game-icon-bowman:before {
  content: "\E232";
}

.game-icon-branch-arrow:before {
  content: "\E233";
}

.game-icon-broadhead-arrow:before {
  content: "\E234";
}

.game-icon-chained-arrow-heads:before {
  content: "\E235";
}

.game-icon-charged-arrow:before {
  content: "\E236";
}

.game-icon-cheerful:before {
  content: "\E237";
}

.game-icon-chemical-arrow:before {
  content: "\E238";
}

.game-icon-crosshair-arrow:before {
  content: "\E239";
}

.game-icon-cupidon-arrow:before {
  content: "\E23A";
}

.game-icon-divergence:before {
  content: "\E23B";
}

.game-icon-divert:before {
  content: "\E23C";
}

.game-icon-double-shot:before {
  content: "\E23D";
}

.game-icon-energy-arrow:before {
  content: "\E23E";
}

.game-icon-falling-boulder:before {
  content: "\E23F";
}

.game-icon-fast-arrow:before {
  content: "\E240";
}

.game-icon-flaming-arrow:before {
  content: "\E241";
}

.game-icon-frayed-arrow:before {
  content: "\E242";
}

.game-icon-frozen-arrow:before {
  content: "\E243";
}

.game-icon-headshot:before {
  content: "\E244";
}

.game-icon-heavy-arrow:before {
  content: "\E245";
}

.game-icon-high-shot:before {
  content: "\E246";
}

.game-icon-ice-spear:before {
  content: "\E247";
}

.game-icon-imbricated-arrows:before {
  content: "\E248";
}

.game-icon-impact-point:before {
  content: "\E249";
}

.game-icon-interleaved-arrows:before {
  content: "\E24A";
}

.game-icon-lift:before {
  content: "\E24B";
}

.game-icon-lob-arrow:before {
  content: "\E24C";
}

.game-icon-middle-arrow:before {
  content: "\E24D";
}

.game-icon-on-target:before {
  content: "\E24E";
}

.game-icon-orb-direction:before {
  content: "\E24F";
}

.game-icon-paper-arrow:before {
  content: "\E250";
}

.game-icon-pentarrows-tornado:before {
  content: "\E251";
}

.game-icon-pierced-body:before {
  content: "\E252";
}

.game-icon-pierced-heart:before {
  content: "\E253";
}

.game-icon-piercing-sword:before {
  content: "\E254";
}

.game-icon-pocket-bow:before {
  content: "\E255";
}

.game-icon-profit:before {
  content: "\E256";
}

.game-icon-rally-the-troops:before {
  content: "\E257";
}

.game-icon-rapidshare-arrow:before {
  content: "\E258";
}

.game-icon-return-arrow:before {
  content: "\E259";
}

.game-icon-self-love:before {
  content: "\E25A";
}

.game-icon-slicing-arrow:before {
  content: "\E25B";
}

.game-icon-smash-arrows:before {
  content: "\E25C";
}

.game-icon-spear-hook:before {
  content: "\E25D";
}

.game-icon-spears:before {
  content: "\E25E";
}

.game-icon-spine-arrow:before {
  content: "\E25F";
}

.game-icon-spiral-arrow:before {
  content: "\E260";
}

.game-icon-spotted-arrowhead:before {
  content: "\E261";
}

.game-icon-stone-spear:before {
  content: "\E262";
}

.game-icon-striking-arrows:before {
  content: "\E263";
}

.game-icon-striking-clamps:before {
  content: "\E264";
}

.game-icon-supersonic-arrow:before {
  content: "\E265";
}

.game-icon-target-arrows:before {
  content: "\E266";
}

.game-icon-target-shot:before {
  content: "\E267";
}

.game-icon-thorn-helix:before {
  content: "\E268";
}

.game-icon-thorned-arrow:before {
  content: "\E269";
}

.game-icon-thrown-spear:before {
  content: "\E26A";
}

.game-icon-trade:before {
  content: "\E26B";
}

.game-icon-tron-arrow:before {
  content: "\E26C";
}

.game-icon-uncertainty:before {
  content: "\E26D";
}

.game-icon-wavy-itinerary:before {
  content: "\E26E";
}

.game-icon-wide-arrow-dunk:before {
  content: "\E26F";
}

.game-icon-william-tell-skull:before {
  content: "\E270";
}

.game-icon-william-tell:before {
  content: "\E271";
}

.game-icon-winged-arrow:before {
  content: "\E272";
}

.game-icon-zig-arrow:before {
  content: "\E273";
}

.game-icon-bunker-assault:before {
  content: "\E274";
}

.game-icon-trench-assault:before {
  content: "\E275";
}

.game-icon-fall-down:before {
  content: "\E276";
}

.game-icon-pounce:before {
  content: "\E277";
}

.game-icon-bullseye:before {
  content: "\E278";
}

.game-icon-flying-shuriken:before {
  content: "\E279";
}

.game-icon-kusarigama:before {
  content: "\E27A";
}

.game-icon-north-star-shuriken:before {
  content: "\E27B";
}

.game-icon-sharp-shuriken:before {
  content: "\E27C";
}

.game-icon-shuriken:before {
  content: "\E27D";
}

.game-icon-star-shuriken:before {
  content: "\E27E";
}

.game-icon-three-pointed-shuriken:before {
  content: "\E27F";
}

.game-icon-whirlpool-shuriken:before {
  content: "\E280";
}

.game-icon-ninja-star:before {
  content: "\E281";
}

.game-icon-shepherds-crook:before {
  content: "\E282";
}

.game-icon-armored-boomerang:before {
  content: "\E283";
}

.game-icon-baseball-bat:before {
  content: "\E284";
}

.game-icon-bo:before {
  content: "\E285";
}

.game-icon-bone-mace:before {
  content: "\E286";
}

.game-icon-boomerang-cross:before {
  content: "\E287";
}

.game-icon-boomerang:before {
  content: "\E288";
}

.game-icon-bow-string:before {
  content: "\E289";
}

.game-icon-brass-knuckles:before {
  content: "\E28A";
}

.game-icon-broken-axe:before {
  content: "\E28B";
}

.game-icon-claymore-explosive:before {
  content: "\E28C";
}

.game-icon-crescent-staff:before {
  content: "\E28D";
}

.game-icon-crowbar:before {
  content: "\E28E";
}

.game-icon-dagger-rose:before {
  content: "\E28F";
}

.game-icon-detonator:before {
  content: "\E290";
}

.game-icon-flail:before {
  content: "\E291";
}

.game-icon-flamethrower-soldier:before {
  content: "\E292";
}

.game-icon-flamethrower:before {
  content: "\E293";
}

.game-icon-flanged-mace:before {
  content: "\E294";
}

.game-icon-gun-rose:before {
  content: "\E295";
}

.game-icon-heavy-bullets:before {
  content: "\E296";
}

.game-icon-human-cannonball:before {
  content: "\E297";
}

.game-icon-hunting-bolas:before {
  content: "\E298";
}

.game-icon-laser-turret:before {
  content: "\E299";
}

.game-icon-lasso:before {
  content: "\E29A";
}

.game-icon-lead-pipe:before {
  content: "\E29B";
}

.game-icon-lunar-wand:before {
  content: "\E29C";
}

.game-icon-magic-trident:before {
  content: "\E29D";
}

.game-icon-missile-launcher:before {
  content: "\E29E";
}

.game-icon-monkey-wrench:before {
  content: "\E29F";
}

.game-icon-mortar:before {
  content: "\E2A0";
}

.game-icon-nunchaku:before {
  content: "\E2A1";
}

.game-icon-panzerfaust:before {
  content: "\E2A2";
}

.game-icon-reload-gun-barrel:before {
  content: "\E2A3";
}

.game-icon-revolver:before {
  content: "\E2A4";
}

.game-icon-saber-and-pistol:before {
  content: "\E2A5";
}

.game-icon-sai:before {
  content: "\E2A6";
}

.game-icon-sawed-off-shotgun:before {
  content: "\E2A7";
}

.game-icon-sharp-axe:before {
  content: "\E2A8";
}

.game-icon-silver-bullet:before {
  content: "\E2A9";
}

.game-icon-skull-staff:before {
  content: "\E2AA";
}

.game-icon-sling:before {
  content: "\E2AB";
}

.game-icon-slingshot:before {
  content: "\E2AC";
}

.game-icon-stake-hammer:before {
  content: "\E2AD";
}

.game-icon-submarine-missile:before {
  content: "\E2AE";
}

.game-icon-switch-weapon:before {
  content: "\E2AF";
}

.game-icon-sword-brandish:before {
  content: "\E2B0";
}

.game-icon-thor-hammer:before {
  content: "\E2B1";
}

.game-icon-tomahawk:before {
  content: "\E2B2";
}

.game-icon-torpedo:before {
  content: "\E2B3";
}

.game-icon-umbrella-bayonet:before {
  content: "\E2B4";
}

.game-icon-uzi:before {
  content: "\E2B5";
}

.game-icon-walking-turret:before {
  content: "\E2B6";
}

.game-icon-war-axe:before {
  content: "\E2B7";
}

.game-icon-war-pick:before {
  content: "\E2B8";
}

.game-icon-warhammer:before {
  content: "\E2B9";
}

.game-icon-winged-scepter:before {
  content: "\E2BA";
}

.game-icon-wood-club:before {
  content: "\E2BB";
}

.game-icon-zat-gun:before {
  content: "\E2BC";
}

.game-icon-all-for-one:before {
  content: "\E2BD";
}

.game-icon-andromeda-chain:before {
  content: "\E2BE";
}

.game-icon-archery-target:before {
  content: "\E2BF";
}

.game-icon-autogun:before {
  content: "\E2C0";
}

.game-icon-axe-in-stump:before {
  content: "\E2C1";
}

.game-icon-backstab:before {
  content: "\E2C2";
}

.game-icon-battered-axe:before {
  content: "\E2C3";
}

.game-icon-battle-axe:before {
  content: "\E2C4";
}

.game-icon-beam-wake:before {
  content: "\E2C5";
}

.game-icon-blunderbuss:before {
  content: "\E2C6";
}

.game-icon-bolas:before {
  content: "\E2C7";
}

.game-icon-bolter-gun:before {
  content: "\E2C8";
}

.game-icon-broken-bottle:before {
  content: "\E2C9";
}

.game-icon-bullets:before {
  content: "\E2CA";
}

.game-icon-cannon-ball:before {
  content: "\E2CB";
}

.game-icon-cannon-shot:before {
  content: "\E2CC";
}

.game-icon-cannon:before {
  content: "\E2CD";
}

.game-icon-crossed-pistols:before {
  content: "\E2CE";
}

.game-icon-crossed-sabres:before {
  content: "\E2CF";
}

.game-icon-crossed-swords:before {
  content: "\E2D0";
}

.game-icon-crystal-wand:before {
  content: "\E2D1";
}

.game-icon-dripping-knife:before {
  content: "\E2D2";
}

.game-icon-fairy-wand:before {
  content: "\E2D3";
}

.game-icon-flaming-trident:before {
  content: "\E2D4";
}

.game-icon-grapple:before {
  content: "\E2D5";
}

.game-icon-grenade:before {
  content: "\E2D6";
}

.game-icon-gunshot:before {
  content: "\E2D7";
}

.game-icon-halberd-shuriken:before {
  content: "\E2D8";
}

.game-icon-harpoon-chain:before {
  content: "\E2D9";
}

.game-icon-harpoon-trident:before {
  content: "\E2DA";
}

.game-icon-laser-blast:before {
  content: "\E2DB";
}

.game-icon-lightning-bow:before {
  content: "\E2DC";
}

.game-icon-mace-head:before {
  content: "\E2DD";
}

.game-icon-machete:before {
  content: "\E2DE";
}

.game-icon-minigun:before {
  content: "\E2DF";
}

.game-icon-missile-pod:before {
  content: "\E2E0";
}

.game-icon-missile-swarm:before {
  content: "\E2E1";
}

.game-icon-pincers:before {
  content: "\E2E2";
}

.game-icon-ray-gun:before {
  content: "\E2E3";
}

.game-icon-reticule:before {
  content: "\E2E4";
}

.game-icon-saber-slash:before {
  content: "\E2E5";
}

.game-icon-sabers-choc:before {
  content: "\E2E6";
}

.game-icon-sacrificial-dagger:before {
  content: "\E2E7";
}

.game-icon-scalpel-strike:before {
  content: "\E2E8";
}

.game-icon-scythe:before {
  content: "\E2E9";
}

.game-icon-sentry-gun:before {
  content: "\E2EA";
}

.game-icon-shuriken-2:before {
  content: "\E2EB";
}

.game-icon-slavery-whip:before {
  content: "\E2EC";
}

.game-icon-sparkling-sabre:before {
  content: "\E2ED";
}

.game-icon-spiked-mace:before {
  content: "\E2EE";
}

.game-icon-spray:before {
  content: "\E2EF";
}

.game-icon-stiletto:before {
  content: "\E2F0";
}

.game-icon-supersonic-bullet:before {
  content: "\E2F1";
}

.game-icon-sword-clash:before {
  content: "\E2F2";
}

.game-icon-sword-in-stone:before {
  content: "\E2F3";
}

.game-icon-sword-spade:before {
  content: "\E2F4";
}

.game-icon-tesla-turret:before {
  content: "\E2F5";
}

.game-icon-trefoil-shuriken:before {
  content: "\E2F6";
}

.game-icon-trident:before {
  content: "\E2F7";
}

.game-icon-wave-strike:before {
  content: "\E2F8";
}

.game-icon-whip:before {
  content: "\E2F9";
}

.game-icon-winged-sword:before {
  content: "\E2FA";
}

.game-icon-wizard-staff:before {
  content: "\E2FB";
}

.game-icon-anti-aircraft-gun:before {
  content: "\E2FC";
}

.game-icon-field-gun:before {
  content: "\E2FD";
}

.game-icon-ammo-box:before {
  content: "\E2FE";
}

.game-icon-chaingun:before {
  content: "\E2FF";
}

.game-icon-duel:before {
  content: "\E300";
}

.game-icon-grenade-2:before {
  content: "\E301";
}

.game-icon-laser-gun:before {
  content: "\E302";
}

.game-icon-rifle:before {
  content: "\E303";
}

.game-icon-shotgun:before {
  content: "\E304";
}

.game-icon-turret:before {
  content: "\E305";
}

.game-icon-bayonet:before {
  content: "\E306";
}

.game-icon-drop-weapon:before {
  content: "\E307";
}

.game-icon-gladius:before {
  content: "\E308";
}

.game-icon-lee-enfield:before {
  content: "\E309";
}

.game-icon-luger:before {
  content: "\E30A";
}

.game-icon-nuclear-bomb:before {
  content: "\E30B";
}

.game-icon-spiked-ball:before {
  content: "\E30C";
}

.game-icon-spiked-bat:before {
  content: "\E30D";
}

.game-icon-stick-grenade:before {
  content: "\E30E";
}

.game-icon-tec-9:before {
  content: "\E30F";
}

.game-icon-telescopic-baton:before {
  content: "\E310";
}

.game-icon-teller-mine:before {
  content: "\E311";
}

.game-icon-orb-wand:before {
  content: "\E312";
}

.game-icon-flame:before {
  content: "\E313";
}

.game-icon-bellows:before {
  content: "\E314";
}

.game-icon-brasero:before {
  content: "\E315";
}

.game-icon-burning-forest:before {
  content: "\E316";
}

.game-icon-camp-cooking-pot:before {
  content: "\E317";
}

.game-icon-candles:before {
  content: "\E318";
}

.game-icon-dice-fire:before {
  content: "\E319";
}

.game-icon-fire-extinguisher:before {
  content: "\E31A";
}

.game-icon-fire-flower:before {
  content: "\E31B";
}

.game-icon-fire-gem:before {
  content: "\E31C";
}

.game-icon-fire-shrine:before {
  content: "\E31D";
}

.game-icon-fire-spell-cast:before {
  content: "\E31E";
}

.game-icon-fireplace:before {
  content: "\E31F";
}

.game-icon-firewall:before {
  content: "\E320";
}

.game-icon-fish-smoking:before {
  content: "\E321";
}

.game-icon-flint-spark:before {
  content: "\E322";
}

.game-icon-furnace:before {
  content: "\E323";
}

.game-icon-gas-stove:before {
  content: "\E324";
}

.game-icon-lighter:before {
  content: "\E325";
}

.game-icon-lightning-flame:before {
  content: "\E326";
}

.game-icon-marshmallows:before {
  content: "\E327";
}

.game-icon-matchbox:before {
  content: "\E328";
}

.game-icon-primitive-torch:before {
  content: "\E329";
}

.game-icon-pyre:before {
  content: "\E32A";
}

.game-icon-torch:before {
  content: "\E32B";
}

.game-icon-alien-fire:before {
  content: "\E32C";
}

.game-icon-arson:before {
  content: "\E32D";
}

.game-icon-burning-book:before {
  content: "\E32E";
}

.game-icon-burning-dot:before {
  content: "\E32F";
}

.game-icon-burning-embers:before {
  content: "\E330";
}

.game-icon-burning-eye:before {
  content: "\E331";
}

.game-icon-burning-meteor:before {
  content: "\E332";
}

.game-icon-burning-passion:before {
  content: "\E333";
}

.game-icon-burning-tree:before {
  content: "\E334";
}

.game-icon-caldera:before {
  content: "\E335";
}

.game-icon-campfire:before {
  content: "\E336";
}

.game-icon-candle-flame:before {
  content: "\E337";
}

.game-icon-candle-holder:before {
  content: "\E338";
}

.game-icon-candle-light:before {
  content: "\E339";
}

.game-icon-candlebright:before {
  content: "\E33A";
}

.game-icon-celebration-fire:before {
  content: "\E33B";
}

.game-icon-dragon-breath:before {
  content: "\E33C";
}

.game-icon-ember-shot:before {
  content: "\E33D";
}

.game-icon-eruption:before {
  content: "\E33E";
}

.game-icon-fire-ace:before {
  content: "\E33F";
}

.game-icon-fire-axe:before {
  content: "\E340";
}

.game-icon-fire-bomb:before {
  content: "\E341";
}

.game-icon-fire-bottle:before {
  content: "\E342";
}

.game-icon-fire-bowl:before {
  content: "\E343";
}

.game-icon-fire-breath:before {
  content: "\E344";
}

.game-icon-fire-dash:before {
  content: "\E345";
}

.game-icon-fire-punch:before {
  content: "\E346";
}

.game-icon-fire-ray:before {
  content: "\E347";
}

.game-icon-fire-ring:before {
  content: "\E348";
}

.game-icon-fire-silhouette:before {
  content: "\E349";
}

.game-icon-fire-tail:before {
  content: "\E34A";
}

.game-icon-fire-wave:before {
  content: "\E34B";
}

.game-icon-fire-zone:before {
  content: "\E34C";
}

.game-icon-fireball:before {
  content: "\E34D";
}

.game-icon-firework-rocket:before {
  content: "\E34E";
}

.game-icon-flame-claws:before {
  content: "\E34F";
}

.game-icon-flame-spin:before {
  content: "\E350";
}

.game-icon-flame-tunnel:before {
  content: "\E351";
}

.game-icon-flamed-leaf:before {
  content: "\E352";
}

.game-icon-flaming-claw:before {
  content: "\E353";
}

.game-icon-flaming-sheet:before {
  content: "\E354";
}

.game-icon-fluffy-flame:before {
  content: "\E355";
}

.game-icon-frostfire:before {
  content: "\E356";
}

.game-icon-heartburn:before {
  content: "\E357";
}

.game-icon-heat-haze:before {
  content: "\E358";
}

.game-icon-hot-spices:before {
  content: "\E359";
}

.game-icon-ifrit:before {
  content: "\E35A";
}

.game-icon-kindle:before {
  content: "\E35B";
}

.game-icon-lantern-flame:before {
  content: "\E35C";
}

.game-icon-lit-candelabra:before {
  content: "\E35D";
}

.game-icon-match-head:before {
  content: "\E35E";
}

.game-icon-match-tip:before {
  content: "\E35F";
}

.game-icon-molotov:before {
  content: "\E360";
}

.game-icon-plasma-bolt:before {
  content: "\E361";
}

.game-icon-pyromaniac:before {
  content: "\E362";
}

.game-icon-salamander:before {
  content: "\E363";
}

.game-icon-small-fire:before {
  content: "\E364";
}

.game-icon-spark-spirit:before {
  content: "\E365";
}

.game-icon-three-burning-balls:before {
  content: "\E366";
}

.game-icon-thrown-charcoal:before {
  content: "\E367";
}

.game-icon-unfriendly-fire:before {
  content: "\E368";
}

.game-icon-volcano:before {
  content: "\E369";
}

.game-icon-wildfires:before {
  content: "\E36A";
}

.game-icon-blast:before {
  content: "\E36B";
}

.game-icon-blaster:before {
  content: "\E36C";
}

.game-icon-burn:before {
  content: "\E36D";
}

.game-icon-fire:before {
  content: "\E36E";
}

.game-icon-fireflake:before {
  content: "\E36F";
}

.game-icon-flamer:before {
  content: "\E370";
}

.game-icon-hot-surface:before {
  content: "\E371";
}

.game-icon-lava:before {
  content: "\E372";
}

.game-icon-lucifer-cannon:before {
  content: "\E373";
}

.game-icon-burning-skull:before {
  content: "\E374";
}

.game-icon-mite-alt:before {
  content: "\E375";
}

.game-icon-spider-alt:before {
  content: "\E376";
}

.game-icon-spider-face:before {
  content: "\E377";
}

.game-icon-earth-worm:before {
  content: "\E378";
}

.game-icon-flying-trout:before {
  content: "\E379";
}

.game-icon-polar-bear:before {
  content: "\E37A";
}

.game-icon-plants-and-animals:before {
  content: "\E37B";
}

.game-icon-animal-hide:before {
  content: "\E37C";
}

.game-icon-bat-2:before {
  content: "\E37D";
}

.game-icon-bear-head:before {
  content: "\E37E";
}

.game-icon-camel-head:before {
  content: "\E37F";
}

.game-icon-cassowary-head:before {
  content: "\E380";
}

.game-icon-charging-bull:before {
  content: "\E381";
}

.game-icon-chicken:before {
  content: "\E382";
}

.game-icon-clownfish:before {
  content: "\E383";
}

.game-icon-cow:before {
  content: "\E384";
}

.game-icon-cricket:before {
  content: "\E385";
}

.game-icon-deer-track:before {
  content: "\E386";
}

.game-icon-dog-bowl:before {
  content: "\E387";
}

.game-icon-dog-house:before {
  content: "\E388";
}

.game-icon-dolphin:before {
  content: "\E389";
}

.game-icon-duck:before {
  content: "\E38A";
}

.game-icon-eagle-head:before {
  content: "\E38B";
}

.game-icon-elephant-head:before {
  content: "\E38C";
}

.game-icon-elephant:before {
  content: "\E38D";
}

.game-icon-falcon-moon:before {
  content: "\E38E";
}

.game-icon-feline:before {
  content: "\E38F";
}

.game-icon-finch:before {
  content: "\E390";
}

.game-icon-flamingo:before {
  content: "\E391";
}

.game-icon-fox-tail:before {
  content: "\E392";
}

.game-icon-giant-squid:before {
  content: "\E393";
}

.game-icon-gorilla:before {
  content: "\E394";
}

.game-icon-griffin-symbol:before {
  content: "\E395";
}

.game-icon-honey-jar:before {
  content: "\E396";
}

.game-icon-horse-head:before {
  content: "\E397";
}

.game-icon-ivory-tusks:before {
  content: "\E398";
}

.game-icon-kangaroo:before {
  content: "\E399";
}

.game-icon-koala:before {
  content: "\E39A";
}

.game-icon-labrador-head:before {
  content: "\E39B";
}

.game-icon-lynx-head:before {
  content: "\E39C";
}

.game-icon-manta-ray:before {
  content: "\E39D";
}

.game-icon-moon-bats:before {
  content: "\E39E";
}

.game-icon-panda:before {
  content: "\E39F";
}

.game-icon-penguin:before {
  content: "\E3A0";
}

.game-icon-pinata:before {
  content: "\E3A1";
}

.game-icon-praying-mantis:before {
  content: "\E3A2";
}

.game-icon-rabbit:before {
  content: "\E3A3";
}

.game-icon-rat:before {
  content: "\E3A4";
}

.game-icon-rhinoceros-horn:before {
  content: "\E3A5";
}

.game-icon-rooster:before {
  content: "\E3A6";
}

.game-icon-saddle:before {
  content: "\E3A7";
}

.game-icon-sea-star:before {
  content: "\E3A8";
}

.game-icon-seahorse:before {
  content: "\E3A9";
}

.game-icon-shark-bite:before {
  content: "\E3AA";
}

.game-icon-shark-fin:before {
  content: "\E3AB";
}

.game-icon-sheep:before {
  content: "\E3AC";
}

.game-icon-snail-eyes:before {
  content: "\E3AD";
}

.game-icon-sperm-whale:before {
  content: "\E3AE";
}

.game-icon-stable:before {
  content: "\E3AF";
}

.game-icon-tiger-head:before {
  content: "\E3B0";
}

.game-icon-tiger:before {
  content: "\E3B1";
}

.game-icon-tortoise:before {
  content: "\E3B2";
}

.game-icon-toucan:before {
  content: "\E3B3";
}

.game-icon-trojan-horse:before {
  content: "\E3B4";
}

.game-icon-udder:before {
  content: "\E3B5";
}

.game-icon-whale-tail:before {
  content: "\E3B6";
}

.game-icon-wool:before {
  content: "\E3B7";
}

.game-icon-worms:before {
  content: "\E3B8";
}

.game-icon-yarn:before {
  content: "\E3B9";
}

.game-icon-ammonite-fossil:before {
  content: "\E3BA";
}

.game-icon-ammonite:before {
  content: "\E3BB";
}

.game-icon-angler-fish:before {
  content: "\E3BC";
}

.game-icon-angular-spider:before {
  content: "\E3BD";
}

.game-icon-animal-skull:before {
  content: "\E3BE";
}

.game-icon-armadillo-tail:before {
  content: "\E3BF";
}

.game-icon-bat-wing:before {
  content: "\E3C0";
}

.game-icon-bee:before {
  content: "\E3C1";
}

.game-icon-bird-twitter:before {
  content: "\E3C2";
}

.game-icon-boar-tusks:before {
  content: "\E3C3";
}

.game-icon-bull-horns:before {
  content: "\E3C4";
}

.game-icon-bull:before {
  content: "\E3C5";
}

.game-icon-butterfly-warning:before {
  content: "\E3C6";
}

.game-icon-butterfly:before {
  content: "\E3C7";
}

.game-icon-cat:before {
  content: "\E3C8";
}

.game-icon-centipede:before {
  content: "\E3C9";
}

.game-icon-chicken-leg:before {
  content: "\E3CA";
}

.game-icon-cobweb:before {
  content: "\E3CB";
}

.game-icon-crab-claw:before {
  content: "\E3CC";
}

.game-icon-crab:before {
  content: "\E3CD";
}

.game-icon-croc-jaws:before {
  content: "\E3CE";
}

.game-icon-crossed-claws:before {
  content: "\E3CF";
}

.game-icon-crow-dive:before {
  content: "\E3D0";
}

.game-icon-desert-skull:before {
  content: "\E3D1";
}

.game-icon-dinosaur-rex:before {
  content: "\E3D2";
}

.game-icon-dragonfly:before {
  content: "\E3D3";
}

.game-icon-earwig:before {
  content: "\E3D4";
}

.game-icon-evil-bat:before {
  content: "\E3D5";
}

.game-icon-fish-corpse:before {
  content: "\E3D6";
}

.game-icon-fishbone:before {
  content: "\E3D7";
}

.game-icon-flat-paw-print:before {
  content: "\E3D8";
}

.game-icon-food-chain:before {
  content: "\E3D9";
}

.game-icon-fox-head:before {
  content: "\E3DA";
}

.game-icon-frog:before {
  content: "\E3DB";
}

.game-icon-gecko:before {
  content: "\E3DC";
}

.game-icon-gold-scarab:before {
  content: "\E3DD";
}

.game-icon-grasping-claws:before {
  content: "\E3DE";
}

.game-icon-hanging-spider:before {
  content: "\E3DF";
}

.game-icon-hollow-cat:before {
  content: "\E3E0";
}

.game-icon-hoof:before {
  content: "\E3E1";
}

.game-icon-horse-head-2:before {
  content: "\E3E2";
}

.game-icon-hound:before {
  content: "\E3E3";
}

.game-icon-insect-jaws:before {
  content: "\E3E4";
}

.game-icon-jellyfish:before {
  content: "\E3E5";
}

.game-icon-lamprey-mouth:before {
  content: "\E3E6";
}

.game-icon-leeching-worm:before {
  content: "\E3E7";
}

.game-icon-lion:before {
  content: "\E3E8";
}

.game-icon-maggot:before {
  content: "\E3E9";
}

.game-icon-masked-spider:before {
  content: "\E3EA";
}

.game-icon-minotaur:before {
  content: "\E3EB";
}

.game-icon-mite:before {
  content: "\E3EC";
}

.game-icon-monkey:before {
  content: "\E3ED";
}

.game-icon-mouse:before {
  content: "\E3EE";
}

.game-icon-octopus:before {
  content: "\E3EF";
}

.game-icon-ouroboros:before {
  content: "\E3F0";
}

.game-icon-owl:before {
  content: "\E3F1";
}

.game-icon-parmecia:before {
  content: "\E3F2";
}

.game-icon-parrot-head:before {
  content: "\E3F3";
}

.game-icon-paw-front:before {
  content: "\E3F4";
}

.game-icon-paw-heart:before {
  content: "\E3F5";
}

.game-icon-paw-print:before {
  content: "\E3F6";
}

.game-icon-paw:before {
  content: "\E3F7";
}

.game-icon-raven:before {
  content: "\E3F8";
}

.game-icon-roast-chicken:before {
  content: "\E3F9";
}

.game-icon-sad-crab:before {
  content: "\E3FA";
}

.game-icon-scarab-beetle:before {
  content: "\E3FB";
}

.game-icon-scorpion-tail:before {
  content: "\E3FC";
}

.game-icon-scorpion:before {
  content: "\E3FD";
}

.game-icon-sea-serpent:before {
  content: "\E3FE";
}

.game-icon-seated-mouse:before {
  content: "\E3FF";
}

.game-icon-shark-jaws:before {
  content: "\E400";
}

.game-icon-snail:before {
  content: "\E401";
}

.game-icon-snake-bite:before {
  content: "\E402";
}

.game-icon-snake-totem:before {
  content: "\E403";
}

.game-icon-snake:before {
  content: "\E404";
}

.game-icon-sonic-screech:before {
  content: "\E405";
}

.game-icon-spider-web:before {
  content: "\E406";
}

.game-icon-spiked-snail:before {
  content: "\E407";
}

.game-icon-squid-head:before {
  content: "\E408";
}

.game-icon-squid:before {
  content: "\E409";
}

.game-icon-stag-head:before {
  content: "\E40A";
}

.game-icon-stomp:before {
  content: "\E40B";
}

.game-icon-swan:before {
  content: "\E40C";
}

.game-icon-tick:before {
  content: "\E40D";
}

.game-icon-top-paw:before {
  content: "\E40E";
}

.game-icon-trilobite:before {
  content: "\E40F";
}

.game-icon-turd:before {
  content: "\E410";
}

.game-icon-turtle-shell:before {
  content: "\E411";
}

.game-icon-turtle:before {
  content: "\E412";
}

.game-icon-virus:before {
  content: "\E413";
}

.game-icon-vulture:before {
  content: "\E414";
}

.game-icon-wasp-sting:before {
  content: "\E415";
}

.game-icon-web-spit:before {
  content: "\E416";
}

.game-icon-werewolf:before {
  content: "\E417";
}

.game-icon-wolf-head:before {
  content: "\E418";
}

.game-icon-wolf-howl:before {
  content: "\E419";
}

.game-icon-wolverine-claws:before {
  content: "\E41A";
}

.game-icon-worm-mouth:before {
  content: "\E41B";
}

.game-icon-big-egg:before {
  content: "\E41C";
}

.game-icon-claw:before {
  content: "\E41D";
}

.game-icon-poison:before {
  content: "\E41E";
}

.game-icon-bear-face:before {
  content: "\E41F";
}

.game-icon-donkey:before {
  content: "\E420";
}

.game-icon-goat:before {
  content: "\E421";
}

.game-icon-long-legged-spider:before {
  content: "\E422";
}

.game-icon-mounted-knight:before {
  content: "\E423";
}

.game-icon-pegasus:before {
  content: "\E424";
}

.game-icon-pig:before {
  content: "\E425";
}

.game-icon-amber-mosquito:before {
  content: "\E426";
}

.game-icon-ant:before {
  content: "\E427";
}

.game-icon-ants:before {
  content: "\E428";
}

.game-icon-beehive:before {
  content: "\E429";
}

.game-icon-bug-net:before {
  content: "\E42A";
}

.game-icon-butterfly-flower:before {
  content: "\E42B";
}

.game-icon-caterpillar:before {
  content: "\E42C";
}

.game-icon-cigale:before {
  content: "\E42D";
}

.game-icon-claws:before {
  content: "\E42E";
}

.game-icon-fly:before {
  content: "\E42F";
}

.game-icon-flying-beetle:before {
  content: "\E430";
}

.game-icon-ladybug:before {
  content: "\E431";
}

.game-icon-shrimp:before {
  content: "\E432";
}

.game-icon-spider-eye:before {
  content: "\E433";
}

.game-icon-tree-beehive:before {
  content: "\E434";
}

.game-icon-apple-maggot:before {
  content: "\E435";
}

.game-icon-beetle-shell:before {
  content: "\E436";
}

.game-icon-dripping-honey:before {
  content: "\E437";
}

.game-icon-long-antennae-bug:before {
  content: "\E438";
}

.game-icon-opening-shell:before {
  content: "\E439";
}

.game-icon-spotted-bug:before {
  content: "\E43A";
}

.game-icon-acrobatic:before {
  content: "\E43B";
}

.game-icon-catch:before {
  content: "\E43C";
}

.game-icon-running-ninja:before {
  content: "\E43D";
}

.game-icon-button-finger:before {
  content: "\E43E";
}

.game-icon-chat-bubble:before {
  content: "\E43F";
}

.game-icon-choice:before {
  content: "\E440";
}

.game-icon-convince:before {
  content: "\E441";
}

.game-icon-crafting:before {
  content: "\E442";
}

.game-icon-discussion:before {
  content: "\E443";
}

.game-icon-drinking:before {
  content: "\E444";
}

.game-icon-eating:before {
  content: "\E445";
}

.game-icon-flying-fox:before {
  content: "\E446";
}

.game-icon-hiking:before {
  content: "\E447";
}

.game-icon-hive-mind:before {
  content: "\E448";
}

.game-icon-jump-across:before {
  content: "\E449";
}

.game-icon-kneeling:before {
  content: "\E44A";
}

.game-icon-leapfrog:before {
  content: "\E44B";
}

.game-icon-look-at:before {
  content: "\E44C";
}

.game-icon-miner:before {
  content: "\E44D";
}

.game-icon-night-sleep:before {
  content: "\E44E";
}

.game-icon-public-speaker:before {
  content: "\E44F";
}

.game-icon-pull:before {
  content: "\E450";
}

.game-icon-push:before {
  content: "\E451";
}

.game-icon-shaking-hands:before {
  content: "\E452";
}

.game-icon-shrug:before {
  content: "\E453";
}

.game-icon-skills:before {
  content: "\E454";
}

.game-icon-stairs-goal:before {
  content: "\E455";
}

.game-icon-teacher:before {
  content: "\E456";
}

.game-icon-team-idea:before {
  content: "\E457";
}

.game-icon-think:before {
  content: "\E458";
}

.game-icon-throwing-ball:before {
  content: "\E459";
}

.game-icon-uprising:before {
  content: "\E45A";
}

.game-icon-vote:before {
  content: "\E45B";
}

.game-icon-walk:before {
  content: "\E45C";
}

.game-icon-boot-kick:before {
  content: "\E45D";
}

.game-icon-boot-stomp:before {
  content: "\E45E";
}

.game-icon-disintegrate:before {
  content: "\E45F";
}

.game-icon-dodging:before {
  content: "\E460";
}

.game-icon-drowning:before {
  content: "\E461";
}

.game-icon-fruiting:before {
  content: "\E462";
}

.game-icon-grab:before {
  content: "\E463";
}

.game-icon-journey:before {
  content: "\E464";
}

.game-icon-juggler:before {
  content: "\E465";
}

.game-icon-meditation:before {
  content: "\E466";
}

.game-icon-mining:before {
  content: "\E467";
}

.game-icon-muscle-up:before {
  content: "\E468";
}

.game-icon-pointing:before {
  content: "\E469";
}

.game-icon-pouring-chalice:before {
  content: "\E46A";
}

.game-icon-prayer:before {
  content: "\E46B";
}

.game-icon-run:before {
  content: "\E46C";
}

.game-icon-screaming:before {
  content: "\E46D";
}

.game-icon-shouting:before {
  content: "\E46E";
}

.game-icon-sing:before {
  content: "\E46F";
}

.game-icon-slap:before {
  content: "\E470";
}

.game-icon-snatch:before {
  content: "\E471";
}

.game-icon-sprint:before {
  content: "\E472";
}

.game-icon-stick-splitting:before {
  content: "\E473";
}

.game-icon-stone-crafting:before {
  content: "\E474";
}

.game-icon-swallow:before {
  content: "\E475";
}

.game-icon-targeting:before {
  content: "\E476";
}

.game-icon-tearing:before {
  content: "\E477";
}

.game-icon-teleport:before {
  content: "\E478";
}

.game-icon-transfuse:before {
  content: "\E479";
}

.game-icon-vomiting:before {
  content: "\E47A";
}

.game-icon-crush:before {
  content: "\E47B";
}

.game-icon-amputation:before {
  content: "\E47C";
}

.game-icon-read:before {
  content: "\E47D";
}

.game-icon-talk:before {
  content: "\E47E";
}

.game-icon-africa:before {
  content: "\E47F";
}

.game-icon-algeria:before {
  content: "\E480";
}

.game-icon-angola:before {
  content: "\E481";
}

.game-icon-antarctica:before {
  content: "\E482";
}

.game-icon-atlas:before {
  content: "\E483";
}

.game-icon-australia:before {
  content: "\E484";
}

.game-icon-belgium:before {
  content: "\E485";
}

.game-icon-binoculars:before {
  content: "\E486";
}

.game-icon-black-sea:before {
  content: "\E487";
}

.game-icon-bolivia:before {
  content: "\E488";
}

.game-icon-brazil:before {
  content: "\E489";
}

.game-icon-bulgaria:before {
  content: "\E48A";
}

.game-icon-cambodia:before {
  content: "\E48B";
}

.game-icon-colombia:before {
  content: "\E48C";
}

.game-icon-corsica:before {
  content: "\E48D";
}

.game-icon-crossroad:before {
  content: "\E48E";
}

.game-icon-direction-signs:before {
  content: "\E48F";
}

.game-icon-earth-africa-europe:before {
  content: "\E490";
}

.game-icon-earth-america:before {
  content: "\E491";
}

.game-icon-earth-asia-oceania:before {
  content: "\E492";
}

.game-icon-ecology:before {
  content: "\E493";
}

.game-icon-egypt:before {
  content: "\E494";
}

.game-icon-france:before {
  content: "\E495";
}

.game-icon-guatemala:before {
  content: "\E496";
}

.game-icon-hungary:before {
  content: "\E497";
}

.game-icon-iceland:before {
  content: "\E498";
}

.game-icon-iraq:before {
  content: "\E499";
}

.game-icon-italia:before {
  content: "\E49A";
}

.game-icon-japan:before {
  content: "\E49B";
}

.game-icon-kenya:before {
  content: "\E49C";
}

.game-icon-latvia:before {
  content: "\E49D";
}

.game-icon-libya:before {
  content: "\E49E";
}

.game-icon-mesh-network:before {
  content: "\E49F";
}

.game-icon-mexico:before {
  content: "\E4A0";
}

.game-icon-moldova:before {
  content: "\E4A1";
}

.game-icon-mongolia:before {
  content: "\E4A2";
}

.game-icon-nigeria:before {
  content: "\E4A3";
}

.game-icon-paraguay:before {
  content: "\E4A4";
}

.game-icon-path-distance:before {
  content: "\E4A5";
}

.game-icon-peru:before {
  content: "\E4A6";
}

.game-icon-poland:before {
  content: "\E4A7";
}

.game-icon-portugal:before {
  content: "\E4A8";
}

.game-icon-position-marker:before {
  content: "\E4A9";
}

.game-icon-radar-cross-section:before {
  content: "\E4AA";
}

.game-icon-road:before {
  content: "\E4AB";
}

.game-icon-sextant:before {
  content: "\E4AC";
}

.game-icon-south-africa:before {
  content: "\E4AD";
}

.game-icon-south-america:before {
  content: "\E4AE";
}

.game-icon-south-korea:before {
  content: "\E4AF";
}

.game-icon-spain:before {
  content: "\E4B0";
}

.game-icon-sri-lanka:before {
  content: "\E4B1";
}

.game-icon-switzerland:before {
  content: "\E4B2";
}

.game-icon-tanzania:before {
  content: "\E4B3";
}

.game-icon-texas:before {
  content: "\E4B4";
}

.game-icon-trail:before {
  content: "\E4B5";
}

.game-icon-tunisia:before {
  content: "\E4B6";
}

.game-icon-uruguay:before {
  content: "\E4B7";
}

.game-icon-venezuela:before {
  content: "\E4B8";
}

.game-icon-wireframe-globe:before {
  content: "\E4B9";
}

.game-icon-compass:before {
  content: "\E4BA";
}

.game-icon-globe:before {
  content: "\E4BB";
}

.game-icon-spyglass:before {
  content: "\E4BC";
}

.game-icon-treasure-map:before {
  content: "\E4BD";
}

.game-icon-wooden-sign:before {
  content: "\E4BE";
}

.game-icon-world:before {
  content: "\E4BF";
}

.game-icon-digital-trace:before {
  content: "\E4C0";
}

.game-icon-orbit:before {
  content: "\E4C1";
}

.game-icon-battleship:before {
  content: "\E4C2";
}

.game-icon-great-war-tank:before {
  content: "\E4C3";
}

.game-icon-occupy:before {
  content: "\E4C4";
}

.game-icon-barracks-tent:before {
  content: "\E4C5";
}

.game-icon-barracks:before {
  content: "\E4C6";
}

.game-icon-caltrops:before {
  content: "\E4C7";
}

.game-icon-hammer-sickle:before {
  content: "\E4C8";
}

.game-icon-military-fort:before {
  content: "\E4C9";
}

.game-icon-union-jack:before {
  content: "\E4CA";
}

.game-icon-usa-flag:before {
  content: "\E4CB";
}

.game-icon-battle-tank:before {
  content: "\E4CC";
}

.game-icon-bombing-run:before {
  content: "\E4CD";
}

.game-icon-gas-mask:before {
  content: "\E4CE";
}

.game-icon-mushroom-cloud:before {
  content: "\E4CF";
}

.game-icon-sunrise:before {
  content: "\E4D0";
}

.game-icon-tank:before {
  content: "\E4D1";
}

.game-icon-artillery-shell:before {
  content: "\E4D2";
}

.game-icon-biplane:before {
  content: "\E4D3";
}

.game-icon-bunker:before {
  content: "\E4D4";
}

.game-icon-damaged-house:before {
  content: "\E4D5";
}

.game-icon-lost-limb:before {
  content: "\E4D6";
}

.game-icon-military-ambulance:before {
  content: "\E4D7";
}

.game-icon-c96:before {
  content: "\E4D8";
}

.game-icon-carpet-bombing:before {
  content: "\E4D9";
}

.game-icon-flatbed-covered:before {
  content: "\E4DA";
}

.game-icon-flatbed:before {
  content: "\E4DB";
}

.game-icon-mp-40:before {
  content: "\E4DC";
}

.game-icon-tank-tread:before {
  content: "\E4DD";
}

.game-icon-thompson-m1:before {
  content: "\E4DE";
}

.game-icon-trench-body-armor:before {
  content: "\E4DF";
}

.game-icon-trench-knife:before {
  content: "\E4E0";
}

.game-icon-trench-spade:before {
  content: "\E4E1";
}

.game-icon-bad-gnome:before {
  content: "\E4E2";
}

.game-icon-boar-ensign:before {
  content: "\E4E3";
}

.game-icon-carnyx:before {
  content: "\E4E4";
}

.game-icon-fomorian:before {
  content: "\E4E5";
}

.game-icon-holy-oak:before {
  content: "\E4E6";
}

.game-icon-oppidum:before {
  content: "\E4E7";
}

.game-icon-torc:before {
  content: "\E4E8";
}

.game-icon-tree-face:before {
  content: "\E4E9";
}

.game-icon-bagpipes:before {
  content: "\E4EA";
}

.game-icon-harp:before {
  content: "\E4EB";
}

.game-icon-magic-potion:before {
  content: "\E4EC";
}

.game-icon-sickle:before {
  content: "\E4ED";
}

.game-icon-heavy-thorny-triskelion:before {
  content: "\E4EE";
}

.game-icon-robe:before {
  content: "\E4EF";
}

.game-icon-medusa-head:before {
  content: "\E4F0";
}

.game-icon-transparent-slime:before {
  content: "\E4F1";
}

.game-icon-dragon-head-2:before {
  content: "\E4F2";
}

.game-icon-alien-bug:before {
  content: "\E4F3";
}

.game-icon-alien-egg:before {
  content: "\E4F4";
}

.game-icon-anubis:before {
  content: "\E4F5";
}

.game-icon-bottled-shadow:before {
  content: "\E4F6";
}

.game-icon-brain-tentacle:before {
  content: "\E4F7";
}

.game-icon-brute:before {
  content: "\E4F8";
}

.game-icon-bullet-bill:before {
  content: "\E4F9";
}

.game-icon-bully-minion:before {
  content: "\E4FA";
}

.game-icon-carnivorous-plant:before {
  content: "\E4FB";
}

.game-icon-ceiling-barnacle:before {
  content: "\E4FC";
}

.game-icon-centaur:before {
  content: "\E4FD";
}

.game-icon-daemon-pull:before {
  content: "\E4FE";
}

.game-icon-devil-mask:before {
  content: "\E4FF";
}

.game-icon-djinn:before {
  content: "\E500";
}

.game-icon-drakkar-dragon:before {
  content: "\E501";
}

.game-icon-egyptian-sphinx:before {
  content: "\E502";
}

.game-icon-fairy:before {
  content: "\E503";
}

.game-icon-floating-ghost:before {
  content: "\E504";
}

.game-icon-floating-tentacles:before {
  content: "\E505";
}

.game-icon-gargoyle:before {
  content: "\E506";
}

.game-icon-giant:before {
  content: "\E507";
}

.game-icon-grasping-slug:before {
  content: "\E508";
}

.game-icon-greek-sphinx:before {
  content: "\E509";
}

.game-icon-half-body-crawling:before {
  content: "\E50A";
}

.game-icon-horned-reptile:before {
  content: "\E50B";
}

.game-icon-horus:before {
  content: "\E50C";
}

.game-icon-ice-golem:before {
  content: "\E50D";
}

.game-icon-jawless-cyclop:before {
  content: "\E50E";
}

.game-icon-kraken-tentacle:before {
  content: "\E50F";
}

.game-icon-mermaid:before {
  content: "\E510";
}

.game-icon-metroid:before {
  content: "\E511";
}

.game-icon-mimic-chest:before {
  content: "\E512";
}

.game-icon-mummy-head:before {
  content: "\E513";
}

.game-icon-oni:before {
  content: "\E514";
}

.game-icon-pick-of-destiny:before {
  content: "\E515";
}

.game-icon-purple-tentacle:before {
  content: "\E516";
}

.game-icon-resting-vampire:before {
  content: "\E517";
}

.game-icon-rock-golem:before {
  content: "\E518";
}

.game-icon-sasquatch:before {
  content: "\E519";
}

.game-icon-shambling-mound:before {
  content: "\E51A";
}

.game-icon-shambling-zombie:before {
  content: "\E51B";
}

.game-icon-slime:before {
  content: "\E51C";
}

.game-icon-spiked-dragon-head:before {
  content: "\E51D";
}

.game-icon-swallower:before {
  content: "\E51E";
}

.game-icon-swamp-bat:before {
  content: "\E51F";
}

.game-icon-thwomp:before {
  content: "\E520";
}

.game-icon-troglodyte:before {
  content: "\E521";
}

.game-icon-unicorn:before {
  content: "\E522";
}

.game-icon-beast-eye:before {
  content: "\E523";
}

.game-icon-behold:before {
  content: "\E524";
}

.game-icon-bestial-fangs:before {
  content: "\E525";
}

.game-icon-cracked-alien-skull:before {
  content: "\E526";
}

.game-icon-cyclops:before {
  content: "\E527";
}

.game-icon-daemon-skull:before {
  content: "\E528";
}

.game-icon-dinosaur-bones:before {
  content: "\E529";
}

.game-icon-dinosaur-egg:before {
  content: "\E52A";
}

.game-icon-double-dragon:before {
  content: "\E52B";
}

.game-icon-dragon-spiral:before {
  content: "\E52C";
}

.game-icon-ent-mouth:before {
  content: "\E52D";
}

.game-icon-evil-book:before {
  content: "\E52E";
}

.game-icon-evil-comet:before {
  content: "\E52F";
}

.game-icon-evil-fork:before {
  content: "\E530";
}

.game-icon-evil-minion:before {
  content: "\E531";
}

.game-icon-evil-moon:before {
  content: "\E532";
}

.game-icon-evil-tree:before {
  content: "\E533";
}

.game-icon-eyestalk:before {
  content: "\E534";
}

.game-icon-fairy-2:before {
  content: "\E535";
}

.game-icon-fleshy-mass:before {
  content: "\E536";
}

.game-icon-frankenstein-creature:before {
  content: "\E537";
}

.game-icon-ghost:before {
  content: "\E538";
}

.game-icon-gluttonous-smile:before {
  content: "\E539";
}

.game-icon-gooey-daemon:before {
  content: "\E53A";
}

.game-icon-grim-reaper:before {
  content: "\E53B";
}

.game-icon-harpy:before {
  content: "\E53C";
}

.game-icon-haunting:before {
  content: "\E53D";
}

.game-icon-horned-skull:before {
  content: "\E53E";
}

.game-icon-hydra-shot:before {
  content: "\E53F";
}

.game-icon-hydra:before {
  content: "\E540";
}

.game-icon-imp-laugh:before {
  content: "\E541";
}

.game-icon-imp:before {
  content: "\E542";
}

.game-icon-infested-mass:before {
  content: "\E543";
}

.game-icon-lizardman:before {
  content: "\E544";
}

.game-icon-pretty-fangs:before {
  content: "\E545";
}

.game-icon-sea-dragon:before {
  content: "\E546";
}

.game-icon-sharp-smile:before {
  content: "\E547";
}

.game-icon-spectre:before {
  content: "\E548";
}

.game-icon-toad-teeth:before {
  content: "\E549";
}

.game-icon-triton-head:before {
  content: "\E54A";
}

.game-icon-vile-fluid:before {
  content: "\E54B";
}

.game-icon-witch-flight:before {
  content: "\E54C";
}

.game-icon-wyvern:before {
  content: "\E54D";
}

.game-icon-sea-creature:before {
  content: "\E54E";
}

.game-icon-carrier:before {
  content: "\E54F";
}

.game-icon-cruiser:before {
  content: "\E550";
}

.game-icon-dreadnought:before {
  content: "\E551";
}

.game-icon-iron-hulled-warship:before {
  content: "\E552";
}

.game-icon-boat-engine:before {
  content: "\E553";
}

.game-icon-boat-fishing:before {
  content: "\E554";
}

.game-icon-boat-horizon:before {
  content: "\E555";
}

.game-icon-boat-propeller:before {
  content: "\E556";
}

.game-icon-canoe:before {
  content: "\E557";
}

.game-icon-caravel:before {
  content: "\E558";
}

.game-icon-cargo-ship:before {
  content: "\E559";
}

.game-icon-crow-nest:before {
  content: "\E55A";
}

.game-icon-drakkar:before {
  content: "\E55B";
}

.game-icon-figurehead:before {
  content: "\E55C";
}

.game-icon-fishing-boat:before {
  content: "\E55D";
}

.game-icon-galley:before {
  content: "\E55E";
}

.game-icon-harbor-dock:before {
  content: "\E55F";
}

.game-icon-iceberg:before {
  content: "\E560";
}

.game-icon-life-buoy:before {
  content: "\E561";
}

.game-icon-mini-submarine:before {
  content: "\E562";
}

.game-icon-mooring-bollard:before {
  content: "\E563";
}

.game-icon-paddle-steamer:before {
  content: "\E564";
}

.game-icon-paddles:before {
  content: "\E565";
}

.game-icon-paper-boat:before {
  content: "\E566";
}

.game-icon-periscope:before {
  content: "\E567";
}

.game-icon-raft:before {
  content: "\E568";
}

.game-icon-sail:before {
  content: "\E569";
}

.game-icon-sailboat:before {
  content: "\E56A";
}

.game-icon-ship-bow:before {
  content: "\E56B";
}

.game-icon-ship-wheel:before {
  content: "\E56C";
}

.game-icon-ship-wreck:before {
  content: "\E56D";
}

.game-icon-sinagot:before {
  content: "\E56E";
}

.game-icon-sinking-ship:before {
  content: "\E56F";
}

.game-icon-speed-boat:before {
  content: "\E570";
}

.game-icon-submarine:before {
  content: "\E571";
}

.game-icon-trireme:before {
  content: "\E572";
}

.game-icon-wood-canoe:before {
  content: "\E573";
}

.game-icon-anchor:before {
  content: "\E574";
}

.game-icon-galleon:before {
  content: "\E575";
}

.game-icon-shooner-sailboat:before {
  content: "\E576";
}

.game-icon-small-fishing-sailboat:before {
  content: "\E577";
}

.game-icon-nautilus-shell:before {
  content: "\E578";
}

.game-icon-algae:before {
  content: "\E579";
}

.game-icon-buoy:before {
  content: "\E57A";
}

.game-icon-coral:before {
  content: "\E57B";
}

.game-icon-fishing-pole:before {
  content: "\E57C";
}

.game-icon-fishing-spoon:before {
  content: "\E57D";
}

.game-icon-fishing:before {
  content: "\E57E";
}

.game-icon-flood:before {
  content: "\E57F";
}

.game-icon-helicoprion:before {
  content: "\E580";
}

.game-icon-high-tide:before {
  content: "\E581";
}

.game-icon-island:before {
  content: "\E582";
}

.game-icon-knot:before {
  content: "\E583";
}

.game-icon-life-jacket:before {
  content: "\E584";
}

.game-icon-lighthouse:before {
  content: "\E585";
}

.game-icon-low-tide:before {
  content: "\E586";
}

.game-icon-mussel:before {
  content: "\E587";
}

.game-icon-offshore-platform:before {
  content: "\E588";
}

.game-icon-oyster:before {
  content: "\E589";
}

.game-icon-plesiosaurus:before {
  content: "\E58A";
}

.game-icon-pulley-hook:before {
  content: "\E58B";
}

.game-icon-rope-coil:before {
  content: "\E58C";
}

.game-icon-sand-castle:before {
  content: "\E58D";
}

.game-icon-scuba-mask:before {
  content: "\E58E";
}

.game-icon-scuba-tanks:before {
  content: "\E58F";
}

.game-icon-sea-cliff:before {
  content: "\E590";
}

.game-icon-sea-turtle:before {
  content: "\E591";
}

.game-icon-seagull:before {
  content: "\E592";
}

.game-icon-surf-board:before {
  content: "\E593";
}

.game-icon-surfer-van:before {
  content: "\E594";
}

.game-icon-swimfins:before {
  content: "\E595";
}

.game-icon-tall-bridge:before {
  content: "\E596";
}

.game-icon-wave-surfer:before {
  content: "\E597";
}

.game-icon-wooden-pier:before {
  content: "\E598";
}

.game-icon-at-sea:before {
  content: "\E599";
}

.game-icon-big-wave:before {
  content: "\E59A";
}

.game-icon-cargo-crane:before {
  content: "\E59B";
}

.game-icon-fishing-hook:before {
  content: "\E59C";
}

.game-icon-fishing-net:before {
  content: "\E59D";
}

.game-icon-water-splash:before {
  content: "\E59E";
}

.game-icon-wave-crest:before {
  content: "\E59F";
}

.game-icon-waves:before {
  content: "\E5A0";
}

.game-icon-snorkel:before {
  content: "\E5A1";
}

.game-icon-ram:before {
  content: "\E5A2";
}

.game-icon-armadillo:before {
  content: "\E5A3";
}

.game-icon-basset-hound-head:before {
  content: "\E5A4";
}

.game-icon-bastet:before {
  content: "\E5A5";
}

.game-icon-beaver:before {
  content: "\E5A6";
}

.game-icon-bison:before {
  content: "\E5A7";
}

.game-icon-buffalo-head:before {
  content: "\E5A8";
}

.game-icon-camel:before {
  content: "\E5A9";
}

.game-icon-cavalry:before {
  content: "\E5AA";
}

.game-icon-juggling-seal:before {
  content: "\E5AB";
}

.game-icon-jumping-dog:before {
  content: "\E5AC";
}

.game-icon-mammoth:before {
  content: "\E5AD";
}

.game-icon-mandrill-head:before {
  content: "\E5AE";
}

.game-icon-rabbit-head:before {
  content: "\E5AF";
}

.game-icon-raccoon-head:before {
  content: "\E5B0";
}

.game-icon-ram-profile:before {
  content: "\E5B1";
}

.game-icon-saber-toothed-cat-head:before {
  content: "\E5B2";
}

.game-icon-sitting-dog:before {
  content: "\E5B3";
}

.game-icon-squirrel:before {
  content: "\E5B4";
}

.game-icon-tapir:before {
  content: "\E5B5";
}

.game-icon-walrus-head:before {
  content: "\E5B6";
}

.game-icon-direwolf:before {
  content: "\E5B7";
}

.game-icon-snout:before {
  content: "\E5B8";
}

.game-icon-acoustic-megaphone:before {
  content: "\E5B9";
}

.game-icon-alarm-clock:before {
  content: "\E5BA";
}

.game-icon-audio-cassette:before {
  content: "\E5BB";
}

.game-icon-banjo:before {
  content: "\E5BC";
}

.game-icon-boombox:before {
  content: "\E5BD";
}

.game-icon-compact-disc:before {
  content: "\E5BE";
}

.game-icon-djembe:before {
  content: "\E5BF";
}

.game-icon-drum:before {
  content: "\E5C0";
}

.game-icon-elf-ear:before {
  content: "\E5C1";
}

.game-icon-film-spool:before {
  content: "\E5C2";
}

.game-icon-flute:before {
  content: "\E5C3";
}

.game-icon-gong:before {
  content: "\E5C4";
}

.game-icon-guitar-bass-head:before {
  content: "\E5C5";
}

.game-icon-guitar-head:before {
  content: "\E5C6";
}

.game-icon-headphones:before {
  content: "\E5C7";
}

.game-icon-heart-beats:before {
  content: "\E5C8";
}

.game-icon-human-ear:before {
  content: "\E5C9";
}

.game-icon-jack-plug:before {
  content: "\E5CA";
}

.game-icon-maracas:before {
  content: "\E5CB";
}

.game-icon-metronome:before {
  content: "\E5CC";
}

.game-icon-microphone:before {
  content: "\E5CD";
}

.game-icon-musical-keyboard:before {
  content: "\E5CE";
}

.game-icon-musical-notes:before {
  content: "\E5CF";
}

.game-icon-musical-score:before {
  content: "\E5D0";
}

.game-icon-mute:before {
  content: "\E5D1";
}

.game-icon-ocarina:before {
  content: "\E5D2";
}

.game-icon-old-microphone:before {
  content: "\E5D3";
}

.game-icon-pan-flute:before {
  content: "\E5D4";
}

.game-icon-phone:before {
  content: "\E5D5";
}

.game-icon-piano-keys:before {
  content: "\E5D6";
}

.game-icon-pocket-radio:before {
  content: "\E5D7";
}

.game-icon-radio-tower:before {
  content: "\E5D8";
}

.game-icon-ringing-alarm:before {
  content: "\E5D9";
}

.game-icon-saxophone:before {
  content: "\E5DA";
}

.game-icon-silenced:before {
  content: "\E5DB";
}

.game-icon-sound-off:before {
  content: "\E5DC";
}

.game-icon-sound-on:before {
  content: "\E5DD";
}

.game-icon-speaker-off:before {
  content: "\E5DE";
}

.game-icon-speaker:before {
  content: "\E5DF";
}

.game-icon-tambourine:before {
  content: "\E5E0";
}

.game-icon-trumpet-flag:before {
  content: "\E5E1";
}

.game-icon-trumpet:before {
  content: "\E5E2";
}

.game-icon-vuvuzelas:before {
  content: "\E5E3";
}

.game-icon-xylophone:before {
  content: "\E5E4";
}

.game-icon-anthem:before {
  content: "\E5E5";
}

.game-icon-bugle-call:before {
  content: "\E5E6";
}

.game-icon-carillon:before {
  content: "\E5E7";
}

.game-icon-double-quaver:before {
  content: "\E5E8";
}

.game-icon-echo-ripples:before {
  content: "\E5E9";
}

.game-icon-guitar:before {
  content: "\E5EA";
}

.game-icon-hunting-horn:before {
  content: "\E5EB";
}

.game-icon-love-howl:before {
  content: "\E5EC";
}

.game-icon-love-song:before {
  content: "\E5ED";
}

.game-icon-lyre:before {
  content: "\E5EE";
}

.game-icon-movement-sensor:before {
  content: "\E5EF";
}

.game-icon-music-spell:before {
  content: "\E5F0";
}

.game-icon-resonance:before {
  content: "\E5F1";
}

.game-icon-ringing-bell:before {
  content: "\E5F2";
}

.game-icon-silence:before {
  content: "\E5F3";
}

.game-icon-sonic-boom:before {
  content: "\E5F4";
}

.game-icon-sonic-shout:before {
  content: "\E5F5";
}

.game-icon-tune-pitch:before {
  content: "\E5F6";
}

.game-icon-ultrasound:before {
  content: "\E5F7";
}

.game-icon-vibrating-ball:before {
  content: "\E5F8";
}

.game-icon-zigzag-tune:before {
  content: "\E5F9";
}

.game-icon-amplitude:before {
  content: "\E5FA";
}

.game-icon-hearing-disabled:before {
  content: "\E5FB";
}

.game-icon-sound-waves:before {
  content: "\E5FC";
}

.game-icon-violin:before {
  content: "\E5FD";
}

.game-icon-chariot:before {
  content: "\E5FE";
}

.game-icon-catapult:before {
  content: "\E5FF";
}

.game-icon-amphora:before {
  content: "\E600";
}

.game-icon-ancient-columns:before {
  content: "\E601";
}

.game-icon-ancient-ruins:before {
  content: "\E602";
}

.game-icon-aqueduct:before {
  content: "\E603";
}

.game-icon-broken-pottery:before {
  content: "\E604";
}

.game-icon-caesar:before {
  content: "\E605";
}

.game-icon-classical-knowledge:before {
  content: "\E606";
}

.game-icon-coliseum:before {
  content: "\E607";
}

.game-icon-column-vase:before {
  content: "\E608";
}

.game-icon-discobolus:before {
  content: "\E609";
}

.game-icon-greaves:before {
  content: "\E60A";
}

.game-icon-greek-temple:before {
  content: "\E60B";
}

.game-icon-icarus:before {
  content: "\E60C";
}

.game-icon-ionic-column:before {
  content: "\E60D";
}

.game-icon-jug:before {
  content: "\E60E";
}

.game-icon-medieval-pavilion:before {
  content: "\E60F";
}

.game-icon-menhir:before {
  content: "\E610";
}

.game-icon-olive:before {
  content: "\E611";
}

.game-icon-palisade:before {
  content: "\E612";
}

.game-icon-roman-toga:before {
  content: "\E613";
}

.game-icon-sandal:before {
  content: "\E614";
}

.game-icon-stone-bust:before {
  content: "\E615";
}

.game-icon-stone-path:before {
  content: "\E616";
}

.game-icon-water-fountain:before {
  content: "\E617";
}

.game-icon-wax-tablet:before {
  content: "\E618";
}

.game-icon-capitol:before {
  content: "\E619";
}

.game-icon-grapes:before {
  content: "\E61A";
}

.game-icon-maze:before {
  content: "\E61B";
}

.game-icon-wingfoot:before {
  content: "\E61C";
}

.game-icon-ballista:before {
  content: "\E61D";
}

.game-icon-pauldrons:before {
  content: "\E61E";
}

.game-icon-pteruges:before {
  content: "\E61F";
}

.game-icon-siege-ram:before {
  content: "\E620";
}

.game-icon-air-balloon:before {
  content: "\E621";
}

.game-icon-airplane-arrival:before {
  content: "\E622";
}

.game-icon-airplane-departure:before {
  content: "\E623";
}

.game-icon-ambulance:before {
  content: "\E624";
}

.game-icon-apollo-capsule:before {
  content: "\E625";
}

.game-icon-bulldozer:before {
  content: "\E626";
}

.game-icon-bus:before {
  content: "\E627";
}

.game-icon-car-door:before {
  content: "\E628";
}

.game-icon-car-key:before {
  content: "\E629";
}

.game-icon-car-seat:before {
  content: "\E62A";
}

.game-icon-car-wheel:before {
  content: "\E62B";
}

.game-icon-caravan:before {
  content: "\E62C";
}

.game-icon-city-car:before {
  content: "\E62D";
}

.game-icon-coal-wagon:before {
  content: "\E62E";
}

.game-icon-commercial-airplane:before {
  content: "\E62F";
}

.game-icon-cycling:before {
  content: "\E630";
}

.game-icon-delivery-drone:before {
  content: "\E631";
}

.game-icon-dutch-bike:before {
  content: "\E632";
}

.game-icon-farm-tractor:before {
  content: "\E633";
}

.game-icon-flat-tire:before {
  content: "\E634";
}

.game-icon-food-truck:before {
  content: "\E635";
}

.game-icon-forklift:before {
  content: "\E636";
}

.game-icon-gas-pump:before {
  content: "\E637";
}

.game-icon-gear-stick:before {
  content: "\E638";
}

.game-icon-hang-glider:before {
  content: "\E639";
}

.game-icon-helicopter-tail:before {
  content: "\E63A";
}

.game-icon-helicopter:before {
  content: "\E63B";
}

.game-icon-home-garage:before {
  content: "\E63C";
}

.game-icon-interceptor-ship:before {
  content: "\E63D";
}

.game-icon-jeep:before {
  content: "\E63E";
}

.game-icon-jet-fighter:before {
  content: "\E63F";
}

.game-icon-kick-scooter:before {
  content: "\E640";
}

.game-icon-level-crossing:before {
  content: "\E641";
}

.game-icon-lunar-module:before {
  content: "\E642";
}

.game-icon-mars-curiosity:before {
  content: "\E643";
}

.game-icon-mars-pathfinder:before {
  content: "\E644";
}

.game-icon-mechanic-garage:before {
  content: "\E645";
}

.game-icon-mine-truck:before {
  content: "\E646";
}

.game-icon-mine-wagon:before {
  content: "\E647";
}

.game-icon-old-wagon:before {
  content: "\E648";
}

.game-icon-plane-wing:before {
  content: "\E649";
}

.game-icon-rail-road:before {
  content: "\E64A";
}

.game-icon-railway:before {
  content: "\E64B";
}

.game-icon-rocket-thruster:before {
  content: "\E64C";
}

.game-icon-ropeway:before {
  content: "\E64D";
}

.game-icon-scooter:before {
  content: "\E64E";
}

.game-icon-scout-ship:before {
  content: "\E64F";
}

.game-icon-siege-tower:before {
  content: "\E650";
}

.game-icon-skateboard:before {
  content: "\E651";
}

.game-icon-space-shuttle:before {
  content: "\E652";
}

.game-icon-spaceship:before {
  content: "\E653";
}

.game-icon-speedometer:before {
  content: "\E654";
}

.game-icon-starfighter:before {
  content: "\E655";
}

.game-icon-stealth-bomber:before {
  content: "\E656";
}

.game-icon-steam-locomotive:before {
  content: "\E657";
}

.game-icon-steering-wheel:before {
  content: "\E658";
}

.game-icon-subway:before {
  content: "\E659";
}

.game-icon-tire-iron-cross:before {
  content: "\E65A";
}

.game-icon-tire-iron:before {
  content: "\E65B";
}

.game-icon-tow-truck:before {
  content: "\E65C";
}

.game-icon-tracked-robot:before {
  content: "\E65D";
}

.game-icon-traffic-lights-green:before {
  content: "\E65E";
}

.game-icon-traffic-lights-orange:before {
  content: "\E65F";
}

.game-icon-traffic-lights-ready-to-go:before {
  content: "\E660";
}

.game-icon-trebuchet:before {
  content: "\E661";
}

.game-icon-truck:before {
  content: "\E662";
}

.game-icon-turbine:before {
  content: "\E663";
}

.game-icon-ufo:before {
  content: "\E664";
}

.game-icon-unicycle:before {
  content: "\E665";
}

.game-icon-velocipede:before {
  content: "\E666";
}

.game-icon-walking-scout:before {
  content: "\E667";
}

.game-icon-afterburn:before {
  content: "\E668";
}

.game-icon-cartwheel:before {
  content: "\E669";
}

.game-icon-forward-field:before {
  content: "\E66A";
}

.game-icon-jetpack:before {
  content: "\E66B";
}

.game-icon-missile-mech:before {
  content: "\E66C";
}

.game-icon-rocket-flight:before {
  content: "\E66D";
}

.game-icon-rocket:before {
  content: "\E66E";
}

.game-icon-strafe:before {
  content: "\E66F";
}

.game-icon-bomber:before {
  content: "\E670";
}

.game-icon-heavy-fighter:before {
  content: "\E671";
}

.game-icon-light-fighter:before {
  content: "\E672";
}

.game-icon-jet-pack:before {
  content: "\E673";
}

.game-icon-airplane:before {
  content: "\E674";
}

.game-icon-apc:before {
  content: "\E675";
}

.game-icon-f1-car:before {
  content: "\E676";
}

.game-icon-glider:before {
  content: "\E677";
}

.game-icon-hang-glider-2:before {
  content: "\E678";
}

.game-icon-jeep-2:before {
  content: "\E679";
}

.game-icon-race-car:before {
  content: "\E67A";
}

.game-icon-zeppelin:before {
  content: "\E67B";
}

.game-icon-dread:before {
  content: "\E67C";
}

.game-icon-distraction:before {
  content: "\E67D";
}

.game-icon-angry-eyes:before {
  content: "\E67E";
}

.game-icon-confrontation:before {
  content: "\E67F";
}

.game-icon-dead-head:before {
  content: "\E680";
}

.game-icon-enrage:before {
  content: "\E681";
}

.game-icon-idea:before {
  content: "\E682";
}

.game-icon-knocked-out-stars:before {
  content: "\E683";
}

.game-icon-liar:before {
  content: "\E684";
}

.game-icon-wisdom:before {
  content: "\E685";
}

.game-icon-awareness:before {
  content: "\E686";
}

.game-icon-back-pain:before {
  content: "\E687";
}

.game-icon-brain-freeze:before {
  content: "\E688";
}

.game-icon-chained-heart:before {
  content: "\E689";
}

.game-icon-coma:before {
  content: "\E68A";
}

.game-icon-delighted:before {
  content: "\E68B";
}

.game-icon-despair:before {
  content: "\E68C";
}

.game-icon-distraction-2:before {
  content: "\E68D";
}

.game-icon-drama-masks:before {
  content: "\E68E";
}

.game-icon-extra-lucid:before {
  content: "\E68F";
}

.game-icon-gluttony:before {
  content: "\E690";
}

.game-icon-hidden:before {
  content: "\E691";
}

.game-icon-imprisoned:before {
  content: "\E692";
}

.game-icon-mirror-mirror:before {
  content: "\E693";
}

.game-icon-mouth-watering:before {
  content: "\E694";
}

.game-icon-nothing-to-say:before {
  content: "\E695";
}

.game-icon-oppression:before {
  content: "\E696";
}

.game-icon-paranoia:before {
  content: "\E697";
}

.game-icon-sensuousness:before {
  content: "\E698";
}

.game-icon-sleepy:before {
  content: "\E699";
}

.game-icon-sly:before {
  content: "\E69A";
}

.game-icon-smitten:before {
  content: "\E69B";
}

.game-icon-stoned-skull:before {
  content: "\E69C";
}

.game-icon-strong:before {
  content: "\E69D";
}

.game-icon-surprised-skull:before {
  content: "\E69E";
}

.game-icon-surprised:before {
  content: "\E69F";
}

.game-icon-suspicious:before {
  content: "\E6A0";
}

.game-icon-temptation:before {
  content: "\E6A1";
}

.game-icon-terror:before {
  content: "\E6A2";
}

.game-icon-worried-eyes:before {
  content: "\E6A3";
}

.game-icon-artificial-intelligence:before {
  content: "\E6A4";
}

.game-icon-cement-shoes:before {
  content: "\E6A5";
}

.game-icon-knockout:before {
  content: "\E6A6";
}

.game-icon-smoke-bomb:before {
  content: "\E6A7";
}

.game-icon-barbecue:before {
  content: "\E6A8";
}

.game-icon-chimney:before {
  content: "\E6A9";
}

.game-icon-cigar:before {
  content: "\E6AA";
}

.game-icon-cigarette:before {
  content: "\E6AB";
}

.game-icon-nuclear-plant:before {
  content: "\E6AC";
}

.game-icon-smoking-pipe:before {
  content: "\E6AD";
}

.game-icon-smoking-volcano:before {
  content: "\E6AE";
}

.game-icon-steam-blast:before {
  content: "\E6AF";
}

.game-icon-steam:before {
  content: "\E6B0";
}

.game-icon-aerosol:before {
  content: "\E6B1";
}

.game-icon-bottle-vapors:before {
  content: "\E6B2";
}

.game-icon-bubbling-bowl:before {
  content: "\E6B3";
}

.game-icon-cloudy-fork:before {
  content: "\E6B4";
}

.game-icon-fizzing-flask:before {
  content: "\E6B5";
}

.game-icon-fluffy-swirl:before {
  content: "\E6B6";
}

.game-icon-fragrance:before {
  content: "\E6B7";
}

.game-icon-incense:before {
  content: "\E6B8";
}

.game-icon-poison-gas:before {
  content: "\E6B9";
}

.game-icon-smoking-finger:before {
  content: "\E6BA";
}

.game-icon-smoking-orb:before {
  content: "\E6BB";
}

.game-icon-poison-cloud:before {
  content: "\E6BC";
}

.game-icon-bird-cage:before {
  content: "\E6BD";
}

.game-icon-bird-house:before {
  content: "\E6BE";
}

.game-icon-chicken-oven:before {
  content: "\E6BF";
}

.game-icon-cuauhtli:before {
  content: "\E6C0";
}

.game-icon-duck-palm:before {
  content: "\E6C1";
}

.game-icon-eating-pelican:before {
  content: "\E6C2";
}

.game-icon-egyptian-bird:before {
  content: "\E6C3";
}

.game-icon-fried-eggs:before {
  content: "\E6C4";
}

.game-icon-goose:before {
  content: "\E6C5";
}

.game-icon-hummingbird:before {
  content: "\E6C6";
}

.game-icon-ibis:before {
  content: "\E6C7";
}

.game-icon-incubator:before {
  content: "\E6C8";
}

.game-icon-kiwi-bird:before {
  content: "\E6C9";
}

.game-icon-nest-birds:before {
  content: "\E6CA";
}

.game-icon-nest-eggs:before {
  content: "\E6CB";
}

.game-icon-new-born:before {
  content: "\E6CC";
}

.game-icon-ostrich:before {
  content: "\E6CD";
}

.game-icon-plastic-duck:before {
  content: "\E6CE";
}

.game-icon-shoebill-stork:before {
  content: "\E6CF";
}

.game-icon-stork-delivery:before {
  content: "\E6D0";
}

.game-icon-swallow-2:before {
  content: "\E6D1";
}

.game-icon-bird-claw:before {
  content: "\E6D2";
}

.game-icon-bird-limb:before {
  content: "\E6D3";
}

.game-icon-bird-mask:before {
  content: "\E6D4";
}

.game-icon-dove:before {
  content: "\E6D5";
}

.game-icon-egg-clutch:before {
  content: "\E6D6";
}

.game-icon-falling-ovoid:before {
  content: "\E6D7";
}

.game-icon-feather:before {
  content: "\E6D8";
}

.game-icon-feathered-wing:before {
  content: "\E6D9";
}

.game-icon-scarecrow:before {
  content: "\E6DA";
}

.game-icon-sparrow:before {
  content: "\E6DB";
}

.game-icon-two-feathers:before {
  content: "\E6DC";
}

.game-icon-winged-leg:before {
  content: "\E6DD";
}

.game-icon-fish-cooked:before {
  content: "\E6DE";
}

.game-icon-fried-fish:before {
  content: "\E6DF";
}

.game-icon-aquarium:before {
  content: "\E6E0";
}

.game-icon-canned-fish:before {
  content: "\E6E1";
}

.game-icon-circling-fish:before {
  content: "\E6E2";
}

.game-icon-double-fish:before {
  content: "\E6E3";
}

.game-icon-eel:before {
  content: "\E6E4";
}

.game-icon-fish-bucket:before {
  content: "\E6E5";
}

.game-icon-fish-eggs:before {
  content: "\E6E6";
}

.game-icon-fish-escape:before {
  content: "\E6E7";
}

.game-icon-fish-scales:before {
  content: "\E6E8";
}

.game-icon-fishing-jig:before {
  content: "\E6E9";
}

.game-icon-fishing-lure:before {
  content: "\E6EA";
}

.game-icon-flatfish:before {
  content: "\E6EB";
}

.game-icon-lucky-fisherman:before {
  content: "\E6EC";
}

.game-icon-piranha:before {
  content: "\E6ED";
}

.game-icon-porcupinefish:before {
  content: "\E6EE";
}

.game-icon-school-of-fish:before {
  content: "\E6EF";
}

.game-icon-sushis:before {
  content: "\E6F0";
}

.game-icon-tadpole:before {
  content: "\E6F1";
}

.game-icon-tropical-fish:before {
  content: "\E6F2";
}

.game-icon-salmon:before {
  content: "\E6F3";
}

.game-icon-fairy-wings:before {
  content: "\E6F4";
}

.game-icon-flying-target:before {
  content: "\E6F5";
}

.game-icon-hand-wing:before {
  content: "\E6F6";
}

.game-icon-heart-wings:before {
  content: "\E6F7";
}

.game-icon-pterodactylus:before {
  content: "\E6F8";
}

.game-icon-spiky-wing:before {
  content: "\E6F9";
}

.game-icon-angel-outfit:before {
  content: "\E6FA";
}

.game-icon-angel-wings:before {
  content: "\E6FB";
}

.game-icon-bat-blade:before {
  content: "\E6FC";
}

.game-icon-curly-wing:before {
  content: "\E6FD";
}

.game-icon-fluffy-wing:before {
  content: "\E6FE";
}

.game-icon-holy-grail:before {
  content: "\E6FF";
}

.game-icon-quill:before {
  content: "\E700";
}

.game-icon-wing-cloak:before {
  content: "\E701";
}

.game-icon-winged-emblem:before {
  content: "\E702";
}

.game-icon-evil-wings:before {
  content: "\E703";
}

.game-icon-tumulus:before {
  content: "\E704";
}

.game-icon-death-juice:before {
  content: "\E705";
}

.game-icon-egyptian-urns:before {
  content: "\E706";
}

.game-icon-grave-flowers:before {
  content: "\E707";
}

.game-icon-graveyard:before {
  content: "\E708";
}

.game-icon-half-dead:before {
  content: "\E709";
}

.game-icon-morgue-feet:before {
  content: "\E70A";
}

.game-icon-sarcophagus:before {
  content: "\E70B";
}

.game-icon-skeletal-hand:before {
  content: "\E70C";
}

.game-icon-slipknot:before {
  content: "\E70D";
}

.game-icon-carrion:before {
  content: "\E70E";
}

.game-icon-coffin:before {
  content: "\E70F";
}

.game-icon-dead-eye:before {
  content: "\E710";
}

.game-icon-dead-wood:before {
  content: "\E711";
}

.game-icon-death-zone:before {
  content: "\E712";
}

.game-icon-decapitation:before {
  content: "\E713";
}

.game-icon-gibbet:before {
  content: "\E714";
}

.game-icon-guillotine:before {
  content: "\E715";
}

.game-icon-internal-injury:before {
  content: "\E716";
}

.game-icon-life-in-the-balance:before {
  content: "\E717";
}

.game-icon-pirate-grave:before {
  content: "\E718";
}

.game-icon-reaper-scythe:before {
  content: "\E719";
}

.game-icon-tombstone:before {
  content: "\E71A";
}

.game-icon-suicide:before {
  content: "\E71B";
}

.game-icon-telefrag:before {
  content: "\E71C";
}

.game-icon-tombstone-2:before {
  content: "\E71D";
}

.game-icon-hasty-grave:before {
  content: "\E71E";
}

.game-icon-raise-zombie:before {
  content: "\E71F";
}

.game-icon-bad-breath:before {
  content: "\E720";
}

.game-icon-chainsaw:before {
  content: "\E721";
}

.game-icon-jerrycan:before {
  content: "\E722";
}

.game-icon-brain:before {
  content: "\E723";
}

.game-icon-dark-squad:before {
  content: "\E724";
}

.game-icon-evil-hand:before {
  content: "\E725";
}

.game-icon-ragged-wound:before {
  content: "\E726";
}

.game-icon-asian-lantern:before {
  content: "\E727";
}

.game-icon-bed-lamp:before {
  content: "\E728";
}

.game-icon-ceiling-light:before {
  content: "\E729";
}

.game-icon-double-street-lights:before {
  content: "\E72A";
}

.game-icon-dungeon-gate:before {
  content: "\E72B";
}

.game-icon-dungeon-light:before {
  content: "\E72C";
}

.game-icon-flexible-lamp:before {
  content: "\E72D";
}

.game-icon-fog-light:before {
  content: "\E72E";
}

.game-icon-inspiration:before {
  content: "\E72F";
}

.game-icon-led:before {
  content: "\E730";
}

.game-icon-light-projector:before {
  content: "\E731";
}

.game-icon-old-lantern:before {
  content: "\E732";
}

.game-icon-prism:before {
  content: "\E733";
}

.game-icon-smart:before {
  content: "\E734";
}

.game-icon-street-light:before {
  content: "\E735";
}

.game-icon-wall-light:before {
  content: "\E736";
}

.game-icon-candle-skull:before {
  content: "\E737";
}

.game-icon-desk-lamp:before {
  content: "\E738";
}

.game-icon-double-face-mask:before {
  content: "\E739";
}

.game-icon-duality:before {
  content: "\E73A";
}

.game-icon-eclipse:before {
  content: "\E73B";
}

.game-icon-expanded-rays:before {
  content: "\E73C";
}

.game-icon-explosion-rays:before {
  content: "\E73D";
}

.game-icon-explosive-materials:before {
  content: "\E73E";
}

.game-icon-gooey-eyed-sun:before {
  content: "\E73F";
}

.game-icon-interstellar-path:before {
  content: "\E740";
}

.game-icon-lantern:before {
  content: "\E741";
}

.game-icon-laser-precision:before {
  content: "\E742";
}

.game-icon-laser-sparks:before {
  content: "\E743";
}

.game-icon-laserburn:before {
  content: "\E744";
}

.game-icon-light-bulb:before {
  content: "\E745";
}

.game-icon-magic-lamp:before {
  content: "\E746";
}

.game-icon-paper-lantern:before {
  content: "\E747";
}

.game-icon-radar-sweep:before {
  content: "\E748";
}

.game-icon-shiny-entrance:before {
  content: "\E749";
}

.game-icon-sun:before {
  content: "\E74A";
}

.game-icon-sunbeams:before {
  content: "\E74B";
}

.game-icon-sundial:before {
  content: "\E74C";
}

.game-icon-two-shadows:before {
  content: "\E74D";
}

.game-icon-unlit-candelabra:before {
  content: "\E74E";
}

.game-icon-laser-burst:before {
  content: "\E74F";
}

.game-icon-camping-tent:before {
  content: "\E750";
}

.game-icon-cave-entrance:before {
  content: "\E751";
}

.game-icon-circle-forest:before {
  content: "\E752";
}

.game-icon-desert:before {
  content: "\E753";
}

.game-icon-forest-camp:before {
  content: "\E754";
}

.game-icon-forest:before {
  content: "\E755";
}

.game-icon-fuji:before {
  content: "\E756";
}

.game-icon-grass:before {
  content: "\E757";
}

.game-icon-high-grass:before {
  content: "\E758";
}

.game-icon-hills:before {
  content: "\E759";
}

.game-icon-jungle:before {
  content: "\E75A";
}

.game-icon-monument-valley:before {
  content: "\E75B";
}

.game-icon-mountain-cave:before {
  content: "\E75C";
}

.game-icon-mountain-road:before {
  content: "\E75D";
}

.game-icon-oasis:before {
  content: "\E75E";
}

.game-icon-park-bench:before {
  content: "\E75F";
}

.game-icon-path-tile:before {
  content: "\E760";
}

.game-icon-river:before {
  content: "\E761";
}

.game-icon-stone-stack:before {
  content: "\E762";
}

.game-icon-summits:before {
  content: "\E763";
}

.game-icon-sunrise-2:before {
  content: "\E764";
}

.game-icon-sunset:before {
  content: "\E765";
}

.game-icon-swamp:before {
  content: "\E766";
}

.game-icon-uluru:before {
  content: "\E767";
}

.game-icon-underground-cave:before {
  content: "\E768";
}

.game-icon-waterfall:before {
  content: "\E769";
}

.game-icon-earth-crack:before {
  content: "\E76A";
}

.game-icon-field:before {
  content: "\E76B";
}

.game-icon-flowers:before {
  content: "\E76C";
}

.game-icon-mountains:before {
  content: "\E76D";
}

.game-icon-mountaintop:before {
  content: "\E76E";
}

.game-icon-peaks:before {
  content: "\E76F";
}

.game-icon-pine-tree:before {
  content: "\E770";
}

.game-icon-quicksand:before {
  content: "\E771";
}

.game-icon-stalagtite:before {
  content: "\E772";
}

.game-icon-three-leaves:before {
  content: "\E773";
}

.game-icon-valley:before {
  content: "\E774";
}

.game-icon-wind-hole:before {
  content: "\E775";
}

.game-icon-axe-in-log:before {
  content: "\E776";
}

.game-icon-bamboo-fountain:before {
  content: "\E777";
}

.game-icon-baobab:before {
  content: "\E778";
}

.game-icon-barrel:before {
  content: "\E779";
}

.game-icon-berry-bush:before {
  content: "\E77A";
}

.game-icon-bonsai-tree:before {
  content: "\E77B";
}

.game-icon-chestnut-leaf:before {
  content: "\E77C";
}

.game-icon-chisel:before {
  content: "\E77D";
}

.game-icon-crosscut-saw:before {
  content: "\E77E";
}

.game-icon-crucifix:before {
  content: "\E77F";
}

.game-icon-deku-tree:before {
  content: "\E780";
}

.game-icon-dough-roller:before {
  content: "\E781";
}

.game-icon-empty-wood-bucket-handle:before {
  content: "\E782";
}

.game-icon-empty-wood-bucket:before {
  content: "\E783";
}

.game-icon-forest-entrance:before {
  content: "\E784";
}

.game-icon-fruit-tree:before {
  content: "\E785";
}

.game-icon-full-wood-bucket-handle:before {
  content: "\E786";
}

.game-icon-full-wood-bucket:before {
  content: "\E787";
}

.game-icon-greenhouse:before {
  content: "\E788";
}

.game-icon-half-log:before {
  content: "\E789";
}

.game-icon-hand-saw:before {
  content: "\E78A";
}

.game-icon-hanging-sign:before {
  content: "\E78B";
}

.game-icon-log:before {
  content: "\E78C";
}

.game-icon-oak-leaf:before {
  content: "\E78D";
}

.game-icon-packed-planks:before {
  content: "\E78E";
}

.game-icon-palm-tree:before {
  content: "\E78F";
}

.game-icon-planks:before {
  content: "\E790";
}

.game-icon-solid-leaf:before {
  content: "\E791";
}

.game-icon-spiked-trunk:before {
  content: "\E792";
}

.game-icon-stakes-fence:before {
  content: "\E793";
}

.game-icon-stick-frame:before {
  content: "\E794";
}

.game-icon-stump-regrowth:before {
  content: "\E795";
}

.game-icon-table:before {
  content: "\E796";
}

.game-icon-tavern-sign:before {
  content: "\E797";
}

.game-icon-totem:before {
  content: "\E798";
}

.game-icon-tree-door:before {
  content: "\E799";
}

.game-icon-tree-roots:before {
  content: "\E79A";
}

.game-icon-tree-swing:before {
  content: "\E79B";
}

.game-icon-trunk-mushroom:before {
  content: "\E79C";
}

.game-icon-watchtower:before {
  content: "\E79D";
}

.game-icon-water-diviner-stick:before {
  content: "\E79E";
}

.game-icon-wood-beam:before {
  content: "\E79F";
}

.game-icon-wood-cabin:before {
  content: "\E7A0";
}

.game-icon-wood-frame:before {
  content: "\E7A1";
}

.game-icon-wood-pile:before {
  content: "\E7A2";
}

.game-icon-wood-stick:before {
  content: "\E7A3";
}

.game-icon-wooden-clogs:before {
  content: "\E7A4";
}

.game-icon-wooden-crate:before {
  content: "\E7A5";
}

.game-icon-wooden-pegleg:before {
  content: "\E7A6";
}

.game-icon-acorn:before {
  content: "\E7A7";
}

.game-icon-beech:before {
  content: "\E7A8";
}

.game-icon-circular-sawblade:before {
  content: "\E7A9";
}

.game-icon-curled-leaf:before {
  content: "\E7AA";
}

.game-icon-falling-leaf:before {
  content: "\E7AB";
}

.game-icon-leaf-swirl:before {
  content: "\E7AC";
}

.game-icon-linden-leaf:before {
  content: "\E7AD";
}

.game-icon-maple-leaf:before {
  content: "\E7AE";
}

.game-icon-oak:before {
  content: "\E7AF";
}

.game-icon-root-tip:before {
  content: "\E7B0";
}

.game-icon-totem-mask:before {
  content: "\E7B1";
}

.game-icon-tree-branch:before {
  content: "\E7B2";
}

.game-icon-tribal-mask:before {
  content: "\E7B3";
}

.game-icon-vine-leaf:before {
  content: "\E7B4";
}

.game-icon-wood-axe:before {
  content: "\E7B5";
}

.game-icon-wooden-door:before {
  content: "\E7B6";
}

.game-icon-wooden-fence:before {
  content: "\E7B7";
}

.game-icon-zigzag-leaf:before {
  content: "\E7B8";
}

.game-icon-circular-saw:before {
  content: "\E7B9";
}

.game-icon-logging:before {
  content: "\E7BA";
}

.game-icon-candlestick-phone:before {
  content: "\E7BB";
}

.game-icon-coal-pile:before {
  content: "\E7BC";
}

.game-icon-corset:before {
  content: "\E7BD";
}

.game-icon-lorgnette:before {
  content: "\E7BE";
}

.game-icon-pipes:before {
  content: "\E7BF";
}

.game-icon-steampunk-goggles:before {
  content: "\E7C0";
}

.game-icon-tower-bridge:before {
  content: "\E7C1";
}

.game-icon-clockwork:before {
  content: "\E7C2";
}

.game-icon-cog:before {
  content: "\E7C3";
}

.game-icon-cogsplosion:before {
  content: "\E7C4";
}

.game-icon-gears:before {
  content: "\E7C5";
}

.game-icon-pokecog:before {
  content: "\E7C6";
}

.game-icon-spectacles:before {
  content: "\E7C7";
}

.game-icon-teapot:before {
  content: "\E7C8";
}

.game-icon-umbrella:before {
  content: "\E7C9";
}

.game-icon-pocket-watch:before {
  content: "\E7CA";
}

.game-icon-old-king:before {
  content: "\E7CB";
}

.game-icon-ninja-head:before {
  content: "\E7CC";
}

.game-icon-air-man:before {
  content: "\E7CD";
}

.game-icon-baby-face:before {
  content: "\E7CE";
}

.game-icon-blindfold:before {
  content: "\E7CF";
}

.game-icon-brain-leak:before {
  content: "\E7D0";
}

.game-icon-cleopatra:before {
  content: "\E7D1";
}

.game-icon-clown:before {
  content: "\E7D2";
}

.game-icon-egyptian-profile:before {
  content: "\E7D3";
}

.game-icon-eyepatch:before {
  content: "\E7D4";
}

.game-icon-headband-knot:before {
  content: "\E7D5";
}

.game-icon-invisible-face:before {
  content: "\E7D6";
}

.game-icon-mecha-head:before {
  content: "\E7D7";
}

.game-icon-mecha-mask:before {
  content: "\E7D8";
}

.game-icon-metal-golem-head:before {
  content: "\E7D9";
}

.game-icon-nose-front:before {
  content: "\E7DA";
}

.game-icon-nose-side:before {
  content: "\E7DB";
}

.game-icon-nurse-female:before {
  content: "\E7DC";
}

.game-icon-nurse-male:before {
  content: "\E7DD";
}

.game-icon-pirate-captain:before {
  content: "\E7DE";
}

.game-icon-plane-pilot:before {
  content: "\E7DF";
}

.game-icon-portrait:before {
  content: "\E7E0";
}

.game-icon-quick-man:before {
  content: "\E7E1";
}

.game-icon-robot-antennas:before {
  content: "\E7E2";
}

.game-icon-robot-helmet:before {
  content: "\E7E3";
}

.game-icon-star-struck:before {
  content: "\E7E4";
}

.game-icon-sunglasses:before {
  content: "\E7E5";
}

.game-icon-viking-head:before {
  content: "\E7E6";
}

.game-icon-alien-stare:before {
  content: "\E7E7";
}

.game-icon-beard:before {
  content: "\E7E8";
}

.game-icon-brain-stem:before {
  content: "\E7E9";
}

.game-icon-brainstorm:before {
  content: "\E7EA";
}

.game-icon-comb:before {
  content: "\E7EB";
}

.game-icon-diamonds-smile:before {
  content: "\E7EC";
}

.game-icon-doctor-face:before {
  content: "\E7ED";
}

.game-icon-frontal-lobe:before {
  content: "\E7EE";
}

.game-icon-gaze:before {
  content: "\E7EF";
}

.game-icon-iron-mask:before {
  content: "\E7F0";
}

.game-icon-mad-scientist:before {
  content: "\E7F1";
}

.game-icon-nailed-head:before {
  content: "\E7F2";
}

.game-icon-ninja-mask:before {
  content: "\E7F3";
}

.game-icon-one-eyed:before {
  content: "\E7F4";
}

.game-icon-pig-face:before {
  content: "\E7F5";
}

.game-icon-psychic-waves:before {
  content: "\E7F6";
}

.game-icon-pummeled:before {
  content: "\E7F7";
}

.game-icon-pumpkin-lantern:before {
  content: "\E7F8";
}

.game-icon-pumpkin-mask:before {
  content: "\E7F9";
}

.game-icon-razor-blade:before {
  content: "\E7FA";
}

.game-icon-spectacle-lenses:before {
  content: "\E7FB";
}

.game-icon-spiked-halo:before {
  content: "\E7FC";
}

.game-icon-tear-tracks:before {
  content: "\E7FD";
}

.game-icon-tooth:before {
  content: "\E7FE";
}

.game-icon-overkill:before {
  content: "\E7FF";
}

.game-icon-headshot-2:before {
  content: "\E800";
}

.game-icon-cobra:before {
  content: "\E801";
}

.game-icon-dimetrodon:before {
  content: "\E802";
}

.game-icon-diplodocus:before {
  content: "\E803";
}

.game-icon-frog-foot:before {
  content: "\E804";
}

.game-icon-ninja-velociraptor:before {
  content: "\E805";
}

.game-icon-parasaurolophus:before {
  content: "\E806";
}

.game-icon-rattlesnake:before {
  content: "\E807";
}

.game-icon-reptile-tail:before {
  content: "\E808";
}

.game-icon-sand-snake:before {
  content: "\E809";
}

.game-icon-sauropod-head:before {
  content: "\E80A";
}

.game-icon-snake-egg:before {
  content: "\E80B";
}

.game-icon-snake-jar:before {
  content: "\E80C";
}

.game-icon-snake-spiral:before {
  content: "\E80D";
}

.game-icon-snake-tongue:before {
  content: "\E80E";
}

.game-icon-stegosaurus-scales:before {
  content: "\E80F";
}

.game-icon-t-rex-skull:before {
  content: "\E810";
}

.game-icon-triceratops-head:before {
  content: "\E811";
}

.game-icon-velociraptor-tracks:before {
  content: "\E812";
}

.game-icon-velociraptor:before {
  content: "\E813";
}

.game-icon-lizard-tongue:before {
  content: "\E814";
}

.game-icon-cobra-2:before {
  content: "\E815";
}

.game-icon-defensive-wall:before {
  content: "\E816";
}

.game-icon-3d-stairs:before {
  content: "\E817";
}

.game-icon-airtight-hatch:before {
  content: "\E818";
}

.game-icon-arabic-door:before {
  content: "\E819";
}

.game-icon-arc-triomphe:before {
  content: "\E81A";
}

.game-icon-ascending-block:before {
  content: "\E81B";
}

.game-icon-bank:before {
  content: "\E81C";
}

.game-icon-barn:before {
  content: "\E81D";
}

.game-icon-barricade:before {
  content: "\E81E";
}

.game-icon-barrier:before {
  content: "\E81F";
}

.game-icon-base-dome:before {
  content: "\E820";
}

.game-icon-block-house:before {
  content: "\E821";
}

.game-icon-boxing-ring:before {
  content: "\E822";
}

.game-icon-brick-pile:before {
  content: "\E823";
}

.game-icon-brick-wall:before {
  content: "\E824";
}

.game-icon-broken-wall:before {
  content: "\E825";
}

.game-icon-byzantin-temple:before {
  content: "\E826";
}

.game-icon-cage:before {
  content: "\E827";
}

.game-icon-carousel:before {
  content: "\E828";
}

.game-icon-castle-ruins:before {
  content: "\E829";
}

.game-icon-castle:before {
  content: "\E82A";
}

.game-icon-church:before {
  content: "\E82B";
}

.game-icon-closed-doors:before {
  content: "\E82C";
}

.game-icon-concrete-bag:before {
  content: "\E82D";
}

.game-icon-congress:before {
  content: "\E82E";
}

.game-icon-control-tower:before {
  content: "\E82F";
}

.game-icon-crane:before {
  content: "\E830";
}

.game-icon-crypt-entrance:before {
  content: "\E831";
}

.game-icon-dam:before {
  content: "\E832";
}

.game-icon-djed-pillar:before {
  content: "\E833";
}

.game-icon-egyptian-pyramids:before {
  content: "\E834";
}

.game-icon-egyptian-temple:before {
  content: "\E835";
}

.game-icon-elevator:before {
  content: "\E836";
}

.game-icon-entry-door:before {
  content: "\E837";
}

.game-icon-escalator:before {
  content: "\E838";
}

.game-icon-evil-tower:before {
  content: "\E839";
}

.game-icon-exit-door:before {
  content: "\E83A";
}

.game-icon-factory:before {
  content: "\E83B";
}

.game-icon-family-house:before {
  content: "\E83C";
}

.game-icon-floating-platforms:before {
  content: "\E83D";
}

.game-icon-gate:before {
  content: "\E83E";
}

.game-icon-goblin-camp:before {
  content: "\E83F";
}

.game-icon-gold-mine:before {
  content: "\E840";
}

.game-icon-granary:before {
  content: "\E841";
}

.game-icon-great-pyramid:before {
  content: "\E842";
}

.game-icon-habitat-dome:before {
  content: "\E843";
}

.game-icon-hill-fort:before {
  content: "\E844";
}

.game-icon-hobbit-dwelling:before {
  content: "\E845";
}

.game-icon-hospital:before {
  content: "\E846";
}

.game-icon-house:before {
  content: "\E847";
}

.game-icon-hut:before {
  content: "\E848";
}

.game-icon-huts-village:before {
  content: "\E849";
}

.game-icon-i-beam:before {
  content: "\E84A";
}

.game-icon-igloo:before {
  content: "\E84B";
}

.game-icon-indian-palace:before {
  content: "\E84C";
}

.game-icon-kid-slide:before {
  content: "\E84D";
}

.game-icon-ladder:before {
  content: "\E84E";
}

.game-icon-louvre-pyramid:before {
  content: "\E84F";
}

.game-icon-martyr-memorial:before {
  content: "\E850";
}

.game-icon-mayan-pyramid:before {
  content: "\E851";
}

.game-icon-medieval-barracks:before {
  content: "\E852";
}

.game-icon-medieval-gate:before {
  content: "\E853";
}

.game-icon-moai:before {
  content: "\E854";
}

.game-icon-modern-city:before {
  content: "\E855";
}

.game-icon-mushroom-house:before {
  content: "\E856";
}

.game-icon-obelisk:before {
  content: "\E857";
}

.game-icon-observatory:before {
  content: "\E858";
}

.game-icon-oil-pump:before {
  content: "\E859";
}

.game-icon-oil-rig:before {
  content: "\E85A";
}

.game-icon-pagoda:before {
  content: "\E85B";
}

.game-icon-pisa-tower:before {
  content: "\E85C";
}

.game-icon-post-office:before {
  content: "\E85D";
}

.game-icon-pylon:before {
  content: "\E85E";
}

.game-icon-refinery:before {
  content: "\E85F";
}

.game-icon-rempart:before {
  content: "\E860";
}

.game-icon-round-silo:before {
  content: "\E861";
}

.game-icon-saint-basil-cathedral:before {
  content: "\E862";
}

.game-icon-saloon-doors:before {
  content: "\E863";
}

.game-icon-saloon:before {
  content: "\E864";
}

.game-icon-samara-mosque:before {
  content: "\E865";
}

.game-icon-secret-door:before {
  content: "\E866";
}

.game-icon-shinto-shrine:before {
  content: "\E867";
}

.game-icon-shop:before {
  content: "\E868";
}

.game-icon-space-needle:before {
  content: "\E869";
}

.game-icon-spooky-house:before {
  content: "\E86A";
}

.game-icon-stairs:before {
  content: "\E86B";
}

.game-icon-stockpiles:before {
  content: "\E86C";
}

.game-icon-stone-wall:before {
  content: "\E86D";
}

.game-icon-sverd-i-fjell:before {
  content: "\E86E";
}

.game-icon-sydney-opera-house:before {
  content: "\E86F";
}

.game-icon-tee-pipe:before {
  content: "\E870";
}

.game-icon-temple-gate:before {
  content: "\E871";
}

.game-icon-theater-curtains:before {
  content: "\E872";
}

.game-icon-tipi:before {
  content: "\E873";
}

.game-icon-tower-flag:before {
  content: "\E874";
}

.game-icon-traffic-cone:before {
  content: "\E875";
}

.game-icon-triple-gate:before {
  content: "\E876";
}

.game-icon-tv-tower:before {
  content: "\E877";
}

.game-icon-viking-church:before {
  content: "\E878";
}

.game-icon-viking-longhouse:before {
  content: "\E879";
}

.game-icon-village:before {
  content: "\E87A";
}

.game-icon-warp-pipe:before {
  content: "\E87B";
}

.game-icon-water-tank:before {
  content: "\E87C";
}

.game-icon-water-tower:before {
  content: "\E87D";
}

.game-icon-well:before {
  content: "\E87E";
}

.game-icon-wheelbarrow:before {
  content: "\E87F";
}

.game-icon-wind-turbine:before {
  content: "\E880";
}

.game-icon-windmill:before {
  content: "\E881";
}

.game-icon-window:before {
  content: "\E882";
}

.game-icon-windpump:before {
  content: "\E883";
}

.game-icon-castle-2:before {
  content: "\E884";
}

.game-icon-demolish:before {
  content: "\E885";
}

.game-icon-fountain:before {
  content: "\E886";
}

.game-icon-guarded-tower:before {
  content: "\E887";
}

.game-icon-heart-tower:before {
  content: "\E888";
}

.game-icon-locked-fortress:before {
  content: "\E889";
}

.game-icon-magic-gate:before {
  content: "\E88A";
}

.game-icon-magic-portal:before {
  content: "\E88B";
}

.game-icon-mucous-pillar:before {
  content: "\E88C";
}

.game-icon-portculis:before {
  content: "\E88D";
}

.game-icon-radar-dish:before {
  content: "\E88E";
}

.game-icon-stone-tower:before {
  content: "\E88F";
}

.game-icon-tower-fall:before {
  content: "\E890";
}

.game-icon-white-tower:before {
  content: "\E891";
}

.game-icon-wrecking-ball:before {
  content: "\E892";
}

.game-icon-elven-castle:before {
  content: "\E893";
}

.game-icon-arena:before {
  content: "\E894";
}

.game-icon-brazil-flag:before {
  content: "\E895";
}

.game-icon-checkered-flag:before {
  content: "\E896";
}

.game-icon-conqueror:before {
  content: "\E897";
}

.game-icon-corner-flag:before {
  content: "\E898";
}

.game-icon-european-flag:before {
  content: "\E899";
}

.game-icon-golf-flag:before {
  content: "\E89A";
}

.game-icon-hill-conquest:before {
  content: "\E89B";
}

.game-icon-knight-banner:before {
  content: "\E89C";
}

.game-icon-level-end-flag:before {
  content: "\E89D";
}

.game-icon-party-flags:before {
  content: "\E89E";
}

.game-icon-pirate-flag:before {
  content: "\E89F";
}

.game-icon-planet-conquest:before {
  content: "\E8A0";
}

.game-icon-south-africa-flag:before {
  content: "\E8A1";
}

.game-icon-star-flag:before {
  content: "\E8A2";
}

.game-icon-truce:before {
  content: "\E8A3";
}

.game-icon-tusks-flag:before {
  content: "\E8A4";
}

.game-icon-vertical-banner:before {
  content: "\E8A5";
}

.game-icon-broken-ribbon:before {
  content: "\E8A6";
}

.game-icon-flying-flag:before {
  content: "\E8A7";
}

.game-icon-spinning-ribbons:before {
  content: "\E8A8";
}

.game-icon-tattered-banner:before {
  content: "\E8A9";
}

.game-icon-curling-stone:before {
  content: "\E8AA";
}

.game-icon-frozen-body:before {
  content: "\E8AB";
}

.game-icon-frozen-ring:before {
  content: "\E8AC";
}

.game-icon-fur-boot:before {
  content: "\E8AD";
}

.game-icon-hockey:before {
  content: "\E8AE";
}

.game-icon-ice-cream-cone:before {
  content: "\E8AF";
}

.game-icon-ice-cubes:before {
  content: "\E8B0";
}

.game-icon-ice-iris:before {
  content: "\E8B1";
}

.game-icon-ice-pop:before {
  content: "\E8B2";
}

.game-icon-ice-skate:before {
  content: "\E8B3";
}

.game-icon-ice-spell-cast:before {
  content: "\E8B4";
}

.game-icon-melting-ice-cube:before {
  content: "\E8B5";
}

.game-icon-ski-boot:before {
  content: "\E8B6";
}

.game-icon-snowboard:before {
  content: "\E8B7";
}

.game-icon-stalactites:before {
  content: "\E8B8";
}

.game-icon-thermometer-cold:before {
  content: "\E8B9";
}

.game-icon-winter-gloves:before {
  content: "\E8BA";
}

.game-icon-beveled-star:before {
  content: "\E8BB";
}

.game-icon-cold-heart:before {
  content: "\E8BC";
}

.game-icon-eskimo:before {
  content: "\E8BD";
}

.game-icon-frozen-block:before {
  content: "\E8BE";
}

.game-icon-frozen-orb:before {
  content: "\E8BF";
}

.game-icon-ice-bolt:before {
  content: "\E8C0";
}

.game-icon-ice-bomb:before {
  content: "\E8C1";
}

.game-icon-ice-cube:before {
  content: "\E8C2";
}

.game-icon-icebergs:before {
  content: "\E8C3";
}

.game-icon-icicles-aura:before {
  content: "\E8C4";
}

.game-icon-icicles-fence:before {
  content: "\E8C5";
}

.game-icon-snow-bottle:before {
  content: "\E8C6";
}

.game-icon-snowflake-1:before {
  content: "\E8C7";
}

.game-icon-snowflake-2:before {
  content: "\E8C8";
}

.game-icon-snowing:before {
  content: "\E8C9";
}

.game-icon-snowman:before {
  content: "\E8CA";
}

.game-icon-flake:before {
  content: "\E8CB";
}

.game-icon-hooded-assassin:before {
  content: "\E8CC";
}

.game-icon-hooded-figure:before {
  content: "\E8CD";
}

.game-icon-ninja-heroic-stance:before {
  content: "\E8CE";
}

.game-icon-arm-bandage:before {
  content: "\E8CF";
}

.game-icon-arm-sling:before {
  content: "\E8D0";
}

.game-icon-blacksmith:before {
  content: "\E8D1";
}

.game-icon-body-balance:before {
  content: "\E8D2";
}

.game-icon-caveman:before {
  content: "\E8D3";
}

.game-icon-character:before {
  content: "\E8D4";
}

.game-icon-contortionist:before {
  content: "\E8D5";
}

.game-icon-cook:before {
  content: "\E8D6";
}

.game-icon-cryo-chamber:before {
  content: "\E8D7";
}

.game-icon-egyptian-walk:before {
  content: "\E8D8";
}

.game-icon-face-to-face:before {
  content: "\E8D9";
}

.game-icon-farmer:before {
  content: "\E8DA";
}

.game-icon-guards:before {
  content: "\E8DB";
}

.game-icon-high-kick:before {
  content: "\E8DC";
}

.game-icon-high-punch:before {
  content: "\E8DD";
}

.game-icon-human-pyramid:before {
  content: "\E8DE";
}

.game-icon-invisible:before {
  content: "\E8DF";
}

.game-icon-jumping-rope:before {
  content: "\E8E0";
}

.game-icon-knee-bandage:before {
  content: "\E8E1";
}

.game-icon-lovers:before {
  content: "\E8E2";
}

.game-icon-medallist:before {
  content: "\E8E3";
}

.game-icon-mighty-force:before {
  content: "\E8E4";
}

.game-icon-person:before {
  content: "\E8E5";
}

.game-icon-pikeman:before {
  content: "\E8E6";
}

.game-icon-ringmaster:before {
  content: "\E8E7";
}

.game-icon-sleeping-bag:before {
  content: "\E8E8";
}

.game-icon-spiked-wall:before {
  content: "\E8E9";
}

.game-icon-strong-man:before {
  content: "\E8EA";
}

.game-icon-sun-priest:before {
  content: "\E8EB";
}

.game-icon-three-friends:before {
  content: "\E8EC";
}

.game-icon-tightrope:before {
  content: "\E8ED";
}

.game-icon-tribunal-jury:before {
  content: "\E8EE";
}

.game-icon-vampire-cape:before {
  content: "\E8EF";
}

.game-icon-van-damme-split:before {
  content: "\E8F0";
}

.game-icon-vitruvian-man:before {
  content: "\E8F1";
}

.game-icon-weight-lifting-down:before {
  content: "\E8F2";
}

.game-icon-weight-lifting-up:before {
  content: "\E8F3";
}

.game-icon-anatomy:before {
  content: "\E8F4";
}

.game-icon-aura:before {
  content: "\E8F5";
}

.game-icon-backup:before {
  content: "\E8F6";
}

.game-icon-beams-aura:before {
  content: "\E8F7";
}

.game-icon-body-swapping:before {
  content: "\E8F8";
}

.game-icon-deadly-strike:before {
  content: "\E8F9";
}

.game-icon-dna1:before {
  content: "\E8FA";
}

.game-icon-dna2:before {
  content: "\E8FB";
}

.game-icon-dozen:before {
  content: "\E8FC";
}

.game-icon-embrassed-energy:before {
  content: "\E8FD";
}

.game-icon-ghost-ally:before {
  content: "\E8FE";
}

.game-icon-heart-inside:before {
  content: "\E8FF";
}

.game-icon-heart-organ:before {
  content: "\E900";
}

.game-icon-inner-self:before {
  content: "\E901";
}

.game-icon-master-of-arms:before {
  content: "\E902";
}

.game-icon-minions:before {
  content: "\E903";
}

.game-icon-muscle-fat:before {
  content: "\E904";
}

.game-icon-octoman:before {
  content: "\E905";
}

.game-icon-puppet:before {
  content: "\E906";
}

.game-icon-rear-aura:before {
  content: "\E907";
}

.game-icon-relationship-bounds:before {
  content: "\E908";
}

.game-icon-rogue:before {
  content: "\E909";
}

.game-icon-shadow-follower:before {
  content: "\E90A";
}

.game-icon-sinking-trap:before {
  content: "\E90B";
}

.game-icon-skeleton-inside:before {
  content: "\E90C";
}

.game-icon-split-body:before {
  content: "\E90D";
}

.game-icon-static-guard:before {
  content: "\E90E";
}

.game-icon-sword-tie:before {
  content: "\E90F";
}

.game-icon-target-dummy:before {
  content: "\E910";
}

.game-icon-telepathy:before {
  content: "\E911";
}

.game-icon-transportation-rings:before {
  content: "\E912";
}

.game-icon-falling:before {
  content: "\E913";
}

.game-icon-fat:before {
  content: "\E914";
}

.game-icon-skeleton:before {
  content: "\E915";
}

.game-icon-crystal-earrings:before {
  content: "\E916";
}

.game-icon-diamond-hilt:before {
  content: "\E917";
}

.game-icon-diamond-ring:before {
  content: "\E918";
}

.game-icon-double-necklace:before {
  content: "\E919";
}

.game-icon-drop-earrings:before {
  content: "\E91A";
}

.game-icon-earrings:before {
  content: "\E91B";
}

.game-icon-emerald-necklace:before {
  content: "\E91C";
}

.game-icon-feather-necklace:before {
  content: "\E91D";
}

.game-icon-globe-ring:before {
  content: "\E91E";
}

.game-icon-heart-earrings:before {
  content: "\E91F";
}

.game-icon-heart-necklace:before {
  content: "\E920";
}

.game-icon-intricate-necklace:before {
  content: "\E921";
}

.game-icon-necklace-display:before {
  content: "\E922";
}

.game-icon-oyster-pearl:before {
  content: "\E923";
}

.game-icon-pearl-earring:before {
  content: "\E924";
}

.game-icon-pearl-necklace:before {
  content: "\E925";
}

.game-icon-pendant-key:before {
  content: "\E926";
}

.game-icon-power-ring:before {
  content: "\E927";
}

.game-icon-prayer-beads:before {
  content: "\E928";
}

.game-icon-primitive-necklace:before {
  content: "\E929";
}

.game-icon-ring-box:before {
  content: "\E92A";
}

.game-icon-ring:before {
  content: "\E92B";
}

.game-icon-rupee:before {
  content: "\E92C";
}

.game-icon-tribal-pendant:before {
  content: "\E92D";
}

.game-icon-crystal-growth:before {
  content: "\E92E";
}

.game-icon-crystalize:before {
  content: "\E92F";
}

.game-icon-cut-diamond:before {
  content: "\E930";
}

.game-icon-emerald:before {
  content: "\E931";
}

.game-icon-engagement-ring:before {
  content: "\E932";
}

.game-icon-gem-chain:before {
  content: "\E933";
}

.game-icon-gem-necklace:before {
  content: "\E934";
}

.game-icon-gem-pendant:before {
  content: "\E935";
}

.game-icon-jeweled-chalice:before {
  content: "\E936";
}

.game-icon-linked-rings:before {
  content: "\E937";
}

.game-icon-medal-skull:before {
  content: "\E938";
}

.game-icon-medal:before {
  content: "\E939";
}

.game-icon-saphir:before {
  content: "\E93A";
}

.game-icon-skull-ring:before {
  content: "\E93B";
}

.game-icon-skull-signet:before {
  content: "\E93C";
}

.game-icon-spiked-collar:before {
  content: "\E93D";
}

.game-icon-trophy:before {
  content: "\E93E";
}

.game-icon-necklace:before {
  content: "\E93F";
}

.game-icon-amethyst:before {
  content: "\E940";
}

.game-icon-big-diamond-ring:before {
  content: "\E941";
}

.game-icon-topaz:before {
  content: "\E942";
}

.game-icon-big-gear:before {
  content: "\E943";
}

.game-icon-belt-armor:before {
  content: "\E944";
}

.game-icon-bolt-cutter:before {
  content: "\E945";
}

.game-icon-bottle-cap:before {
  content: "\E946";
}

.game-icon-carabiner:before {
  content: "\E947";
}

.game-icon-empty-metal-bucket-handle:before {
  content: "\E948";
}

.game-icon-empty-metal-bucket:before {
  content: "\E949";
}

.game-icon-foundry-bucket:before {
  content: "\E94A";
}

.game-icon-full-metal-bucket-handle:before {
  content: "\E94B";
}

.game-icon-full-metal-bucket:before {
  content: "\E94C";
}

.game-icon-gold-stack:before {
  content: "\E94D";
}

.game-icon-hexagonal-nut:before {
  content: "\E94E";
}

.game-icon-ladle:before {
  content: "\E94F";
}

.game-icon-melting-metal:before {
  content: "\E950";
}

.game-icon-metal-plate:before {
  content: "\E951";
}

.game-icon-ring-mould:before {
  content: "\E952";
}

.game-icon-screw:before {
  content: "\E953";
}

.game-icon-shoulder-armor:before {
  content: "\E954";
}

.game-icon-spring:before {
  content: "\E955";
}

.game-icon-straight-pipe:before {
  content: "\E956";
}

.game-icon-sword-mold:before {
  content: "\E957";
}

.game-icon-wire-coil:before {
  content: "\E958";
}

.game-icon-zipper:before {
  content: "\E959";
}

.game-icon-anvil-impact:before {
  content: "\E95A";
}

.game-icon-anvil:before {
  content: "\E95B";
}

.game-icon-barbed-coil:before {
  content: "\E95C";
}

.game-icon-barbed-wire:before {
  content: "\E95D";
}

.game-icon-black-bar:before {
  content: "\E95E";
}

.game-icon-cash:before {
  content: "\E95F";
}

.game-icon-crossed-chains:before {
  content: "\E960";
}

.game-icon-magnet-blast:before {
  content: "\E961";
}

.game-icon-magnet:before {
  content: "\E962";
}

.game-icon-metal-bar:before {
  content: "\E963";
}

.game-icon-metal-disc:before {
  content: "\E964";
}

.game-icon-metal-hand:before {
  content: "\E965";
}

.game-icon-metal-scales:before {
  content: "\E966";
}

.game-icon-nails:before {
  content: "\E967";
}

.game-icon-needle-drill:before {
  content: "\E968";
}

.game-icon-robot-golem:before {
  content: "\E969";
}

.game-icon-screw-2:before {
  content: "\E96A";
}

.game-icon-spoon:before {
  content: "\E96B";
}

.game-icon-steel-claws:before {
  content: "\E96C";
}

.game-icon-steeltoe-boots:before {
  content: "\E96D";
}

.game-icon-wavy-chains:before {
  content: "\E96E";
}

.game-icon-weight-crush:before {
  content: "\E96F";
}

.game-icon-breaking-chain:before {
  content: "\E970";
}

.game-icon-steel-claws-2:before {
  content: "\E971";
}

.game-icon-gold-bar:before {
  content: "\E972";
}

.game-icon-tabi-boot:before {
  content: "\E973";
}

.game-icon-bamboo:before {
  content: "\E974";
}

.game-icon-black-belt:before {
  content: "\E975";
}

.game-icon-handheld-fan:before {
  content: "\E976";
}

.game-icon-japanese-bridge:before {
  content: "\E977";
}

.game-icon-katana:before {
  content: "\E978";
}

.game-icon-kimono:before {
  content: "\E979";
}

.game-icon-night-vision:before {
  content: "\E97A";
}

.game-icon-ninja-armor:before {
  content: "\E97B";
}

.game-icon-onigori:before {
  content: "\E97C";
}

.game-icon-rope-dart:before {
  content: "\E97D";
}

.game-icon-shinto-shrine-mirror:before {
  content: "\E97E";
}

.game-icon-teapot-leaves:before {
  content: "\E97F";
}

.game-icon-yunluo:before {
  content: "\E980";
}

.game-icon-assassin-pocket:before {
  content: "\E981";
}

.game-icon-lotus:before {
  content: "\E982";
}

.game-icon-sword-slice:before {
  content: "\E983";
}

.game-icon-thrown-daggers:before {
  content: "\E984";
}

.game-icon-apothecary:before {
  content: "\E985";
}

.game-icon-cauldron:before {
  content: "\E986";
}

.game-icon-butter:before {
  content: "\E987";
}

.game-icon-cloth-jar:before {
  content: "\E988";
}

.game-icon-coffee-cup:before {
  content: "\E989";
}

.game-icon-coffee-pot:before {
  content: "\E98A";
}

.game-icon-cooking-pot:before {
  content: "\E98B";
}

.game-icon-corkscrew:before {
  content: "\E98C";
}

.game-icon-covered-jar:before {
  content: "\E98D";
}

.game-icon-fork-knife-spoon:before {
  content: "\E98E";
}

.game-icon-glass-celebration:before {
  content: "\E98F";
}

.game-icon-ice-cream-scoop:before {
  content: "\E990";
}

.game-icon-kitchen-scale:before {
  content: "\E991";
}

.game-icon-kitchen-tap:before {
  content: "\E992";
}

.game-icon-manual-juicer:before {
  content: "\E993";
}

.game-icon-manual-meat-grinder:before {
  content: "\E994";
}

.game-icon-meal:before {
  content: "\E995";
}

.game-icon-moka-pot:before {
  content: "\E996";
}

.game-icon-painted-pottery:before {
  content: "\E997";
}

.game-icon-porcelain-vase:before {
  content: "\E998";
}

.game-icon-pouring-pot:before {
  content: "\E999";
}

.game-icon-toaster:before {
  content: "\E99A";
}

.game-icon-whisk:before {
  content: "\E99B";
}

.game-icon-bandage-roll:before {
  content: "\E99C";
}

.game-icon-bowl-spiral:before {
  content: "\E99D";
}

.game-icon-cauldron-2:before {
  content: "\E99E";
}

.game-icon-chalice-drops:before {
  content: "\E99F";
}

.game-icon-coffee-mug:before {
  content: "\E9A0";
}

.game-icon-glass-shot:before {
  content: "\E9A1";
}

.game-icon-kitchen-knives:before {
  content: "\E9A2";
}

.game-icon-knife-fork:before {
  content: "\E9A3";
}

.game-icon-martini:before {
  content: "\E9A4";
}

.game-icon-meat-cleaver:before {
  content: "\E9A5";
}

.game-icon-pizza-cutter:before {
  content: "\E9A6";
}

.game-icon-shattered-glass:before {
  content: "\E9A7";
}

.game-icon-wine-glass:before {
  content: "\E9A8";
}

.game-icon-egg-pod:before {
  content: "\E9A9";
}

.game-icon-fruit-bowl:before {
  content: "\E9AA";
}

.game-icon-pestle-mortar:before {
  content: "\E9AB";
}

.game-icon-eternal-love:before {
  content: "\E9AC";
}

.game-icon-first-aid-kit:before {
  content: "\E9AD";
}

.game-icon-foot-plaster:before {
  content: "\E9AE";
}

.game-icon-hand-bandage:before {
  content: "\E9AF";
}

.game-icon-healing:before {
  content: "\E9B0";
}

.game-icon-health-capsule:before {
  content: "\E9B1";
}

.game-icon-health-potion:before {
  content: "\E9B2";
}

.game-icon-herbs-bundle:before {
  content: "\E9B3";
}

.game-icon-life-bar:before {
  content: "\E9B4";
}

.game-icon-medical-drip:before {
  content: "\E9B5";
}

.game-icon-medical-thermometer:before {
  content: "\E9B6";
}

.game-icon-medicine-pills:before {
  content: "\E9B7";
}

.game-icon-medicines:before {
  content: "\E9B8";
}

.game-icon-nested-hearts:before {
  content: "\E9B9";
}

.game-icon-remedy:before {
  content: "\E9BA";
}

.game-icon-stethoscope:before {
  content: "\E9BB";
}

.game-icon-broken-heart:before {
  content: "\E9BC";
}

.game-icon-defibrilate:before {
  content: "\E9BD";
}

.game-icon-embryo:before {
  content: "\E9BE";
}

.game-icon-heart-bottle:before {
  content: "\E9BF";
}

.game-icon-life-support:before {
  content: "\E9C0";
}

.game-icon-life-tap:before {
  content: "\E9C1";
}

.game-icon-love-injection:before {
  content: "\E9C2";
}

.game-icon-miracle-medecine:before {
  content: "\E9C3";
}

.game-icon-overdose:before {
  content: "\E9C4";
}

.game-icon-pill-drop:before {
  content: "\E9C5";
}

.game-icon-pill:before {
  content: "\E9C6";
}

.game-icon-scalpel:before {
  content: "\E9C7";
}

.game-icon-sticking-plaster:before {
  content: "\E9C8";
}

.game-icon-syringe:before {
  content: "\E9C9";
}

.game-icon-health-decrease:before {
  content: "\E9CA";
}

.game-icon-health-increase:before {
  content: "\E9CB";
}

.game-icon-health-normal:before {
  content: "\E9CC";
}

.game-icon-medical-pack-alt:before {
  content: "\E9CD";
}

.game-icon-medical-pack:before {
  content: "\E9CE";
}

.game-icon-fetus:before {
  content: "\E9CF";
}

.game-icon-heart-minus:before {
  content: "\E9D0";
}

.game-icon-heart-plus:before {
  content: "\E9D1";
}

.game-icon-ancient-screw:before {
  content: "\E9D2";
}

.game-icon-beam-satellite:before {
  content: "\E9D3";
}

.game-icon-bouncing-spring:before {
  content: "\E9D4";
}

.game-icon-computer-fan:before {
  content: "\E9D5";
}

.game-icon-death-star:before {
  content: "\E9D6";
}

.game-icon-drill:before {
  content: "\E9D7";
}

.game-icon-floor-polisher:before {
  content: "\E9D8";
}

.game-icon-gps:before {
  content: "\E9D9";
}

.game-icon-love-mystery:before {
  content: "\E9DA";
}

.game-icon-plug:before {
  content: "\E9DB";
}

.game-icon-power-generator:before {
  content: "\E9DC";
}

.game-icon-round-knob:before {
  content: "\E9DD";
}

.game-icon-satellite-communication:before {
  content: "\E9DE";
}

.game-icon-security-gate:before {
  content: "\E9DF";
}

.game-icon-settings-knobs:before {
  content: "\E9E0";
}

.game-icon-star-gate:before {
  content: "\E9E1";
}

.game-icon-tv:before {
  content: "\E9E2";
}

.game-icon-vacuum-cleaner:before {
  content: "\E9E3";
}

.game-icon-valve:before {
  content: "\E9E4";
}

.game-icon-vending-machine:before {
  content: "\E9E5";
}

.game-icon-video-conference:before {
  content: "\E9E6";
}

.game-icon-auto-repair:before {
  content: "\E9E7";
}

.game-icon-circuitry:before {
  content: "\E9E8";
}

.game-icon-cog-lock:before {
  content: "\E9E9";
}

.game-icon-drill-2:before {
  content: "\E9EA";
}

.game-icon-lever:before {
  content: "\E9EB";
}

.game-icon-mechanical-arm:before {
  content: "\E9EC";
}

.game-icon-microchip:before {
  content: "\E9ED";
}

.game-icon-microscope-lens:before {
  content: "\E9EE";
}

.game-icon-overdrive:before {
  content: "\E9EF";
}

.game-icon-processor:before {
  content: "\E9F0";
}

.game-icon-sattelite:before {
  content: "\E9F1";
}

.game-icon-skid-mark:before {
  content: "\E9F2";
}

.game-icon-spoutnik:before {
  content: "\E9F3";
}

.game-icon-tyre:before {
  content: "\E9F4";
}

.game-icon-computing:before {
  content: "\E9F5";
}

.game-icon-diagram:before {
  content: "\E9F6";
}

.game-icon-microscope:before {
  content: "\E9F7";
}

.game-icon-tesla:before {
  content: "\E9F8";
}

.game-icon-unplugged:before {
  content: "\E9F9";
}

.game-icon-revolt:before {
  content: "\E9FA";
}

.game-icon-robber-hand:before {
  content: "\E9FB";
}

.game-icon-severed-hand:before {
  content: "\E9FC";
}

.game-icon-fingers-crossed:before {
  content: "\E9FD";
}

.game-icon-paper:before {
  content: "\E9FE";
}

.game-icon-rock:before {
  content: "\E9FF";
}

.game-icon-scissors:before {
  content: "\EA00";
}

.game-icon-armor-punch:before {
  content: "\EA01";
}

.game-icon-baseball-glove:before {
  content: "\EA02";
}

.game-icon-bolt-spell-cast:before {
  content: "\EA03";
}

.game-icon-cherish:before {
  content: "\EA04";
}

.game-icon-cooking-glove:before {
  content: "\EA05";
}

.game-icon-fingernail:before {
  content: "\EA06";
}

.game-icon-forearm:before {
  content: "\EA07";
}

.game-icon-gauntlet:before {
  content: "\EA08";
}

.game-icon-gloves:before {
  content: "\EA09";
}

.game-icon-hand-grip:before {
  content: "\EA0A";
}

.game-icon-hand-of-god:before {
  content: "\EA0B";
}

.game-icon-hand-ok:before {
  content: "\EA0C";
}

.game-icon-marble-tap:before {
  content: "\EA0D";
}

.game-icon-pirate-hook:before {
  content: "\EA0E";
}

.game-icon-spock-hand:before {
  content: "\EA0F";
}

.game-icon-take-my-money:before {
  content: "\EA10";
}

.game-icon-thumb-down:before {
  content: "\EA11";
}

.game-icon-thumb-up:before {
  content: "\EA12";
}

.game-icon-wolverine-claws-2:before {
  content: "\EA13";
}

.game-icon-boxing-glove-surprise:before {
  content: "\EA14";
}

.game-icon-boxing-glove:before {
  content: "\EA15";
}

.game-icon-cut-palm:before {
  content: "\EA16";
}

.game-icon-fist:before {
  content: "\EA17";
}

.game-icon-fulguro-punch:before {
  content: "\EA18";
}

.game-icon-gift-of-knowledge:before {
  content: "\EA19";
}

.game-icon-glowing-hands:before {
  content: "\EA1A";
}

.game-icon-hand:before {
  content: "\EA1B";
}

.game-icon-high-five:before {
  content: "\EA1C";
}

.game-icon-magic-palm:before {
  content: "\EA1D";
}

.game-icon-magic-swirl:before {
  content: "\EA1E";
}

.game-icon-mailed-fist:before {
  content: "\EA1F";
}

.game-icon-monster-grasp:before {
  content: "\EA20";
}

.game-icon-moon-claws:before {
  content: "\EA21";
}

.game-icon-palm:before {
  content: "\EA22";
}

.game-icon-punch-blast:before {
  content: "\EA23";
}

.game-icon-punch:before {
  content: "\EA24";
}

.game-icon-shadow-grasp:before {
  content: "\EA25";
}

.game-icon-stigmata:before {
  content: "\EA26";
}

.game-icon-thor-fist:before {
  content: "\EA27";
}

.game-icon-hand-2:before {
  content: "\EA28";
}

.game-icon-revolt-2:before {
  content: "\EA29";
}

.game-icon-usable:before {
  content: "\EA2A";
}

.game-icon-click:before {
  content: "\EA2B";
}

.game-icon-fist-2:before {
  content: "\EA2C";
}

.game-icon-open-palm:before {
  content: "\EA2D";
}

.game-icon-raise-skeleton:before {
  content: "\EA2E";
}

.game-icon-barrel-leak:before {
  content: "\EA2F";
}

.game-icon-basket:before {
  content: "\EA30";
}

.game-icon-beach-bucket:before {
  content: "\EA31";
}

.game-icon-cardboard-box-closed:before {
  content: "\EA32";
}

.game-icon-cardboard-box:before {
  content: "\EA33";
}

.game-icon-cargo-crate:before {
  content: "\EA34";
}

.game-icon-cellar-barrels:before {
  content: "\EA35";
}

.game-icon-chest:before {
  content: "\EA36";
}

.game-icon-companion-cube:before {
  content: "\EA37";
}

.game-icon-database:before {
  content: "\EA38";
}

.game-icon-energy-tank:before {
  content: "\EA39";
}

.game-icon-fuel-tank:before {
  content: "\EA3A";
}

.game-icon-hand-truck:before {
  content: "\EA3B";
}

.game-icon-locked-box:before {
  content: "\EA3C";
}

.game-icon-matryoshka-dolls:before {
  content: "\EA3D";
}

.game-icon-nuclear-waste:before {
  content: "\EA3E";
}

.game-icon-opened-food-can:before {
  content: "\EA3F";
}

.game-icon-paper-tray:before {
  content: "\EA40";
}

.game-icon-present:before {
  content: "\EA41";
}

.game-icon-shoulder-bag:before {
  content: "\EA42";
}

.game-icon-strongbox:before {
  content: "\EA43";
}

.game-icon-toolbox:before {
  content: "\EA44";
}

.game-icon-watering-can:before {
  content: "\EA45";
}

.game-icon-cannister:before {
  content: "\EA46";
}

.game-icon-chemical-tank:before {
  content: "\EA47";
}

.game-icon-jigsaw-box:before {
  content: "\EA48";
}

.game-icon-locked-chest:before {
  content: "\EA49";
}

.game-icon-box-trap:before {
  content: "\EA4A";
}

.game-icon-oil-drum:before {
  content: "\EA4B";
}

.game-icon-open-chest:before {
  content: "\EA4C";
}

.game-icon-open-treasure-chest:before {
  content: "\EA4D";
}

.game-icon-beer-horn:before {
  content: "\EA4E";
}

.game-icon-bolt-drop:before {
  content: "\EA4F";
}

.game-icon-booze:before {
  content: "\EA50";
}

.game-icon-cactus-tap:before {
  content: "\EA51";
}

.game-icon-holy-water:before {
  content: "\EA52";
}

.game-icon-leak:before {
  content: "\EA53";
}

.game-icon-lily-pads:before {
  content: "\EA54";
}

.game-icon-liquid-soap:before {
  content: "\EA55";
}

.game-icon-plant-watering:before {
  content: "\EA56";
}

.game-icon-shower:before {
  content: "\EA57";
}

.game-icon-tap:before {
  content: "\EA58";
}

.game-icon-water-bottle:before {
  content: "\EA59";
}

.game-icon-water-gallon:before {
  content: "\EA5A";
}

.game-icon-water-gun:before {
  content: "\EA5B";
}

.game-icon-acid-blob:before {
  content: "\EA5C";
}

.game-icon-beer-stein:before {
  content: "\EA5D";
}

.game-icon-bleeding-eye:before {
  content: "\EA5E";
}

.game-icon-boiling-bubbles:before {
  content: "\EA5F";
}

.game-icon-bubbles:before {
  content: "\EA60";
}

.game-icon-burst-blob:before {
  content: "\EA61";
}

.game-icon-chemical-drop:before {
  content: "\EA62";
}

.game-icon-dew:before {
  content: "\EA63";
}

.game-icon-drink-me:before {
  content: "\EA64";
}

.game-icon-dripping-blade:before {
  content: "\EA65";
}

.game-icon-dripping-goo:before {
  content: "\EA66";
}

.game-icon-dripping-star:before {
  content: "\EA67";
}

.game-icon-dripping-stone:before {
  content: "\EA68";
}

.game-icon-dripping-sword:before {
  content: "\EA69";
}

.game-icon-drop:before {
  content: "\EA6A";
}

.game-icon-droplet-splash:before {
  content: "\EA6B";
}

.game-icon-droplets:before {
  content: "\EA6C";
}

.game-icon-eyedropper:before {
  content: "\EA6D";
}

.game-icon-foam:before {
  content: "\EA6E";
}

.game-icon-gloop:before {
  content: "\EA6F";
}

.game-icon-goo-explosion:before {
  content: "\EA70";
}

.game-icon-goo-skull:before {
  content: "\EA71";
}

.game-icon-goo-spurt:before {
  content: "\EA72";
}

.game-icon-gooey-impact:before {
  content: "\EA73";
}

.game-icon-gooey-molecule:before {
  content: "\EA74";
}

.game-icon-gooey-sword:before {
  content: "\EA75";
}

.game-icon-grease-trap:before {
  content: "\EA76";
}

.game-icon-grouped-drops:before {
  content: "\EA77";
}

.game-icon-heart-drop:before {
  content: "\EA78";
}

.game-icon-heavy-rain:before {
  content: "\EA79";
}

.game-icon-hypodermic-test:before {
  content: "\EA7A";
}

.game-icon-kaleidoscope-pearls:before {
  content: "\EA7B";
}

.game-icon-marrow-drain:before {
  content: "\EA7C";
}

.game-icon-oily-spiral:before {
  content: "\EA7D";
}

.game-icon-poison-bottle:before {
  content: "\EA7E";
}

.game-icon-spatter:before {
  content: "\EA7F";
}

.game-icon-spill:before {
  content: "\EA80";
}

.game-icon-splash:before {
  content: "\EA81";
}

.game-icon-splashy-stream:before {
  content: "\EA82";
}

.game-icon-splurt:before {
  content: "\EA83";
}

.game-icon-transparent-tubes:before {
  content: "\EA84";
}

.game-icon-water-bolt:before {
  content: "\EA85";
}

.game-icon-milk-carton:before {
  content: "\EA86";
}

.game-icon-acid:before {
  content: "\EA87";
}

.game-icon-water-drop:before {
  content: "\EA88";
}

.game-icon-blood:before {
  content: "\EA89";
}

.game-icon-skull-sabertooth:before {
  content: "\EA8A";
}

.game-icon-calavera:before {
  content: "\EA8B";
}

.game-icon-alien-skull:before {
  content: "\EA8C";
}

.game-icon-blade-bite:before {
  content: "\EA8D";
}

.game-icon-bone-gnawer:before {
  content: "\EA8E";
}

.game-icon-broken-skull:before {
  content: "\EA8F";
}

.game-icon-chewed-skull:before {
  content: "\EA90";
}

.game-icon-chopped-skull:before {
  content: "\EA91";
}

.game-icon-condylura-skull:before {
  content: "\EA92";
}

.game-icon-death-note:before {
  content: "\EA93";
}

.game-icon-deathcab:before {
  content: "\EA94";
}

.game-icon-dread-skull:before {
  content: "\EA95";
}

.game-icon-fanged-skull:before {
  content: "\EA96";
}

.game-icon-happy-skull:before {
  content: "\EA97";
}

.game-icon-harry-potter-skull:before {
  content: "\EA98";
}

.game-icon-leaky-skull:before {
  content: "\EA99";
}

.game-icon-morbid-humour:before {
  content: "\EA9A";
}

.game-icon-piece-skull:before {
  content: "\EA9B";
}

.game-icon-pirate-skull:before {
  content: "\EA9C";
}

.game-icon-sharped-teeth-skull:before {
  content: "\EA9D";
}

.game-icon-skeleton-key:before {
  content: "\EA9E";
}

.game-icon-skull-bolt:before {
  content: "\EA9F";
}

.game-icon-skull-crack:before {
  content: "\EAA0";
}

.game-icon-skull-in-jar:before {
  content: "\EAA1";
}

.game-icon-skull-mask:before {
  content: "\EAA2";
}

.game-icon-skull-slices:before {
  content: "\EAA3";
}

.game-icon-spade-skull:before {
  content: "\EAA4";
}

.game-icon-star-skull:before {
  content: "\EAA5";
}

.game-icon-tentacles-skull:before {
  content: "\EAA6";
}

.game-icon-thunder-skull:before {
  content: "\EAA7";
}

.game-icon-triple-skulls:before {
  content: "\EAA8";
}

.game-icon-death-skull:before {
  content: "\EAA9";
}

.game-icon-skull-with-syringe:before {
  content: "\EAAA";
}

.game-icon-bacon:before {
  content: "\EAAB";
}

.game-icon-kebab-spit:before {
  content: "\EAAC";
}

.game-icon-sausage:before {
  content: "\EAAD";
}

.game-icon-sausages-ribbon:before {
  content: "\EAAE";
}

.game-icon-sliced-sausage:before {
  content: "\EAAF";
}

.game-icon-steak:before {
  content: "\EAB0";
}

.game-icon-meat:before {
  content: "\EAB1";
}

.game-icon-ham-shank:before {
  content: "\EAB2";
}

.game-icon-berries-bowl:before {
  content: "\EAB3";
}

.game-icon-bindle:before {
  content: "\EAB4";
}

.game-icon-bone-knife:before {
  content: "\EAB5";
}

.game-icon-clay-brick:before {
  content: "\EAB6";
}

.game-icon-dolmen:before {
  content: "\EAB7";
}

.game-icon-fur-shirt:before {
  content: "\EAB8";
}

.game-icon-silex:before {
  content: "\EAB9";
}

.game-icon-stone-wheel:before {
  content: "\EABA";
}

.game-icon-bone-knife-2:before {
  content: "\EABB";
}

.game-icon-broken-bone:before {
  content: "\EABC";
}

.game-icon-broken-tablet:before {
  content: "\EABD";
}

.game-icon-rock-2:before {
  content: "\EABE";
}

.game-icon-stone-axe:before {
  content: "\EABF";
}

.game-icon-stone-tablet:before {
  content: "\EAC0";
}

.game-icon-cactus:before {
  content: "\EAC1";
}

.game-icon-cleaver:before {
  content: "\EAC2";
}

.game-icon-heart-stake:before {
  content: "\EAC3";
}

.game-icon-pitchfork:before {
  content: "\EAC4";
}

.game-icon-safety-pin:before {
  content: "\EAC5";
}

.game-icon-shattered-heart:before {
  content: "\EAC6";
}

.game-icon-spiked-shoulder-armor:before {
  content: "\EAC7";
}

.game-icon-spiky-pit:before {
  content: "\EAC8";
}

.game-icon-air-zigzag:before {
  content: "\EAC9";
}

.game-icon-alligator-clip:before {
  content: "\EACA";
}

.game-icon-armoured-shell:before {
  content: "\EACB";
}

.game-icon-atomic-slashes:before {
  content: "\EACC";
}

.game-icon-bleeding-heart:before {
  content: "\EACD";
}

.game-icon-burning-round-shot:before {
  content: "\EACE";
}

.game-icon-circle-claws:before {
  content: "\EACF";
}

.game-icon-claw-slashes:before {
  content: "\EAD0";
}

.game-icon-cracked-ball-dunk:before {
  content: "\EAD1";
}

.game-icon-cracked-disc:before {
  content: "\EAD2";
}

.game-icon-cracked-glass:before {
  content: "\EAD3";
}

.game-icon-cracked-mask:before {
  content: "\EAD4";
}

.game-icon-cracked-saber:before {
  content: "\EAD5";
}

.game-icon-crossed-air-flows:before {
  content: "\EAD6";
}

.game-icon-crossed-slashes:before {
  content: "\EAD7";
}

.game-icon-earth-spit:before {
  content: "\EAD8";
}

.game-icon-eclipse-saw:before {
  content: "\EAD9";
}

.game-icon-edge-crack:before {
  content: "\EADA";
}

.game-icon-fangs-circle:before {
  content: "\EADB";
}

.game-icon-groundbreaker:before {
  content: "\EADC";
}

.game-icon-large-wound:before {
  content: "\EADD";
}

.game-icon-light-thorny-triskelion:before {
  content: "\EADE";
}

.game-icon-mantrap:before {
  content: "\EADF";
}

.game-icon-mighty-spanner:before {
  content: "\EAE0";
}

.game-icon-needle-jaws:before {
  content: "\EAE1";
}

.game-icon-quick-slash:before {
  content: "\EAE2";
}

.game-icon-rough-wound:before {
  content: "\EAE3";
}

.game-icon-serrated-slash:before {
  content: "\EAE4";
}

.game-icon-shatter:before {
  content: "\EAE5";
}

.game-icon-shattered-sword:before {
  content: "\EAE6";
}

.game-icon-spiked-armor:before {
  content: "\EAE7";
}

.game-icon-spiked-fence:before {
  content: "\EAE8";
}

.game-icon-spiked-shell:before {
  content: "\EAE9";
}

.game-icon-spiked-tail:before {
  content: "\EAEA";
}

.game-icon-spiked-tentacle:before {
  content: "\EAEB";
}

.game-icon-spiky-eclipse:before {
  content: "\EAEC";
}

.game-icon-spiky-explosion:before {
  content: "\EAED";
}

.game-icon-striking-diamonds:before {
  content: "\EAEE";
}

.game-icon-swan-breeze:before {
  content: "\EAEF";
}

.game-icon-swirl-ring:before {
  content: "\EAF0";
}

.game-icon-swirl-string:before {
  content: "\EAF1";
}

.game-icon-sword-break:before {
  content: "\EAF2";
}

.game-icon-tension-snowflake:before {
  content: "\EAF3";
}

.game-icon-tornado-discs:before {
  content: "\EAF4";
}

.game-icon-triple-claws:before {
  content: "\EAF5";
}

.game-icon-triple-needle:before {
  content: "\EAF6";
}

.game-icon-unstable-projectile:before {
  content: "\EAF7";
}

.game-icon-whiplash:before {
  content: "\EAF8";
}

.game-icon-wind-slap:before {
  content: "\EAF9";
}

.game-icon-wolf-trap:before {
  content: "\EAFA";
}

.game-icon-wrapped-heart:before {
  content: "\EAFB";
}

.game-icon-spikeball:before {
  content: "\EAFC";
}

.game-icon-spikes-full:before {
  content: "\EAFD";
}

.game-icon-spikes-half:before {
  content: "\EAFE";
}

.game-icon-spikes-init:before {
  content: "\EAFF";
}

.game-icon-spikes:before {
  content: "\EB00";
}

.game-icon-trigger-hurt:before {
  content: "\EB01";
}

.game-icon-bat-mask:before {
  content: "\EB02";
}

.game-icon-cadillac-helm:before {
  content: "\EB03";
}

.game-icon-carnival-mask:before {
  content: "\EB04";
}

.game-icon-ceremonial-mask:before {
  content: "\EB05";
}

.game-icon-cyborg-face:before {
  content: "\EB06";
}

.game-icon-jason-mask:before {
  content: "\EB07";
}

.game-icon-king-ju-mask:before {
  content: "\EB08";
}

.game-icon-luchador:before {
  content: "\EB09";
}

.game-icon-mighty-boosh:before {
  content: "\EB0A";
}

.game-icon-pharoah:before {
  content: "\EB0B";
}

.game-icon-protection-glasses:before {
  content: "\EB0C";
}

.game-icon-soul-vessel:before {
  content: "\EB0D";
}

.game-icon-spider-mask:before {
  content: "\EB0E";
}

.game-icon-vr-headset:before {
  content: "\EB0F";
}

.game-icon-android-mask:before {
  content: "\EB10";
}

.game-icon-architect-mask:before {
  content: "\EB11";
}

.game-icon-curly-mask:before {
  content: "\EB12";
}

.game-icon-domino-mask:before {
  content: "\EB13";
}

.game-icon-duality-mask:before {
  content: "\EB14";
}

.game-icon-lightning-mask:before {
  content: "\EB15";
}

.game-icon-trap-mask:before {
  content: "\EB16";
}

.game-icon-cultist-2:before {
  content: "\EB17";
}

.game-icon-gas-mask-2:before {
  content: "\EB18";
}

.game-icon-carambola:before {
  content: "\EB19";
}

.game-icon-falling-star:before {
  content: "\EB1A";
}

.game-icon-flower-star:before {
  content: "\EB1B";
}

.game-icon-galaxy:before {
  content: "\EB1C";
}

.game-icon-polar-star:before {
  content: "\EB1D";
}

.game-icon-round-star:before {
  content: "\EB1E";
}

.game-icon-seven-pointed-star:before {
  content: "\EB1F";
}

.game-icon-solar-system:before {
  content: "\EB20";
}

.game-icon-solar-time:before {
  content: "\EB21";
}

.game-icon-sparkles:before {
  content: "\EB22";
}

.game-icon-star-altar:before {
  content: "\EB23";
}

.game-icon-star-formation:before {
  content: "\EB24";
}

.game-icon-star-key:before {
  content: "\EB25";
}

.game-icon-star-medal:before {
  content: "\EB26";
}

.game-icon-stars-stack:before {
  content: "\EB27";
}

.game-icon-sun-cloud:before {
  content: "\EB28";
}

.game-icon-sunflower:before {
  content: "\EB29";
}

.game-icon-thermometer-hot:before {
  content: "\EB2A";
}

.game-icon-ursa-major:before {
  content: "\EB2B";
}

.game-icon-barbed-star:before {
  content: "\EB2C";
}

.game-icon-boomerang-sun:before {
  content: "\EB2D";
}

.game-icon-circle-sparks:before {
  content: "\EB2E";
}

.game-icon-cross-flare:before {
  content: "\EB2F";
}

.game-icon-eclipse-flare:before {
  content: "\EB30";
}

.game-icon-flat-star:before {
  content: "\EB31";
}

.game-icon-flexible-star:before {
  content: "\EB32";
}

.game-icon-lotus-flower:before {
  content: "\EB33";
}

.game-icon-orbital-rays:before {
  content: "\EB34";
}

.game-icon-rainbow-star:before {
  content: "\EB35";
}

.game-icon-shiny-iris:before {
  content: "\EB36";
}

.game-icon-star-cycle:before {
  content: "\EB37";
}

.game-icon-star-prominences:before {
  content: "\EB38";
}

.game-icon-star-pupil:before {
  content: "\EB39";
}

.game-icon-star-sattelites:before {
  content: "\EB3A";
}

.game-icon-star-swirl:before {
  content: "\EB3B";
}

.game-icon-sun-radiations:before {
  content: "\EB3C";
}

.game-icon-wrapping-star:before {
  content: "\EB3D";
}

.game-icon-splash-2:before {
  content: "\EB3E";
}

.game-icon-solar-power:before {
  content: "\EB3F";
}

.game-icon-on-sight:before {
  content: "\EB40";
}

.game-icon-convergence-target:before {
  content: "\EB41";
}

.game-icon-crosshair:before {
  content: "\EB42";
}

.game-icon-dice-target:before {
  content: "\EB43";
}

.game-icon-eye-target:before {
  content: "\EB44";
}

.game-icon-human-target:before {
  content: "\EB45";
}

.game-icon-multiple-targets:before {
  content: "\EB46";
}

.game-icon-target-poster:before {
  content: "\EB47";
}

.game-icon-triangle-target:before {
  content: "\EB48";
}

.game-icon-head-shot:before {
  content: "\EB49";
}

.game-icon-select:before {
  content: "\EB4A";
}

.game-icon-target-laser:before {
  content: "\EB4B";
}

.game-icon-targeted:before {
  content: "\EB4C";
}

.game-icon-agave:before {
  content: "\EB4D";
}

.game-icon-cactus-pot:before {
  content: "\EB4E";
}

.game-icon-cantua:before {
  content: "\EB4F";
}

.game-icon-carrot:before {
  content: "\EB50";
}

.game-icon-coffee-beans:before {
  content: "\EB51";
}

.game-icon-corn:before {
  content: "\EB52";
}

.game-icon-cotton-flower:before {
  content: "\EB53";
}

.game-icon-dandelion-flower:before {
  content: "\EB54";
}

.game-icon-fern:before {
  content: "\EB55";
}

.game-icon-fertilizer-bag:before {
  content: "\EB56";
}

.game-icon-flax:before {
  content: "\EB57";
}

.game-icon-garlic:before {
  content: "\EB58";
}

.game-icon-ginkgo-leaf:before {
  content: "\EB59";
}

.game-icon-grain-bundle:before {
  content: "\EB5A";
}

.game-icon-grain:before {
  content: "\EB5B";
}

.game-icon-green-power:before {
  content: "\EB5C";
}

.game-icon-hemp:before {
  content: "\EB5D";
}

.game-icon-hops:before {
  content: "\EB5E";
}

.game-icon-jasmine:before {
  content: "\EB5F";
}

.game-icon-monstera-leaf:before {
  content: "\EB60";
}

.game-icon-papyrus:before {
  content: "\EB61";
}

.game-icon-plant-roots:before {
  content: "\EB62";
}

.game-icon-plant-seed:before {
  content: "\EB63";
}

.game-icon-reed:before {
  content: "\EB64";
}

.game-icon-round-straw-bale:before {
  content: "\EB65";
}

.game-icon-seedling:before {
  content: "\EB66";
}

.game-icon-shamrock:before {
  content: "\EB67";
}

.game-icon-sugar-cane:before {
  content: "\EB68";
}

.game-icon-tumbleweed:before {
  content: "\EB69";
}

.game-icon-vines:before {
  content: "\EB6A";
}

.game-icon-aubergine:before {
  content: "\EB6B";
}

.game-icon-beanstalk:before {
  content: "\EB6C";
}

.game-icon-bud:before {
  content: "\EB6D";
}

.game-icon-bulb:before {
  content: "\EB6E";
}

.game-icon-curling-vines:before {
  content: "\EB6F";
}

.game-icon-daisy:before {
  content: "\EB70";
}

.game-icon-elderberry:before {
  content: "\EB71";
}

.game-icon-evil-bud:before {
  content: "\EB72";
}

.game-icon-flower-pot:before {
  content: "\EB73";
}

.game-icon-ground-sprout:before {
  content: "\EB74";
}

.game-icon-leaf-skeleton:before {
  content: "\EB75";
}

.game-icon-new-shoot:before {
  content: "\EB76";
}

.game-icon-oat:before {
  content: "\EB77";
}

.game-icon-pollen-dust:before {
  content: "\EB78";
}

.game-icon-rose:before {
  content: "\EB79";
}

.game-icon-shut-rose:before {
  content: "\EB7A";
}

.game-icon-spiral-bloom:before {
  content: "\EB7B";
}

.game-icon-spoted-flower:before {
  content: "\EB7C";
}

.game-icon-sprout-disc:before {
  content: "\EB7D";
}

.game-icon-sprout:before {
  content: "\EB7E";
}

.game-icon-trefoil-lily:before {
  content: "\EB7F";
}

.game-icon-twirly-flower:before {
  content: "\EB80";
}

.game-icon-vanilla-flower:before {
  content: "\EB81";
}

.game-icon-vine-flower:before {
  content: "\EB82";
}

.game-icon-vine-whip:before {
  content: "\EB83";
}

.game-icon-viola:before {
  content: "\EB84";
}

.game-icon-wheat:before {
  content: "\EB85";
}

.game-icon-poppy:before {
  content: "\EB86";
}

.game-icon-backpack:before {
  content: "\EB87";
}

.game-icon-beach-bag:before {
  content: "\EB88";
}

.game-icon-briefcase:before {
  content: "\EB89";
}

.game-icon-chips-bag:before {
  content: "\EB8A";
}

.game-icon-disc-golf-bag:before {
  content: "\EB8B";
}

.game-icon-duffel-bag:before {
  content: "\EB8C";
}

.game-icon-flour:before {
  content: "\EB8D";
}

.game-icon-gym-bag:before {
  content: "\EB8E";
}

.game-icon-hand-bag:before {
  content: "\EB8F";
}

.game-icon-light-backpack:before {
  content: "\EB90";
}

.game-icon-powder-bag:before {
  content: "\EB91";
}

.game-icon-school-bag:before {
  content: "\EB92";
}

.game-icon-shopping-bag:before {
  content: "\EB93";
}

.game-icon-suitcase:before {
  content: "\EB94";
}

.game-icon-knapsack:before {
  content: "\EB95";
}

.game-icon-shiny-purse:before {
  content: "\EB96";
}

.game-icon-swap-bag:before {
  content: "\EB97";
}

.game-icon-coins-pile:before {
  content: "\EB98";
}

.game-icon-gold-nuggets:before {
  content: "\EB99";
}

.game-icon-money-stack:before {
  content: "\EB9A";
}

.game-icon-piggy-bank:before {
  content: "\EB9B";
}

.game-icon-price-tag:before {
  content: "\EB9C";
}

.game-icon-swipe-card:before {
  content: "\EB9D";
}

.game-icon-ticket:before {
  content: "\EB9E";
}

.game-icon-wallet:before {
  content: "\EB9F";
}

.game-icon-knee-cap:before {
  content: "\EBA0";
}

.game-icon-pelvis-bone:before {
  content: "\EBA1";
}

.game-icon-backbone-shell:before {
  content: "\EBA2";
}

.game-icon-crossed-bones:before {
  content: "\EBA3";
}

.game-icon-horn-internal:before {
  content: "\EBA4";
}

.game-icon-jawbone:before {
  content: "\EBA5";
}

.game-icon-ribcage:before {
  content: "\EBA6";
}

.game-icon-spinal-coil:before {
  content: "\EBA7";
}

.game-icon-joint:before {
  content: "\EBA8";
}

.game-icon-swirled-shell:before {
  content: "\EBA9";
}

.game-icon-bolt-bomb:before {
  content: "\EBAA";
}

.game-icon-dynamite:before {
  content: "\EBAB";
}

.game-icon-falling-bomb:before {
  content: "\EBAC";
}

.game-icon-holy-hand-grenade:before {
  content: "\EBAD";
}

.game-icon-time-dynamite:before {
  content: "\EBAE";
}

.game-icon-cluster-bomb:before {
  content: "\EBAF";
}

.game-icon-fission:before {
  content: "\EBB0";
}

.game-icon-land-mine:before {
  content: "\EBB1";
}

.game-icon-letter-bomb:before {
  content: "\EBB2";
}

.game-icon-paper-bomb:before {
  content: "\EBB3";
}

.game-icon-rolling-bomb:before {
  content: "\EBB4";
}

.game-icon-sparky-bomb:before {
  content: "\EBB5";
}

.game-icon-squib:before {
  content: "\EBB6";
}

.game-icon-time-bomb:before {
  content: "\EBB7";
}

.game-icon-unlit-bomb:before {
  content: "\EBB8";
}

.game-icon-bundle-grenade:before {
  content: "\EBB9";
}

.game-icon-minefield:before {
  content: "\EBBA";
}

.game-icon-ballerina-shoes:before {
  content: "\EBBB";
}

.game-icon-banana-peel:before {
  content: "\EBBC";
}

.game-icon-chelsea-boot:before {
  content: "\EBBD";
}

.game-icon-converse-shoe:before {
  content: "\EBBE";
}

.game-icon-cowboy-boot:before {
  content: "\EBBF";
}

.game-icon-female-legs:before {
  content: "\EBC0";
}

.game-icon-flip-flops:before {
  content: "\EBC1";
}

.game-icon-hieroglyph-legs:before {
  content: "\EBC2";
}

.game-icon-high-heel:before {
  content: "\EBC3";
}

.game-icon-leg-armor:before {
  content: "\EBC4";
}

.game-icon-leg:before {
  content: "\EBC5";
}

.game-icon-metal-boot:before {
  content: "\EBC6";
}

.game-icon-roller-skate:before {
  content: "\EBC7";
}

.game-icon-rubber-boot:before {
  content: "\EBC8";
}

.game-icon-running-shoe:before {
  content: "\EBC9";
}

.game-icon-slippers:before {
  content: "\EBCA";
}

.game-icon-socks:before {
  content: "\EBCB";
}

.game-icon-sonic-shoes:before {
  content: "\EBCC";
}

.game-icon-sticky-boot:before {
  content: "\EBCD";
}

.game-icon-barefoot:before {
  content: "\EBCE";
}

.game-icon-boot-prints:before {
  content: "\EBCF";
}

.game-icon-boots:before {
  content: "\EBD0";
}

.game-icon-foot-trip:before {
  content: "\EBD1";
}

.game-icon-footprint:before {
  content: "\EBD2";
}

.game-icon-leather-boot:before {
  content: "\EBD3";
}

.game-icon-nailed-foot:before {
  content: "\EBD4";
}

.game-icon-quake-stomp:before {
  content: "\EBD5";
}

.game-icon-tread:before {
  content: "\EBD6";
}

.game-icon-tripwire:before {
  content: "\EBD7";
}

.game-icon-walking-boot:before {
  content: "\EBD8";
}

.game-icon-beet:before {
  content: "\EBD9";
}

.game-icon-super-mushroom:before {
  content: "\EBDA";
}

.game-icon-minerals:before {
  content: "\EBDB";
}

.game-icon-ore:before {
  content: "\EBDC";
}

.game-icon-crystal-shrine:before {
  content: "\EBDD";
}

.game-icon-diamond-trophy:before {
  content: "\EBDE";
}

.game-icon-mineral-pearls:before {
  content: "\EBDF";
}

.game-icon-checkered-diamond:before {
  content: "\EBE0";
}

.game-icon-crystal-bars:before {
  content: "\EBE1";
}

.game-icon-crystal-cluster:before {
  content: "\EBE2";
}

.game-icon-crystal-eye:before {
  content: "\EBE3";
}

.game-icon-crystal-shine:before {
  content: "\EBE4";
}

.game-icon-diamond-hard:before {
  content: "\EBE5";
}

.game-icon-floating-crystal:before {
  content: "\EBE6";
}

.game-icon-gems:before {
  content: "\EBE7";
}

.game-icon-mineral-heart:before {
  content: "\EBE8";
}

.game-icon-fossil:before {
  content: "\EBE9";
}

.game-icon-gold-shell:before {
  content: "\EBEA";
}

.game-icon-scallop:before {
  content: "\EBEB";
}

.game-icon-sewed-shell:before {
  content: "\EBEC";
}

.game-icon-spiral-shell:before {
  content: "\EBED";
}

.game-icon-triple-shells:before {
  content: "\EBEE";
}

.game-icon-twin-shell:before {
  content: "\EBEF";
}

.game-icon-asteroid:before {
  content: "\EBF0";
}

.game-icon-colombian-statue:before {
  content: "\EBF1";
}

.game-icon-dig-hole:before {
  content: "\EBF2";
}

.game-icon-falling-rocks:before {
  content: "\EBF3";
}

.game-icon-stone-bridge:before {
  content: "\EBF4";
}

.game-icon-stone-pile:before {
  content: "\EBF5";
}

.game-icon-bridge:before {
  content: "\EBF6";
}

.game-icon-crags:before {
  content: "\EBF7";
}

.game-icon-meteor-impact:before {
  content: "\EBF8";
}

.game-icon-rune-stone:before {
  content: "\EBF9";
}

.game-icon-stone-block:before {
  content: "\EBFA";
}

.game-icon-stone-sphere:before {
  content: "\EBFB";
}

.game-icon-stone-throne:before {
  content: "\EBFC";
}

.game-icon-bow-tie-ribbon:before {
  content: "\EBFD";
}

.game-icon-champagne-cork:before {
  content: "\EBFE";
}

.game-icon-champions:before {
  content: "\EBFF";
}

.game-icon-diploma:before {
  content: "\EC00";
}

.game-icon-family-tree:before {
  content: "\EC01";
}

.game-icon-finish-line:before {
  content: "\EC02";
}

.game-icon-histogram:before {
  content: "\EC03";
}

.game-icon-laurels-trophy:before {
  content: "\EC04";
}

.game-icon-network-bars:before {
  content: "\EC05";
}

.game-icon-organigram:before {
  content: "\EC06";
}

.game-icon-podium-second:before {
  content: "\EC07";
}

.game-icon-podium-third:before {
  content: "\EC08";
}

.game-icon-podium-winner:before {
  content: "\EC09";
}

.game-icon-podium:before {
  content: "\EC0A";
}

.game-icon-private-first-class:before {
  content: "\EC0B";
}

.game-icon-private:before {
  content: "\EC0C";
}

.game-icon-ribbon-medal:before {
  content: "\EC0D";
}

.game-icon-sergeant:before {
  content: "\EC0E";
}

.game-icon-sport-medal:before {
  content: "\EC0F";
}

.game-icon-trophies-shelf:before {
  content: "\EC10";
}

.game-icon-trophy-cup:before {
  content: "\EC11";
}

.game-icon-level-four-advanced:before {
  content: "\EC12";
}

.game-icon-level-four:before {
  content: "\EC13";
}

.game-icon-level-three-advanced:before {
  content: "\EC14";
}

.game-icon-level-three:before {
  content: "\EC15";
}

.game-icon-level-two-advanced:before {
  content: "\EC16";
}

.game-icon-level-two:before {
  content: "\EC17";
}

.game-icon-achievement:before {
  content: "\EC18";
}

.game-icon-rank-1:before {
  content: "\EC19";
}

.game-icon-rank-2:before {
  content: "\EC1A";
}

.game-icon-rank-3:before {
  content: "\EC1B";
}

.game-icon-3d-hammer:before {
  content: "\EC1C";
}

.game-icon-aspergillum:before {
  content: "\EC1D";
}

.game-icon-box-cutter:before {
  content: "\EC1E";
}

.game-icon-broom:before {
  content: "\EC1F";
}

.game-icon-clamp:before {
  content: "\EC20";
}

.game-icon-coa-de-jima:before {
  content: "\EC21";
}

.game-icon-crook-flail:before {
  content: "\EC22";
}

.game-icon-gardening-shears:before {
  content: "\EC23";
}

.game-icon-hair-strands:before {
  content: "\EC24";
}

.game-icon-hammer-break:before {
  content: "\EC25";
}

.game-icon-hole-ladder:before {
  content: "\EC26";
}

.game-icon-hook:before {
  content: "\EC27";
}

.game-icon-injustice:before {
  content: "\EC28";
}

.game-icon-ladders-platform:before {
  content: "\EC29";
}

.game-icon-large-paint-brush:before {
  content: "\EC2A";
}

.game-icon-lockpicks:before {
  content: "\EC2B";
}

.game-icon-paint-brush:before {
  content: "\EC2C";
}

.game-icon-paint-bucket:before {
  content: "\EC2D";
}

.game-icon-paint-roller:before {
  content: "\EC2E";
}

.game-icon-plow:before {
  content: "\EC2F";
}

.game-icon-plunger:before {
  content: "\EC30";
}

.game-icon-rake:before {
  content: "\EC31";
}

.game-icon-shears:before {
  content: "\EC32";
}

.game-icon-swiss-army-knife:before {
  content: "\EC33";
}

.game-icon-toy-mallet:before {
  content: "\EC34";
}

.game-icon-trowel:before {
  content: "\EC35";
}

.game-icon-weight:before {
  content: "\EC36";
}

.game-icon-claw-hammer:before {
  content: "\EC37";
}

.game-icon-dig-dug:before {
  content: "\EC38";
}

.game-icon-fishhook-fork:before {
  content: "\EC39";
}

.game-icon-flat-hammer:before {
  content: "\EC3A";
}

.game-icon-gavel:before {
  content: "\EC3B";
}

.game-icon-gear-hammer:before {
  content: "\EC3C";
}

.game-icon-hammer-drop:before {
  content: "\EC3D";
}

.game-icon-hammer-nails:before {
  content: "\EC3E";
}

.game-icon-lightning-spanner:before {
  content: "\EC3F";
}

.game-icon-scissors-2:before {
  content: "\EC40";
}

.game-icon-screwdriver:before {
  content: "\EC41";
}

.game-icon-sewing-needle:before {
  content: "\EC42";
}

.game-icon-spade:before {
  content: "\EC43";
}

.game-icon-spanner:before {
  content: "\EC44";
}

.game-icon-sword-smithing:before {
  content: "\EC45";
}

.game-icon-thermometer-scale:before {
  content: "\EC46";
}

.game-icon-tinker:before {
  content: "\EC47";
}

.game-icon-trample:before {
  content: "\EC48";
}

.game-icon-wrench:before {
  content: "\EC49";
}

.game-icon-pause-button:before {
  content: "\EC4A";
}

.game-icon-play-button:before {
  content: "\EC4B";
}

.game-icon-abacus:before {
  content: "\EC4C";
}

.game-icon-calendar:before {
  content: "\EC4D";
}

.game-icon-checklist:before {
  content: "\EC4E";
}

.game-icon-circle:before {
  content: "\EC4F";
}

.game-icon-clapperboard:before {
  content: "\EC50";
}

.game-icon-confirmed:before {
  content: "\EC51";
}

.game-icon-cube:before {
  content: "\EC52";
}

.game-icon-fast-backward-button:before {
  content: "\EC53";
}

.game-icon-fast-forward-button:before {
  content: "\EC54";
}

.game-icon-files:before {
  content: "\EC55";
}

.game-icon-film-projector:before {
  content: "\EC56";
}

.game-icon-full-folder:before {
  content: "\EC57";
}

.game-icon-funnel:before {
  content: "\EC58";
}

.game-icon-gamepad:before {
  content: "\EC59";
}

.game-icon-hamburger-menu:before {
  content: "\EC5A";
}

.game-icon-highlighter:before {
  content: "\EC5B";
}

.game-icon-joystick:before {
  content: "\EC5C";
}

.game-icon-keyboard:before {
  content: "\EC5D";
}

.game-icon-load:before {
  content: "\EC5E";
}

.game-icon-mailbox:before {
  content: "\EC5F";
}

.game-icon-mouse-2:before {
  content: "\EC60";
}

.game-icon-newspaper:before {
  content: "\EC61";
}

.game-icon-next-button:before {
  content: "\EC62";
}

.game-icon-open-folder:before {
  content: "\EC63";
}

.game-icon-palette:before {
  content: "\EC64";
}

.game-icon-pencil-brush:before {
  content: "\EC65";
}

.game-icon-pencil:before {
  content: "\EC66";
}

.game-icon-photo-camera:before {
  content: "\EC67";
}

.game-icon-pie-chart:before {
  content: "\EC68";
}

.game-icon-pin:before {
  content: "\EC69";
}

.game-icon-plain-circle:before {
  content: "\EC6A";
}

.game-icon-plain-square:before {
  content: "\EC6B";
}

.game-icon-previous-button:before {
  content: "\EC6C";
}

.game-icon-resize:before {
  content: "\EC6D";
}

.game-icon-save:before {
  content: "\EC6E";
}

.game-icon-share:before {
  content: "\EC6F";
}

.game-icon-shopping-cart:before {
  content: "\EC70";
}

.game-icon-square:before {
  content: "\EC71";
}

.game-icon-toggles:before {
  content: "\EC72";
}

.game-icon-trash-can:before {
  content: "\EC73";
}

.game-icon-unbalanced:before {
  content: "\EC74";
}

.game-icon-vibrating-smartphone:before {
  content: "\EC75";
}

.game-icon-video-camera:before {
  content: "\EC76";
}

.game-icon-bookmark:before {
  content: "\EC77";
}

.game-icon-envelope:before {
  content: "\EC78";
}

.game-icon-open-book:before {
  content: "\EC79";
}

.game-icon-retro-controller:before {
  content: "\EC7A";
}

.game-icon-smartphone:before {
  content: "\EC7B";
}

.game-icon-tablet:before {
  content: "\EC7C";
}

.game-icon-soda-can:before {
  content: "\EC7D";
}

.game-icon-baby-bottle:before {
  content: "\EC7E";
}

.game-icon-beer-bottle:before {
  content: "\EC7F";
}

.game-icon-delicate-perfume:before {
  content: "\EC80";
}

.game-icon-ketchup:before {
  content: "\EC81";
}

.game-icon-water-flask:before {
  content: "\EC82";
}

.game-icon-wine-bottle:before {
  content: "\EC83";
}

.game-icon-bottled-bolt:before {
  content: "\EC84";
}

.game-icon-brandy-bottle:before {
  content: "\EC85";
}

.game-icon-bubbling-flask:before {
  content: "\EC86";
}

.game-icon-perfume-bottle:before {
  content: "\EC87";
}

.game-icon-potion-ball:before {
  content: "\EC88";
}

.game-icon-round-bottom-flask:before {
  content: "\EC89";
}

.game-icon-spiral-bottle:before {
  content: "\EC8A";
}

.game-icon-square-bottle:before {
  content: "\EC8B";
}

.game-icon-standing-potion:before {
  content: "\EC8C";
}

.game-icon-potion-of-madness:before {
  content: "\EC8D";
}

.game-icon-almond:before {
  content: "\EC8E";
}

.game-icon-asparagus:before {
  content: "\EC8F";
}

.game-icon-avocado:before {
  content: "\EC90";
}

.game-icon-banana:before {
  content: "\EC91";
}

.game-icon-bell-pepper:before {
  content: "\EC92";
}

.game-icon-bread-slice:before {
  content: "\EC93";
}

.game-icon-bread:before {
  content: "\EC94";
}

.game-icon-butter-toast:before {
  content: "\EC95";
}

.game-icon-cabbage:before {
  content: "\EC96";
}

.game-icon-candy-canes:before {
  content: "\EC97";
}

.game-icon-cherry:before {
  content: "\EC98";
}

.game-icon-chili-pepper:before {
  content: "\EC99";
}

.game-icon-cookie:before {
  content: "\EC9A";
}

.game-icon-croissant:before {
  content: "\EC9B";
}

.game-icon-cupcake:before {
  content: "\EC9C";
}

.game-icon-dango:before {
  content: "\EC9D";
}

.game-icon-doner-kebab:before {
  content: "\EC9E";
}

.game-icon-fast-noodles:before {
  content: "\EC9F";
}

.game-icon-french-fries:before {
  content: "\ECA0";
}

.game-icon-full-pizza:before {
  content: "\ECA1";
}

.game-icon-gingerbread-man:before {
  content: "\ECA2";
}

.game-icon-hamburger:before {
  content: "\ECA3";
}

.game-icon-hot-dog:before {
  content: "\ECA4";
}

.game-icon-hot-meal:before {
  content: "\ECA5";
}

.game-icon-jelly-beans:before {
  content: "\ECA6";
}

.game-icon-jelly:before {
  content: "\ECA7";
}

.game-icon-kiwi-fruit:before {
  content: "\ECA8";
}

.game-icon-mushrooms:before {
  content: "\ECA9";
}

.game-icon-nachos:before {
  content: "\ECAA";
}

.game-icon-noodles:before {
  content: "\ECAB";
}

.game-icon-oden:before {
  content: "\ECAC";
}

.game-icon-pear:before {
  content: "\ECAD";
}

.game-icon-pickle:before {
  content: "\ECAE";
}

.game-icon-pimiento:before {
  content: "\ECAF";
}

.game-icon-pizza-slice:before {
  content: "\ECB0";
}

.game-icon-popcorn:before {
  content: "\ECB1";
}

.game-icon-potato:before {
  content: "\ECB2";
}

.game-icon-pretzel:before {
  content: "\ECB3";
}

.game-icon-raw-egg:before {
  content: "\ECB4";
}

.game-icon-sandwich:before {
  content: "\ECB5";
}

.game-icon-sesame:before {
  content: "\ECB6";
}

.game-icon-sliced-mushroom:before {
  content: "\ECB7";
}

.game-icon-spiral-lollipop:before {
  content: "\ECB8";
}

.game-icon-stairs-cake:before {
  content: "\ECB9";
}

.game-icon-stomach:before {
  content: "\ECBA";
}

.game-icon-tacos:before {
  content: "\ECBB";
}

.game-icon-tangerine:before {
  content: "\ECBC";
}

.game-icon-tomato:before {
  content: "\ECBD";
}

.game-icon-cake-slice:before {
  content: "\ECBE";
}

.game-icon-cheese-wedge:before {
  content: "\ECBF";
}

.game-icon-cool-spices:before {
  content: "\ECC0";
}

.game-icon-honeypot:before {
  content: "\ECC1";
}

.game-icon-meat-hook:before {
  content: "\ECC2";
}

.game-icon-mushroom-gills:before {
  content: "\ECC3";
}

.game-icon-mushroom:before {
  content: "\ECC4";
}

.game-icon-pie-slice:before {
  content: "\ECC5";
}

.game-icon-powder:before {
  content: "\ECC6";
}

.game-icon-salt-shaker:before {
  content: "\ECC7";
}

.game-icon-shiny-apple:before {
  content: "\ECC8";
}

.game-icon-sliced-bread:before {
  content: "\ECC9";
}

.game-icon-wrapped-sweet:before {
  content: "\ECCA";
}

.game-icon-chocolate-bar:before {
  content: "\ECCB";
}

.game-icon-peanut:before {
  content: "\ECCC";
}

.game-icon-armored-pants:before {
  content: "\ECCD";
}

.game-icon-abdominal-armor:before {
  content: "\ECCE";
}

.game-icon-american-football-player:before {
  content: "\ECCF";
}

.game-icon-bracer:before {
  content: "\ECD0";
}

.game-icon-cape-armor:before {
  content: "\ECD1";
}

.game-icon-chest-armor:before {
  content: "\ECD2";
}

.game-icon-elbow-pad:before {
  content: "\ECD3";
}

.game-icon-heart-armor:before {
  content: "\ECD4";
}

.game-icon-knee-pad:before {
  content: "\ECD5";
}

.game-icon-leather-armor:before {
  content: "\ECD6";
}

.game-icon-loincloth:before {
  content: "\ECD7";
}

.game-icon-metal-skirt:before {
  content: "\ECD8";
}

.game-icon-morph-ball:before {
  content: "\ECD9";
}

.game-icon-saiyan-suit:before {
  content: "\ECDA";
}

.game-icon-armor-vest:before {
  content: "\ECDB";
}

.game-icon-breastplate:before {
  content: "\ECDC";
}

.game-icon-dorsal-scales:before {
  content: "\ECDD";
}

.game-icon-lamellar:before {
  content: "\ECDE";
}

.game-icon-leather-vest:before {
  content: "\ECDF";
}

.game-icon-mail-shirt:before {
  content: "\ECE0";
}

.game-icon-plastron:before {
  content: "\ECE1";
}

.game-icon-scale-mail:before {
  content: "\ECE2";
}

.game-icon-shoulder-scales:before {
  content: "\ECE3";
}

.game-icon-space-suit:before {
  content: "\ECE4";
}

.game-icon-bracers:before {
  content: "\ECE5";
}

.game-icon-chain-mail:before {
  content: "\ECE6";
}

.game-icon-ample-dress:before {
  content: "\ECE7";
}

.game-icon-basketball-jersey:before {
  content: "\ECE8";
}

.game-icon-bow-tie:before {
  content: "\ECE9";
}

.game-icon-cape:before {
  content: "\ECEA";
}

.game-icon-clothes:before {
  content: "\ECEB";
}

.game-icon-clothespin:before {
  content: "\ECEC";
}

.game-icon-hanger:before {
  content: "\ECED";
}

.game-icon-heavy-collar:before {
  content: "\ECEE";
}

.game-icon-hoodie:before {
  content: "\ECEF";
}

.game-icon-lab-coat:before {
  content: "\ECF0";
}

.game-icon-large-dress:before {
  content: "\ECF1";
}

.game-icon-moncler-jacket:before {
  content: "\ECF2";
}

.game-icon-pirate-coat:before {
  content: "\ECF3";
}

.game-icon-polo-shirt:before {
  content: "\ECF4";
}

.game-icon-poncho:before {
  content: "\ECF5";
}

.game-icon-rolled-cloth:before {
  content: "\ECF6";
}

.game-icon-sewing-string:before {
  content: "\ECF7";
}

.game-icon-shirt-button:before {
  content: "\ECF8";
}

.game-icon-shorts:before {
  content: "\ECF9";
}

.game-icon-skirt:before {
  content: "\ECFA";
}

.game-icon-sleeveless-jacket:before {
  content: "\ECFB";
}

.game-icon-sleeveless-top:before {
  content: "\ECFC";
}

.game-icon-t-shirt:before {
  content: "\ECFD";
}

.game-icon-tank-top:before {
  content: "\ECFE";
}

.game-icon-tie:before {
  content: "\ECFF";
}

.game-icon-travel-dress:before {
  content: "\ED00";
}

.game-icon-underwear-shorts:before {
  content: "\ED01";
}

.game-icon-underwear:before {
  content: "\ED02";
}

.game-icon-washing-machine:before {
  content: "\ED03";
}

.game-icon-belt-buckles:before {
  content: "\ED04";
}

.game-icon-trousers:before {
  content: "\ED05";
}

.game-icon-belt:before {
  content: "\ED06";
}

.game-icon-cloak:before {
  content: "\ED07";
}

.game-icon-shirt:before {
  content: "\ED08";
}

.game-icon-trousers-2:before {
  content: "\ED09";
}

.game-icon-dress:before {
  content: "\ED0A";
}

.game-icon-bullet-impacts:before {
  content: "\ED0B";
}

.game-icon-cowboy-holster:before {
  content: "\ED0C";
}

.game-icon-gun-stock:before {
  content: "\ED0D";
}

.game-icon-machine-gun-magazine:before {
  content: "\ED0E";
}

.game-icon-pirate-cannon:before {
  content: "\ED0F";
}

.game-icon-shotgun-rounds:before {
  content: "\ED10";
}

.game-icon-ak47:before {
  content: "\ED11";
}

.game-icon-ak47u:before {
  content: "\ED12";
}

.game-icon-colt-m1911:before {
  content: "\ED13";
}

.game-icon-cz-skorpion:before {
  content: "\ED14";
}

.game-icon-desert-eagle:before {
  content: "\ED15";
}

.game-icon-fn-fal:before {
  content: "\ED16";
}

.game-icon-m3-grease-gun:before {
  content: "\ED17";
}

.game-icon-mac-10:before {
  content: "\ED18";
}

.game-icon-machine-gun:before {
  content: "\ED19";
}

.game-icon-mp5-2:before {
  content: "\ED1A";
}

.game-icon-mp5k:before {
  content: "\ED1B";
}

.game-icon-musket:before {
  content: "\ED1C";
}

.game-icon-p90:before {
  content: "\ED1D";
}

.game-icon-revolver-2:before {
  content: "\ED1E";
}

.game-icon-spectre-m4:before {
  content: "\ED1F";
}

.game-icon-thompson-m1928:before {
  content: "\ED20";
}

.game-icon-winchester-rifle:before {
  content: "\ED21";
}

.game-icon-3d-glasses:before {
  content: "\ED22";
}

.game-icon-director-chair:before {
  content: "\ED23";
}

.game-icon-inauguration:before {
  content: "\ED24";
}

.game-icon-red-carpet:before {
  content: "\ED25";
}

.game-icon-theater:before {
  content: "\ED26";
}

.game-icon-cyber-eye:before {
  content: "\ED27";
}

.game-icon-egg-eye:before {
  content: "\ED28";
}

.game-icon-eyelashes:before {
  content: "\ED29";
}

.game-icon-hunter-eyes:before {
  content: "\ED2A";
}

.game-icon-lock-spy:before {
  content: "\ED2B";
}

.game-icon-tired-eye:before {
  content: "\ED2C";
}

.game-icon-bolt-eye:before {
  content: "\ED2D";
}

.game-icon-brass-eye:before {
  content: "\ED2E";
}

.game-icon-croissants-pupil:before {
  content: "\ED2F";
}

.game-icon-eyeball:before {
  content: "\ED30";
}

.game-icon-falling-eye:before {
  content: "\ED31";
}

.game-icon-lightning-tear:before {
  content: "\ED32";
}

.game-icon-octogonal-eye:before {
  content: "\ED33";
}

.game-icon-semi-closed-eye:before {
  content: "\ED34";
}

.game-icon-sheikah-eye:before {
  content: "\ED35";
}

.game-icon-six-eyes:before {
  content: "\ED36";
}

.game-icon-sunken-eye:before {
  content: "\ED37";
}

.game-icon-surrounded-eye:before {
  content: "\ED38";
}

.game-icon-templar-eye:before {
  content: "\ED39";
}

.game-icon-third-eye:before {
  content: "\ED3A";
}

.game-icon-evil-eyes:before {
  content: "\ED3B";
}

.game-icon-sight-disabled:before {
  content: "\ED3C";
}

.game-icon-glass-ball:before {
  content: "\ED3D";
}

.game-icon-lightning-dome:before {
  content: "\ED3E";
}

.game-icon-corked-tube:before {
  content: "\ED3F";
}

.game-icon-crystal-ball:before {
  content: "\ED40";
}

.game-icon-dragon-balls:before {
  content: "\ED41";
}

.game-icon-empty-hourglass:before {
  content: "\ED42";
}

.game-icon-glass-heart:before {
  content: "\ED43";
}

.game-icon-test-tubes:before {
  content: "\ED44";
}

.game-icon-babyfoot-players:before {
  content: "\ED45";
}

.game-icon-balloon-dog:before {
  content: "\ED46";
}

.game-icon-beach-ball:before {
  content: "\ED47";
}

.game-icon-game-console:before {
  content: "\ED48";
}

.game-icon-kite:before {
  content: "\ED49";
}

.game-icon-marbles:before {
  content: "\ED4A";
}

.game-icon-paper-plane:before {
  content: "\ED4B";
}

.game-icon-paper-windmill:before {
  content: "\ED4C";
}

.game-icon-pendulum-swing:before {
  content: "\ED4D";
}

.game-icon-pinball-flipper:before {
  content: "\ED4E";
}

.game-icon-ping-pong-bat:before {
  content: "\ED4F";
}

.game-icon-puzzle:before {
  content: "\ED50";
}

.game-icon-skipping-rope:before {
  content: "\ED51";
}

.game-icon-soccer-ball:before {
  content: "\ED52";
}

.game-icon-balloons:before {
  content: "\ED53";
}

.game-icon-jigsaw-piece:before {
  content: "\ED54";
}

.game-icon-voodoo-doll:before {
  content: "\ED55";
}

.game-icon-console-controller:before {
  content: "\ED56";
}

.game-icon-spinning-top:before {
  content: "\ED57";
}

.game-icon-bar-stool:before {
  content: "\ED58";
}

.game-icon-bathtub:before {
  content: "\ED59";
}

.game-icon-bed:before {
  content: "\ED5A";
}

.game-icon-bookshelf:before {
  content: "\ED5B";
}

.game-icon-bunk-beds:before {
  content: "\ED5C";
}

.game-icon-cuckoo-clock:before {
  content: "\ED5D";
}

.game-icon-desk:before {
  content: "\ED5E";
}

.game-icon-door-handle:before {
  content: "\ED5F";
}

.game-icon-door:before {
  content: "\ED60";
}

.game-icon-electrical-socket:before {
  content: "\ED61";
}

.game-icon-laptop:before {
  content: "\ED62";
}

.game-icon-lipstick:before {
  content: "\ED63";
}

.game-icon-lockers:before {
  content: "\ED64";
}

.game-icon-magic-broom:before {
  content: "\ED65";
}

.game-icon-office-chair:before {
  content: "\ED66";
}

.game-icon-paper-clip:before {
  content: "\ED67";
}

.game-icon-party-popper:before {
  content: "\ED68";
}

.game-icon-person-in-bed:before {
  content: "\ED69";
}

.game-icon-pillow:before {
  content: "\ED6A";
}

.game-icon-rocking-chair:before {
  content: "\ED6B";
}

.game-icon-soap:before {
  content: "\ED6C";
}

.game-icon-sofa:before {
  content: "\ED6D";
}

.game-icon-towel:before {
  content: "\ED6E";
}

.game-icon-weight-scale:before {
  content: "\ED6F";
}

.game-icon-wifi-router:before {
  content: "\ED70";
}

.game-icon-wooden-chair:before {
  content: "\ED71";
}

.game-icon-fountain-pen:before {
  content: "\ED72";
}

.game-icon-papers:before {
  content: "\ED73";
}

.game-icon-quill-ink:before {
  content: "\ED74";
}

.game-icon-tied-scroll:before {
  content: "\ED75";
}

.game-icon-calculator:before {
  content: "\ED76";
}

.game-icon-cctv-camera:before {
  content: "\ED77";
}

.game-icon-charging:before {
  content: "\ED78";
}

.game-icon-cpu:before {
  content: "\ED79";
}

.game-icon-electrical-resistance:before {
  content: "\ED7A";
}

.game-icon-server-rack:before {
  content: "\ED7B";
}

.game-icon-smartphone-2:before {
  content: "\ED7C";
}

.game-icon-spark-plug:before {
  content: "\ED7D";
}

.game-icon-tv-remote:before {
  content: "\ED7E";
}

.game-icon-usb-key:before {
  content: "\ED7F";
}

.game-icon-watch:before {
  content: "\ED80";
}

.game-icon-batteries:before {
  content: "\ED81";
}

.game-icon-cpu-shot:before {
  content: "\ED82";
}

.game-icon-disc:before {
  content: "\ED83";
}

.game-icon-lightning-arc:before {
  content: "\ED84";
}

.game-icon-ram-2:before {
  content: "\ED85";
}

.game-icon-pc:before {
  content: "\ED86";
}

.game-icon-fog:before {
  content: "\ED87";
}

.game-icon-windsock:before {
  content: "\ED88";
}

.game-icon-cloud-ring:before {
  content: "\ED89";
}

.game-icon-dust-cloud:before {
  content: "\ED8A";
}

.game-icon-flower-twirl:before {
  content: "\ED8B";
}

.game-icon-fluffy-cloud:before {
  content: "\ED8C";
}

.game-icon-half-tornado:before {
  content: "\ED8D";
}

.game-icon-lightning-storm:before {
  content: "\ED8E";
}

.game-icon-lightning-tree:before {
  content: "\ED8F";
}

.game-icon-moon:before {
  content: "\ED90";
}

.game-icon-night-sky:before {
  content: "\ED91";
}

.game-icon-parachute:before {
  content: "\ED92";
}

.game-icon-raining:before {
  content: "\ED93";
}

.game-icon-static:before {
  content: "\ED94";
}

.game-icon-stomp-tornado:before {
  content: "\ED95";
}

.game-icon-tornado:before {
  content: "\ED96";
}

.game-icon-twirl-center:before {
  content: "\ED97";
}

.game-icon-twister:before {
  content: "\ED98";
}

.game-icon-whirlwind:before {
  content: "\ED99";
}

.game-icon-battle-mech:before {
  content: "\ED9A";
}

.game-icon-factory-arm:before {
  content: "\ED9B";
}

.game-icon-magnet-man:before {
  content: "\ED9C";
}

.game-icon-mono-wheel-robot:before {
  content: "\ED9D";
}

.game-icon-nano-bot:before {
  content: "\ED9E";
}

.game-icon-robot-grab:before {
  content: "\ED9F";
}

.game-icon-robot-leg:before {
  content: "\EDA0";
}

.game-icon-spider-bot:before {
  content: "\EDA1";
}

.game-icon-megabot:before {
  content: "\EDA2";
}

.game-icon-techno-heart:before {
  content: "\EDA3";
}

.game-icon-vintage-robot:before {
  content: "\EDA4";
}

.game-icon-bus-doors:before {
  content: "\EDA5";
}

.game-icon-door-ring-handle:before {
  content: "\EDA6";
}

.game-icon-door-watcher:before {
  content: "\EDA7";
}

.game-icon-heaven-gate:before {
  content: "\EDA8";
}

.game-icon-hobbit-door:before {
  content: "\EDA9";
}

.game-icon-india-gate:before {
  content: "\EDAA";
}

.game-icon-locked-door:before {
  content: "\EDAB";
}

.game-icon-open-gate:before {
  content: "\EDAC";
}

.game-icon-ranch-gate:before {
  content: "\EDAD";
}

.game-icon-temple-door:before {
  content: "\EDAE";
}

.game-icon-turnstile:before {
  content: "\EDAF";
}

.game-icon-doorway:before {
  content: "\EDB0";
}

.game-icon-boss-key:before {
  content: "\EDB1";
}

.game-icon-dial-padlock:before {
  content: "\EDB2";
}

.game-icon-heart-key:before {
  content: "\EDB3";
}

.game-icon-house-keys:before {
  content: "\EDB4";
}

.game-icon-key-card:before {
  content: "\EDB5";
}

.game-icon-key-lock:before {
  content: "\EDB6";
}

.game-icon-keyring:before {
  content: "\EDB7";
}

.game-icon-locked-heart:before {
  content: "\EDB8";
}

.game-icon-millenium-key:before {
  content: "\EDB9";
}

.game-icon-padlock-open:before {
  content: "\EDBA";
}

.game-icon-secret-book:before {
  content: "\EDBB";
}

.game-icon-key:before {
  content: "\EDBC";
}

.game-icon-padlock:before {
  content: "\EDBD";
}

.game-icon-three-keys:before {
  content: "\EDBE";
}

.game-icon-triple-lock:before {
  content: "\EDBF";
}

.game-icon-unlocking:before {
  content: "\EDC0";
}

.game-icon-wax-seal:before {
  content: "\EDC1";
}

.game-icon-key-2:before {
  content: "\EDC2";
}

.game-icon-combination-lock:before {
  content: "\EDC3";
}

.game-icon-defense-satellite:before {
  content: "\EDC4";
}

.game-icon-light-sabers:before {
  content: "\EDC5";
}

.game-icon-double-ringed-orb:before {
  content: "\EDC6";
}

.game-icon-energise:before {
  content: "\EDC7";
}

.game-icon-ringed-planet:before {
  content: "\EDC8";
}

.game-icon-sinusoidal-beam:before {
  content: "\EDC9";
}

.game-icon-bandaged:before {
  content: "\EDCA";
}

.game-icon-duration:before {
  content: "\EDCB";
}

.game-icon-extra-time:before {
  content: "\EDCC";
}

.game-icon-time-synchronization:before {
  content: "\EDCD";
}

.game-icon-heavy-timer:before {
  content: "\EDCE";
}

.game-icon-time-trap:before {
  content: "\EDCF";
}

.game-icon-stopwatch-2:before {
  content: "\EDD0";
}

.game-icon-dragon-orb:before {
  content: "\EDD1";
}

.game-icon-barbed-nails:before {
  content: "\EDD2";
}

.game-icon-claw-string:before {
  content: "\EDD3";
}

.game-icon-interleaved-claws:before {
  content: "\EDD4";
}

.game-icon-midnight-claw:before {
  content: "\EDD5";
}

.game-icon-plate-claw:before {
  content: "\EDD6";
}

.game-icon-saw-claw:before {
  content: "\EDD7";
}

.game-icon-shining-claw:before {
  content: "\EDD8";
}

.game-icon-triple-scratches:before {
  content: "\EDD9";
}

.game-icon-distress-signal:before {
  content: "\EDDA";
}

.game-icon-exploding-planet:before {
  content: "\EDDB";
}

.game-icon-jupiter:before {
  content: "\EDDC";
}

.game-icon-moon-orbit:before {
  content: "\EDDD";
}

.game-icon-planet-core:before {
  content: "\EDDE";
}

.game-icon-black-hole-bolas:before {
  content: "\EDDF";
}

.game-icon-hypersonic-bolt:before {
  content: "\EDE0";
}

.game-icon-nested-eclipses:before {
  content: "\EDE1";
}

.game-icon-vortex:before {
  content: "\EDE2";
}

.game-icon-koholint-egg:before {
  content: "\EDE3";
}

.game-icon-egg-defense:before {
  content: "\EDE4";
}

.game-icon-tentacles-barrier:before {
  content: "\EDE5";
}

.game-icon-curled-tentacle:before {
  content: "\EDE6";
}

.game-icon-interlaced-tentacles:before {
  content: "\EDE7";
}

.game-icon-noodle-ball:before {
  content: "\EDE8";
}

.game-icon-spiral-tentacle:before {
  content: "\EDE9";
}

.game-icon-suckered-tentacle:before {
  content: "\EDEA";
}

.game-icon-tentacle-heart:before {
  content: "\EDEB";
}

.game-icon-tentacle-strike:before {
  content: "\EDEC";
}

.game-icon-tentacurl:before {
  content: "\EDED";
}

.game-icon-thorny-tentacle:before {
  content: "\EDEE";
}

.game-icon-thorny-vine:before {
  content: "\EDEF";
}

.game-icon-apple-core:before {
  content: "\EDF0";
}

.game-icon-banana-bunch:before {
  content: "\EDF1";
}

.game-icon-banana-peeled:before {
  content: "\EDF2";
}

.game-icon-broccoli:before {
  content: "\EDF3";
}

.game-icon-coconuts:before {
  content: "\EDF4";
}

.game-icon-cut-lemon:before {
  content: "\EDF5";
}

.game-icon-leek:before {
  content: "\EDF6";
}

.game-icon-lemon:before {
  content: "\EDF7";
}

.game-icon-orange-slice:before {
  content: "\EDF8";
}

.game-icon-orange:before {
  content: "\EDF9";
}

.game-icon-peach:before {
  content: "\EDFA";
}

.game-icon-peas:before {
  content: "\EDFB";
}

.game-icon-pineapple:before {
  content: "\EDFC";
}

.game-icon-pumpkin:before {
  content: "\EDFD";
}

.game-icon-raddish:before {
  content: "\EDFE";
}

.game-icon-raspberry:before {
  content: "\EDFF";
}

.game-icon-strawberry:before {
  content: "\EE00";
}

.game-icon-watermelon:before {
  content: "\EE01";
}

.game-icon-apple-seeds:before {
  content: "\EE02";
}

.game-icon-blackcurrant:before {
  content: "\EE03";
}

.game-icon-gift-trap:before {
  content: "\EE04";
}

.game-icon-american-football-ball:before {
  content: "\EE05";
}

.game-icon-cricket-bat:before {
  content: "\EE06";
}

.game-icon-footy-field:before {
  content: "\EE07";
}

.game-icon-bowie-knife:before {
  content: "\EE08";
}

.game-icon-ball-pyramid:before {
  content: "\EE09";
}

.game-icon-basketball-ball:before {
  content: "\EE0A";
}

.game-icon-boulder-dash:before {
  content: "\EE0B";
}

.game-icon-bowling-strike:before {
  content: "\EE0C";
}

.game-icon-eight-ball:before {
  content: "\EE0D";
}

.game-icon-golf-tee:before {
  content: "\EE0E";
}

.game-icon-pool-triangle:before {
  content: "\EE0F";
}

.game-icon-snitch-quidditch-ball:before {
  content: "\EE10";
}

.game-icon-soccer-kick:before {
  content: "\EE11";
}

.game-icon-tennis-ball:before {
  content: "\EE12";
}

.game-icon-volleyball-ball:before {
  content: "\EE13";
}

.game-icon-water-polo:before {
  content: "\EE14";
}

.game-icon-ball-glow:before {
  content: "\EE15";
}

.game-icon-ball-heart:before {
  content: "\EE16";
}

.game-icon-blackball:before {
  content: "\EE17";
}

.game-icon-bowling-propulsion:before {
  content: "\EE18";
}

.game-icon-caged-ball:before {
  content: "\EE19";
}

.game-icon-circle-cage:before {
  content: "\EE1A";
}

.game-icon-concentration-orb:before {
  content: "\EE1B";
}

.game-icon-crumbling-ball:before {
  content: "\EE1C";
}

.game-icon-double-diaphragm:before {
  content: "\EE1D";
}

.game-icon-extraction-orb:before {
  content: "\EE1E";
}

.game-icon-falling-blob:before {
  content: "\EE1F";
}

.game-icon-mesh-ball:before {
  content: "\EE20";
}

.game-icon-striking-balls:before {
  content: "\EE21";
}

.game-icon-unstable-orb:before {
  content: "\EE22";
}

.game-icon-basketball-basket:before {
  content: "\EE23";
}

.game-icon-biceps:before {
  content: "\EE24";
}

.game-icon-bowling-alley:before {
  content: "\EE25";
}

.game-icon-bowling-pin:before {
  content: "\EE26";
}

.game-icon-disc-golf-basket:before {
  content: "\EE27";
}

.game-icon-fencer:before {
  content: "\EE28";
}

.game-icon-frisbee:before {
  content: "\EE29";
}

.game-icon-goal-keeper:before {
  content: "\EE2A";
}

.game-icon-juggling-clubs:before {
  content: "\EE2B";
}

.game-icon-muscular-torso:before {
  content: "\EE2C";
}

.game-icon-pool-dive:before {
  content: "\EE2D";
}

.game-icon-pool-table-corner:before {
  content: "\EE2E";
}

.game-icon-punching-bag:before {
  content: "\EE2F";
}

.game-icon-rugby-conversion:before {
  content: "\EE30";
}

.game-icon-shuttlecock:before {
  content: "\EE31";
}

.game-icon-skier:before {
  content: "\EE32";
}

.game-icon-skis:before {
  content: "\EE33";
}

.game-icon-soccer-field:before {
  content: "\EE34";
}

.game-icon-tennis-court:before {
  content: "\EE35";
}

.game-icon-tennis-racket:before {
  content: "\EE36";
}

.game-icon-sword-altar:before {
  content: "\EE37";
}

.game-icon-broadsword:before {
  content: "\EE38";
}

.game-icon-half-heart:before {
  content: "\EE39";
}

.game-icon-ancient-sword:before {
  content: "\EE3A";
}

.game-icon-axe-sword:before {
  content: "\EE3B";
}

.game-icon-bat-leth:before {
  content: "\EE3C";
}

.game-icon-butterfly-knife:before {
  content: "\EE3D";
}

.game-icon-chakram:before {
  content: "\EE3E";
}

.game-icon-coiled-nail:before {
  content: "\EE3F";
}

.game-icon-glaive:before {
  content: "\EE40";
}

.game-icon-razor:before {
  content: "\EE41";
}

.game-icon-rusty-sword:before {
  content: "\EE42";
}

.game-icon-scabbard:before {
  content: "\EE43";
}

.game-icon-spiral-hilt:before {
  content: "\EE44";
}

.game-icon-stabbed-note:before {
  content: "\EE45";
}

.game-icon-swords-power:before {
  content: "\EE46";
}

.game-icon-two-handed-sword:before {
  content: "\EE47";
}

.game-icon-blade-drag:before {
  content: "\EE48";
}

.game-icon-blade-fall:before {
  content: "\EE49";
}

.game-icon-bloody-sword:before {
  content: "\EE4A";
}

.game-icon-bouncing-sword:before {
  content: "\EE4B";
}

.game-icon-broad-dagger:before {
  content: "\EE4C";
}

.game-icon-cloak-dagger:before {
  content: "\EE4D";
}

.game-icon-crescent-blade:before {
  content: "\EE4E";
}

.game-icon-croc-sword:before {
  content: "\EE4F";
}

.game-icon-curvy-knife:before {
  content: "\EE50";
}

.game-icon-daggers:before {
  content: "\EE51";
}

.game-icon-dervish-swords:before {
  content: "\EE52";
}

.game-icon-diving-dagger:before {
  content: "\EE53";
}

.game-icon-energy-sword:before {
  content: "\EE54";
}

.game-icon-flying-dagger:before {
  content: "\EE55";
}

.game-icon-fragmented-sword:before {
  content: "\EE56";
}

.game-icon-knife-thrust:before {
  content: "\EE57";
}

.game-icon-lightning-saber:before {
  content: "\EE58";
}

.game-icon-plain-dagger:before {
  content: "\EE59";
}

.game-icon-pointy-sword:before {
  content: "\EE5A";
}

.game-icon-relic-blade:before {
  content: "\EE5B";
}

.game-icon-shard-sword:before {
  content: "\EE5C";
}

.game-icon-shining-sword:before {
  content: "\EE5D";
}

.game-icon-spinning-blades:before {
  content: "\EE5E";
}

.game-icon-spinning-sword:before {
  content: "\EE5F";
}

.game-icon-spiral-thrust:before {
  content: "\EE60";
}

.game-icon-striped-sword:before {
  content: "\EE61";
}

.game-icon-sword-array:before {
  content: "\EE62";
}

.game-icon-sword-hilt:before {
  content: "\EE63";
}

.game-icon-sword-spin:before {
  content: "\EE64";
}

.game-icon-sword-wound:before {
  content: "\EE65";
}

.game-icon-thrown-knife:before {
  content: "\EE66";
}

.game-icon-thunder-blade:before {
  content: "\EE67";
}

.game-icon-zeus-sword:before {
  content: "\EE68";
}

.game-icon-bowie-knife-2:before {
  content: "\EE69";
}

.game-icon-butterfly-knife-2:before {
  content: "\EE6A";
}

.game-icon-crescent-blade-2:before {
  content: "\EE6B";
}

.game-icon-stiletto-2:before {
  content: "\EE6C";
}

.game-icon-antibody:before {
  content: "\EE6D";
}

.game-icon-atom-core:before {
  content: "\EE6E";
}

.game-icon-h2o:before {
  content: "\EE6F";
}

.game-icon-radiations:before {
  content: "\EE70";
}

.game-icon-chemical-bolt:before {
  content: "\EE71";
}

.game-icon-dripping-tube:before {
  content: "\EE72";
}

.game-icon-erlenmeyer:before {
  content: "\EE73";
}

.game-icon-foamy-disc:before {
  content: "\EE74";
}

.game-icon-materials-science:before {
  content: "\EE75";
}

.game-icon-molecule:before {
  content: "\EE76";
}

.game-icon-soap-experiment:before {
  content: "\EE77";
}

.game-icon-acid-tube:before {
  content: "\EE78";
}

.game-icon-syringe-2:before {
  content: "\EE79";
}

.game-icon-vial:before {
  content: "\EE7A";
}

.game-icon-atom:before {
  content: "\EE7B";
}

.game-icon-molecule-2:before {
  content: "\EE7C";
}

.game-icon-arch-bridge:before {
  content: "\EE7D";
}

.game-icon-black-bridge:before {
  content: "\EE7E";
}

.game-icon-cable-stayed-bridge:before {
  content: "\EE7F";
}

.game-icon-cliff-crossing:before {
  content: "\EE80";
}

.game-icon-drawbridge:before {
  content: "\EE81";
}

.game-icon-rialto-bridge:before {
  content: "\EE82";
}

.game-icon-rope-bridge:before {
  content: "\EE83";
}

.game-icon-suspension-bridge:before {
  content: "\EE84";
}

.game-icon-archive-register:before {
  content: "\EE85";
}

.game-icon-archive-research:before {
  content: "\EE86";
}

.game-icon-book-cover:before {
  content: "\EE87";
}

.game-icon-book-pile:before {
  content: "\EE88";
}

.game-icon-calendar-half-year:before {
  content: "\EE89";
}

.game-icon-love-letter:before {
  content: "\EE8A";
}

.game-icon-passport:before {
  content: "\EE8B";
}

.game-icon-pencil-ruler:before {
  content: "\EE8C";
}

.game-icon-post-stamp:before {
  content: "\EE8D";
}

.game-icon-scroll-quill:before {
  content: "\EE8E";
}

.game-icon-spell-book:before {
  content: "\EE8F";
}

.game-icon-stamper:before {
  content: "\EE90";
}

.game-icon-book-aura:before {
  content: "\EE91";
}

.game-icon-book-cover-2:before {
  content: "\EE92";
}

.game-icon-book-storm:before {
  content: "\EE93";
}

.game-icon-bookmarklet:before {
  content: "\EE94";
}

.game-icon-enlightenment:before {
  content: "\EE95";
}

.game-icon-folded-paper:before {
  content: "\EE96";
}

.game-icon-ink-swirl:before {
  content: "\EE97";
}

.game-icon-scroll-unfurled:before {
  content: "\EE98";
}

.game-icon-black-book:before {
  content: "\EE99";
}

.game-icon-white-book:before {
  content: "\EE9A";
}

.game-icon-neck-bite:before {
  content: "\EE9B";
}

.game-icon-bleeding-wound:before {
  content: "\EE9C";
}

.game-icon-bloody-stash:before {
  content: "\EE9D";
}

.game-icon-cross-mark:before {
  content: "\EE9E";
}

.game-icon-feather-wound:before {
  content: "\EE9F";
}

.game-icon-open-wound:before {
  content: "\EEA0";
}

.game-icon-scar-wound:before {
  content: "\EEA1";
}

.game-icon-spotted-wound:before {
  content: "\EEA2";
}

.game-icon-stitched-wound:before {
  content: "\EEA3";
}

.game-icon-arm:before {
  content: "\EEA4";
}

.game-icon-bowels:before {
  content: "\EEA5";
}

.game-icon-kidneys:before {
  content: "\EEA6";
}

.game-icon-liver:before {
  content: "\EEA7";
}

.game-icon-lungs:before {
  content: "\EEA8";
}

.game-icon-internal-organ:before {
  content: "\EEA9";
}

.game-icon-tumor:before {
  content: "\EEAA";
}

.game-icon-flat-platform:before {
  content: "\EEAB";
}

.game-icon-i-brick:before {
  content: "\EEAC";
}

.game-icon-j-brick:before {
  content: "\EEAD";
}

.game-icon-l-brick:before {
  content: "\EEAE";
}

.game-icon-o-brick:before {
  content: "\EEAF";
}

.game-icon-s-brick:before {
  content: "\EEB0";
}

.game-icon-t-brick:before {
  content: "\EEB1";
}

.game-icon-z-brick:before {
  content: "\EEB2";
}

.game-icon-platform:before {
  content: "\EEB3";
}

.game-icon-hatchet:before {
  content: "\EEB4";
}

.game-icon-magic-axe:before {
  content: "\EEB5";
}

.game-icon-sharp-halberd:before {
  content: "\EEB6";
}

.game-icon-axe-swing:before {
  content: "\EEB7";
}

.game-icon-crossed-axes:before {
  content: "\EEB8";
}

.game-icon-halberd:before {
  content: "\EEB9";
}

.game-icon-hatchets:before {
  content: "\EEBA";
}

.game-icon-royal-love:before {
  content: "\EEBB";
}

.game-icon-split-cross:before {
  content: "\EEBC";
}

.game-icon-templar-heart:before {
  content: "\EEBD";
}

.game-icon-aztec-calendar-sun:before {
  content: "\EEBE";
}

.game-icon-tongue:before {
  content: "\EEBF";
}

.game-icon-carnivore-mouth:before {
  content: "\EEC0";
}

.game-icon-energy-breath:before {
  content: "\EEC1";
}

.game-icon-front-teeth:before {
  content: "\EEC2";
}

.game-icon-incisors:before {
  content: "\EEC3";
}

.game-icon-lightning-shout:before {
  content: "\EEC4";
}

.game-icon-lips:before {
  content: "\EEC5";
}

.game-icon-saber-tooth:before {
  content: "\EEC6";
}

.game-icon-sharp-lips:before {
  content: "\EEC7";
}

.game-icon-fangs:before {
  content: "\EEC8";
}

.game-icon-floor-hatch:before {
  content: "\EEC9";
}

.game-icon-hole:before {
  content: "\EECA";
}

.game-icon-mine-explosion:before {
  content: "\EECB";
}

.game-icon-car-battery:before {
  content: "\EECC";
}

.game-icon-heart-battery:before {
  content: "\EECD";
}

.game-icon-entangled-typhoon:before {
  content: "\EECE";
}

.game-icon-lightning-electron:before {
  content: "\EECF";
}

.game-icon-portal:before {
  content: "\EED0";
}

.game-icon-rolling-energy:before {
  content: "\EED1";
}

.game-icon-tesla-coil:before {
  content: "\EED2";
}

.game-icon-battery-0:before {
  content: "\EED3";
}

.game-icon-battery-100:before {
  content: "\EED4";
}

.game-icon-battery-25:before {
  content: "\EED5";
}

.game-icon-battery-50:before {
  content: "\EED6";
}

.game-icon-battery-75:before {
  content: "\EED7";
}

.game-icon-battery-minus:before {
  content: "\EED8";
}

.game-icon-battery-plus:before {
  content: "\EED9";
}

.game-icon-battery-pack-alt:before {
  content: "\EEDA";
}

.game-icon-battery-pack:before {
  content: "\EEDB";
}

.game-icon-bolt-saw:before {
  content: "\EEDC";
}

.game-icon-electric-whip:before {
  content: "\EEDD";
}

.game-icon-focused-lightning:before {
  content: "\EEDE";
}

.game-icon-heavy-lightning:before {
  content: "\EEDF";
}

.game-icon-lightning-branches:before {
  content: "\EEE0";
}

.game-icon-lightning-dissipation:before {
  content: "\EEE1";
}

.game-icon-lightning-frequency:before {
  content: "\EEE2";
}

.game-icon-lightning-helix:before {
  content: "\EEE3";
}

.game-icon-lightning-shadow:before {
  content: "\EEE4";
}

.game-icon-lightning-slashes:before {
  content: "\EEE5";
}

.game-icon-lightning-trio:before {
  content: "\EEE6";
}

.game-icon-power-lightning:before {
  content: "\EEE7";
}

.game-icon-round-struck:before {
  content: "\EEE8";
}

.game-icon-sonic-lightning:before {
  content: "\EEE9";
}

.game-icon-static-waves:before {
  content: "\EEEA";
}

.game-icon-thunder-struck:before {
  content: "\EEEB";
}

.game-icon-thunderball:before {
  content: "\EEEC";
}

.game-icon-windy-stripes:before {
  content: "\EEED";
}

.game-icon-electric:before {
  content: "\EEEE";
}

.game-icon-chain-lightning:before {
  content: "\EEEF";
}

.game-icon-arcing-bolt:before {
  content: "\EEF0";
}

.game-icon-bright-explosion:before {
  content: "\EEF1";
}

.game-icon-bubbling-beam:before {
  content: "\EEF2";
}

.game-icon-burning-blobs:before {
  content: "\EEF3";
}

.game-icon-clout:before {
  content: "\EEF4";
}

.game-icon-comet-spark:before {
  content: "\EEF5";
}

.game-icon-corner-explosion:before {
  content: "\EEF6";
}

.game-icon-crowned-explosion:before {
  content: "\EEF7";
}

.game-icon-electrical-crescent:before {
  content: "\EEF8";
}

.game-icon-explosive-meeting:before {
  content: "\EEF9";
}

.game-icon-fragmented-meteor:before {
  content: "\EEFA";
}

.game-icon-heavy-fall:before {
  content: "\EEFB";
}

.game-icon-hypersonic-melon:before {
  content: "\EEFC";
}

.game-icon-implosion:before {
  content: "\EEFD";
}

.game-icon-incoming-rocket:before {
  content: "\EEFE";
}

.game-icon-ion-cannon-blast:before {
  content: "\EEFF";
}

.game-icon-ringed-beam:before {
  content: "\EF00";
}

.game-icon-screen-impact:before {
  content: "\EF01";
}

.game-icon-striking-splinter:before {
  content: "\EF02";
}

.game-icon-evil-love:before {
  content: "\EF03";
}

.game-icon-broken-heart-zone:before {
  content: "\EF04";
}

.game-icon-charm:before {
  content: "\EF05";
}

.game-icon-chewed-heart:before {
  content: "\EF06";
}

.game-icon-shining-heart:before {
  content: "\EF07";
}

.game-icon-chanterelles:before {
  content: "\EF08";
}

.game-icon-grass-mushroom:before {
  content: "\EF09";
}

.game-icon-mushrooms-cluster:before {
  content: "\EF0A";
}

.game-icon-spotted-mushroom:before {
  content: "\EF0B";
}

.game-icon-dice-eight-faces-eight:before {
  content: "\EF0C";
}

.game-icon-dice-six-faces-five:before {
  content: "\EF0D";
}

.game-icon-dice-six-faces-four:before {
  content: "\EF0E";
}

.game-icon-dice-six-faces-one:before {
  content: "\EF0F";
}

.game-icon-dice-six-faces-six:before {
  content: "\EF10";
}

.game-icon-dice-six-faces-two:before {
  content: "\EF11";
}

.game-icon-dice-twenty-faces-one:before {
  content: "\EF12";
}

.game-icon-dice-twenty-faces-twenty:before {
  content: "\EF13";
}

.game-icon-perspective-dice-five:before {
  content: "\EF14";
}

.game-icon-perspective-dice-four:before {
  content: "\EF15";
}

.game-icon-perspective-dice-one:before {
  content: "\EF16";
}

.game-icon-perspective-dice-six-faces-five:before {
  content: "\EF17";
}

.game-icon-perspective-dice-six-faces-one:before {
  content: "\EF18";
}

.game-icon-perspective-dice-six-faces-random:before {
  content: "\EF19";
}

.game-icon-perspective-dice-six-faces-six:before {
  content: "\EF1A";
}

.game-icon-perspective-dice-six-faces-three:before {
  content: "\EF1B";
}

.game-icon-perspective-dice-six-faces-two:before {
  content: "\EF1C";
}

.game-icon-perspective-dice-six:before {
  content: "\EF1D";
}

.game-icon-perspective-dice-three:before {
  content: "\EF1E";
}

.game-icon-perspective-dice-two:before {
  content: "\EF1F";
}

.game-icon-d10:before {
  content: "\EF20";
}

.game-icon-d12:before {
  content: "\EF21";
}

.game-icon-d4:before {
  content: "\EF22";
}

.game-icon-inverted-dice-1:before {
  content: "\EF23";
}

.game-icon-inverted-dice-2:before {
  content: "\EF24";
}

.game-icon-inverted-dice-3:before {
  content: "\EF25";
}

.game-icon-inverted-dice-4:before {
  content: "\EF26";
}

.game-icon-inverted-dice-5:before {
  content: "\EF27";
}

.game-icon-inverted-dice-6:before {
  content: "\EF28";
}

.game-icon-matter-states:before {
  content: "\EF29";
}

.game-icon-sandstorm:before {
  content: "\EF2A";
}

.game-icon-abstract-001:before {
  content: "\EF2B";
}

.game-icon-abstract-002:before {
  content: "\EF2C";
}

.game-icon-abstract-003:before {
  content: "\EF2D";
}

.game-icon-abstract-004:before {
  content: "\EF2E";
}

.game-icon-abstract-005:before {
  content: "\EF2F";
}

.game-icon-abstract-006:before {
  content: "\EF30";
}

.game-icon-abstract-007:before {
  content: "\EF31";
}

.game-icon-abstract-008:before {
  content: "\EF32";
}

.game-icon-abstract-009:before {
  content: "\EF33";
}

.game-icon-abstract-010:before {
  content: "\EF34";
}

.game-icon-abstract-011:before {
  content: "\EF35";
}

.game-icon-abstract-012:before {
  content: "\EF36";
}

.game-icon-abstract-013:before {
  content: "\EF37";
}

.game-icon-abstract-014:before {
  content: "\EF38";
}

.game-icon-abstract-015:before {
  content: "\EF39";
}

.game-icon-abstract-016:before {
  content: "\EF3A";
}

.game-icon-abstract-017:before {
  content: "\EF3B";
}

.game-icon-abstract-018:before {
  content: "\EF3C";
}

.game-icon-abstract-019:before {
  content: "\EF3D";
}

.game-icon-abstract-020:before {
  content: "\EF3E";
}

.game-icon-abstract-021:before {
  content: "\EF3F";
}

.game-icon-abstract-022:before {
  content: "\EF40";
}

.game-icon-abstract-023:before {
  content: "\EF41";
}

.game-icon-abstract-024:before {
  content: "\EF42";
}

.game-icon-abstract-025:before {
  content: "\EF43";
}

.game-icon-abstract-026:before {
  content: "\EF44";
}

.game-icon-abstract-027:before {
  content: "\EF45";
}

.game-icon-abstract-028:before {
  content: "\EF46";
}

.game-icon-abstract-029:before {
  content: "\EF47";
}

.game-icon-abstract-030:before {
  content: "\EF48";
}

.game-icon-abstract-031:before {
  content: "\EF49";
}

.game-icon-abstract-032:before {
  content: "\EF4A";
}

.game-icon-abstract-033:before {
  content: "\EF4B";
}

.game-icon-abstract-034:before {
  content: "\EF4C";
}

.game-icon-abstract-035:before {
  content: "\EF4D";
}

.game-icon-abstract-036:before {
  content: "\EF4E";
}

.game-icon-abstract-037:before {
  content: "\EF4F";
}

.game-icon-abstract-038:before {
  content: "\EF50";
}

.game-icon-abstract-039:before {
  content: "\EF51";
}

.game-icon-abstract-040:before {
  content: "\EF52";
}

.game-icon-abstract-041:before {
  content: "\EF53";
}

.game-icon-abstract-042:before {
  content: "\EF54";
}

.game-icon-abstract-043:before {
  content: "\EF55";
}

.game-icon-abstract-044:before {
  content: "\EF56";
}

.game-icon-abstract-045:before {
  content: "\EF57";
}

.game-icon-abstract-046:before {
  content: "\EF58";
}

.game-icon-abstract-047:before {
  content: "\EF59";
}

.game-icon-abstract-048:before {
  content: "\EF5A";
}

.game-icon-abstract-049:before {
  content: "\EF5B";
}

.game-icon-abstract-050:before {
  content: "\EF5C";
}

.game-icon-abstract-051:before {
  content: "\EF5D";
}

.game-icon-abstract-052:before {
  content: "\EF5E";
}

.game-icon-abstract-053:before {
  content: "\EF5F";
}

.game-icon-abstract-054:before {
  content: "\EF60";
}

.game-icon-abstract-055:before {
  content: "\EF61";
}

.game-icon-abstract-056:before {
  content: "\EF62";
}

.game-icon-abstract-057:before {
  content: "\EF63";
}

.game-icon-abstract-058:before {
  content: "\EF64";
}

.game-icon-abstract-059:before {
  content: "\EF65";
}

.game-icon-abstract-060:before {
  content: "\EF66";
}

.game-icon-abstract-061:before {
  content: "\EF67";
}

.game-icon-abstract-062:before {
  content: "\EF68";
}

.game-icon-abstract-063:before {
  content: "\EF69";
}

.game-icon-abstract-064:before {
  content: "\EF6A";
}

.game-icon-abstract-065:before {
  content: "\EF6B";
}

.game-icon-abstract-066:before {
  content: "\EF6C";
}

.game-icon-abstract-067:before {
  content: "\EF6D";
}

.game-icon-abstract-068:before {
  content: "\EF6E";
}

.game-icon-abstract-069:before {
  content: "\EF6F";
}

.game-icon-abstract-070:before {
  content: "\EF70";
}

.game-icon-abstract-071:before {
  content: "\EF71";
}

.game-icon-abstract-072:before {
  content: "\EF72";
}

.game-icon-abstract-073:before {
  content: "\EF73";
}

.game-icon-abstract-074:before {
  content: "\EF74";
}

.game-icon-abstract-075:before {
  content: "\EF75";
}

.game-icon-abstract-076:before {
  content: "\EF76";
}

.game-icon-abstract-077:before {
  content: "\EF77";
}

.game-icon-abstract-078:before {
  content: "\EF78";
}

.game-icon-abstract-079:before {
  content: "\EF79";
}

.game-icon-abstract-080:before {
  content: "\EF7A";
}

.game-icon-abstract-081:before {
  content: "\EF7B";
}

.game-icon-abstract-082:before {
  content: "\EF7C";
}

.game-icon-abstract-083:before {
  content: "\EF7D";
}

.game-icon-abstract-084:before {
  content: "\EF7E";
}

.game-icon-abstract-085:before {
  content: "\EF7F";
}

.game-icon-abstract-086:before {
  content: "\EF80";
}

.game-icon-abstract-087:before {
  content: "\EF81";
}

.game-icon-abstract-088:before {
  content: "\EF82";
}

.game-icon-abstract-089:before {
  content: "\EF83";
}

.game-icon-abstract-090:before {
  content: "\EF84";
}

.game-icon-abstract-091:before {
  content: "\EF85";
}

.game-icon-abstract-092:before {
  content: "\EF86";
}

.game-icon-abstract-093:before {
  content: "\EF87";
}

.game-icon-abstract-094:before {
  content: "\EF88";
}

.game-icon-abstract-095:before {
  content: "\EF89";
}

.game-icon-abstract-096:before {
  content: "\EF8A";
}

.game-icon-abstract-097:before {
  content: "\EF8B";
}

.game-icon-abstract-098:before {
  content: "\EF8C";
}

.game-icon-abstract-099:before {
  content: "\EF8D";
}

.game-icon-abstract-100:before {
  content: "\EF8E";
}

.game-icon-abstract-101:before {
  content: "\EF8F";
}

.game-icon-abstract-102:before {
  content: "\EF90";
}

.game-icon-abstract-103:before {
  content: "\EF91";
}

.game-icon-abstract-104:before {
  content: "\EF92";
}

.game-icon-abstract-105:before {
  content: "\EF93";
}

.game-icon-abstract-106:before {
  content: "\EF94";
}

.game-icon-abstract-107:before {
  content: "\EF95";
}

.game-icon-abstract-108:before {
  content: "\EF96";
}

.game-icon-abstract-109:before {
  content: "\EF97";
}

.game-icon-abstract-110:before {
  content: "\EF98";
}

.game-icon-abstract-111:before {
  content: "\EF99";
}

.game-icon-abstract-112:before {
  content: "\EF9A";
}

.game-icon-abstract-113:before {
  content: "\EF9B";
}

.game-icon-abstract-114:before {
  content: "\EF9C";
}

.game-icon-abstract-115:before {
  content: "\EF9D";
}

.game-icon-abstract-116:before {
  content: "\EF9E";
}

.game-icon-abstract-117:before {
  content: "\EF9F";
}

.game-icon-abstract-118:before {
  content: "\EFA0";
}

.game-icon-abstract-119:before {
  content: "\EFA1";
}

.game-icon-abstract-120:before {
  content: "\EFA2";
}

.game-icon-abstract-121:before {
  content: "\EFA3";
}