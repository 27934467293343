// $card-height: 500px;
// $card-width: 350px;

$card-width: 6.35cm;
$card-height: 9.52cm;
$card-scale: 1.4;
$dark: var(--red-4);

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.asset-card {
  border: 4px solid transparent;

  border-radius: 0.25rem;

  font-size: 9.6pt;
  position: relative;
  // height: 500px;
  // width: 350px;
  width: $card-width * $card-scale;
  height: $card-height * $card-scale;
  background-color: #fff;
  color: #000;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 2px 2px 2px #000;

  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }

  h4 {
    margin: 0;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    padding: 0;
    font-size: 1.5rem;
  }

  .input-field {
    // position: relative;
    // overflow: hidden;
    // width: 50%;
    padding: 0 1em;

    .hr {
      border-bottom: 2px solid black;
      margin-bottom: 1em;
      display: block;
      width: 100%;
      z-index: 5;
    }

    ul {
      padding-left: 1.2em;
    }

    label {
      line-height: 0;
      margin-bottom: 0;
      margin-top: 1.5em;
    }

    .form-control-sm {
      z-index: 1;
      // position: relative;
      top: 0em;
      line-height: 0;
      height: 1.4em;
      font-family: "Sue Ellen Francisco" !important;
      text-transform: uppercase;
      font-size: 20px;
      padding: 0.2em 0 0 0.2em;
      border: none;
    }
  }

  .card-header {
    padding: 0.5em 2em;
    background-color: #343a40;
    color: #f8f9fa;
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem;
  }

  h6 {
    margin: 0;
    font-size: 12pt;
    text-transform: uppercase;
  }

  .asset-icon {
    border-radius: 50%;
    top: 1em;
    right: 1em;
    border: 4px solid white;
    background: $dark;
    width: 4em;
    height: 4em;
    position: absolute;
    cursor: pointer;
  }

  .asset-icon--selected {
    background-color: var(--red-2);
  }

  .asset-icon i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
  }

  input[type="checkbox"] {
    position: initial;
    opacity: 1 !important;
  }

  input[type="checkbox"]:checked+label:before {
    background: #fff;
    color: #333;
    content: "\2713";
    text-align: center;
  }

  label {
    padding: 0;
  }

  .control {
    font-family: arial;
    display: block;
    position: relative;
    // padding-left: 30px;
    // margin-bottom: 5px;
    // padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
  }

  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 0.3cm;
    width: 0.3cm;
    background: #ffffff;
    border: 1px solid #444444;
    border-radius: 20px;
  }

  .control:hover input~.control_indicator,
  .control input:focus~.control_indicator {
    background: white;
  }

  .control input:checked~.control_indicator {
    background: #424242;
  }

  .control:hover input:not([disabled]):checked~.control_indicator,
  .control input:checked:focus~.control_indicator {
    background: $dark;
  }

  .control input:disabled~.control_indicator {
    background: #ffffff;
    opacity: 0.6;
    pointer-events: none;
  }

  .control_indicator:after {
    box-sizing: unset;
    content: "";
    position: absolute;
    display: none;
  }

  .control input:checked~.control_indicator:after {
    display: block;
  }

  label.control.control-checkbox {
    margin-left: 1em;
    margin-right: 0.6em;
  }

  //   .control-checkbox .control_indicator:after {
  //     left: 7px;
  //     top: 3px;
  //     width: 3px;
  //     height: 8px;
  //     border: solid #ffffff;
  //     border-width: 0 2px 2px 0;
  //     transform: rotate(45deg);
  //   }
  .control-checkbox input:disabled~.control_indicator:after {
    border-color: #7b7b7b;
  }

  .control-checkbox .control_indicator::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 1rem;
    height: 1rem;
    margin-left: -0.25rem;
    margin-top: -0.25rem;
    background: #353535;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
  }

  @keyframes s-ripple {
    0% {
      transform: scale(0);
    }

    20% {
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  @keyframes s-ripple-dup {
    0% {
      transform: scale(0);
    }

    30% {
      transform: scale(1);
    }

    60% {
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  .control-checkbox input+.control_indicator::before {
    animation: s-ripple 250ms ease-out;
  }

  .control-checkbox input:checked+.control_indicator::before {
    animation-name: s-ripple-dup;
  }
}

.asset-card.selected {
    border-color: var(--red-1);
}


.print-show {
  display: none !important;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .page-break {
    display: block;
    page-break-before: always;
  }

  .print-show {
    display: block !important;
  }

  .print-hide {
    display: none !important;
  }

  // .asset-card {
  //   width: 350px;
  // }

  #page-container {
    margin: 0;
    padding: 0;
    // height: 100vh;
    // width: 100vw;
  }

  .asset-card-back {
    display: block;
  }

  .asset-card {
    .slider-container .slider {
      box-shadow: none;
    }
  }

  .asset-card {
    .slider-container .slider::-webkit-slider-thumb {
      display: none;
    }

    .slider-container .slider::-moz-range-thumb {
      display: none;
    }
  }

  // #assetCards {
  //   position: absolute;
  //   margin: 0;
  //   padding: 0;
  // }

  // .card-header {
  //   background: $dark !important;
  // }
  // h6 {
  //   color: white;
  // }

  // .asset-icon {
  //   background: #000 !important;
  // }
  // body {
  //   margin: 0 1rem; //($card-height / 2) $sideBarWidth + 2rem;
  //   overflow-y: scroll;
  //   cursor: default;
  // }
}

// @page {
//   size: auto;
//   margin: 0px;
// }

.MuiFormControl-root {
  flex-direction: inherit !important;
  width: 100%;
}

.MuiAutocomplete-input {
  padding: 0 !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width: 100% !important;
  min-width: 100%;
}

input.autocomplete-input {
  margin-top: -0.2em !important;
  padding: 0 !important;
}

.asset-card-back {
  h3 {
    margin-top: 4em;
  }

  .asset-card-back-line {
    margin-top: 3em;
    border-top: 2px solid rgba(0, 0, 0, 1);
  }

  .container {
    position: relative;
  }

  .asset-icon {
    color: white;
    transform: rotate(45deg);
    left: 50%;
    margin-left: -1.5em;
    top: 12em;
  }

  .hr {
    height: 5px;
    border-top: 2px solid black;
    width: 90%;
    margin-left: 5%;
    margin-top: 2em;
  }
}

.asset-card {

  // .stat-track-container {
  //   position: absolute;
  //   bottom: 0;
  // }
  .slider-container {
    width: 88%;
    position: absolute;
    bottom: 1em;
  }

  .slider-container ul.slider-ticks li:not(:last-child):before {
    content: "";
    border: 0 !important;
    clear: both;
  }

  .slider-container ul.slider-ticks li:not(:last-child):after {
    content: "";
    border: 0 !important;
    clear: both;
  }

  .slider-container ul.slider-ticks li {
    margin-top: 0.1em;
    line-height: 2.1em;
  }

  .slider-container ul.slider-ticks li.slider-tick-label {
    margin-top: 0.1em;
    height: 2.5em !important;
    line-height: 2.5em !important;
  }

  .slider-container ul.slider-ticks li.slider-tick-label span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1em !important;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: white;
    opacity: 1;
  }
}

#assetBuilderControls {
  min-height: 2.8em;
}

.asset-card label.control.control-checkbox {
  margin-left: 1em;
  margin-right: 0.6em;
}