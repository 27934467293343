.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 0 0;
    flex-wrap: wrap;
}

.buttons button {
    margin: .25rem;
    text-transform: uppercase;
}

.delete {
    background-color: var(--red-2);    
}