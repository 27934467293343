.field {
    display: flex;
    width: 600px;
    max-width: 100%;
    margin-bottom: 1rem;
}

.input {
    flex: 1;
}

.password {
    padding: .5rem;
    width: 100%;
    box-sizing: border-box;
}

.journal {
    padding: 0 1rem;
    width: 600px;
    max-width: 100%;
    flex: 1;
    overflow: auto;
}

.button {
    margin-left: .25rem;
}

.storyTellingButton {
    width: 100%;
    background-color: var(--red-2);
}

.settingsButton {
    position: absolute;
    top: .5rem;
    right: .5rem;
}

.textArea {
    width: 100%;
    font-size: 1rem;
    padding: .5rem;
    box-sizing: border-box;
}

.sideBySide {
    margin-bottom: .5rem 0 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.formInput {
    margin-bottom: 1rem;

    label {
        display: block;
        padding: 0;
        margin: 0;
    }
}