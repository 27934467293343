.entry {
    background-color: var(--red-4);
    padding-left: .5rem;
    margin-bottom: .5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: italic;
    border: 1px solid transparent;
}

.entryText {
    flex: 1;
    padding: .5rem;
    margin-right: 2px;
}