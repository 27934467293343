.dice-tray {
    display: flex;
    flex-direction: row;
    align-items: center;    
    justify-content: center;
    margin: 1rem 0 0 0;
    padding: .5rem 0;
    cursor: pointer;
    background-color: var(--red-3);
}

.dice-tray:hover {
    background-color: var(--red-2);
}

.dice-tray > * + * {
    margin-left: 10px;
}