.d10 {
    display: inline-block;
    position: relative;
}

.d10__value {
    color: var(--black);
    position: absolute;
    font-size: 90%;
    font-weight: bold;
    top: 12%;
    left: 50%;
    transform: translate(-50%, 0%);
}